/* eslint-disable */
import React from "react";
import { Link, useLocation } from "react-router-dom";
import DashIcon from "components/icons/DashIcon";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../shadcn/accordian"
// chakra imports

// *import { Accordion } from "../../shadcn/Accordian.tsx";

export function SidebarLinks(props) {
  // Chakra color mode
  let location = useLocation();

  const { routes } = props;

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return location.pathname.includes(routeName);
  };

  const createLinks = (routes) => {
    return routes.map((route, index) => {
      if (
        route.layout === "/admin" ||
        route.layout === "/auth" ||
        route.layout === "/rtl" ||
        route.layout === "/user"
      ) {
        return (
          <div >
            {
              route.isDropdown ? (
                <Accordion type="multiple" collapsible className=" relative mb-3 flex justify-center hover:cursor-pointer w-full">
                  <AccordionItem value={index} className="my-[3px] border-none flex flex-col cursor-pointer w-full px-8">
                    <AccordionTrigger className="hover:no-underline" >
                      <span
                        className={`${activeRoute(route.path) === true
                          ? "font-bold text-brand-500 dark:text-white"
                          : "font-medium text-gray-600"
                          }`}
                      >
                        {route.icon ? route.icon : <DashIcon />}{" "}
                      </span>
                      <p className={`ml-4 !font-bold mr-24 ${activeRoute(route.path) === true
                        ? "font-bold text-navy-700 dark:text-white"
                        : "font-medium text-gray-600"
                        }`}>
                        {route.name}
                      </p>
                    </AccordionTrigger>

                    {route.children.map((childRoute, index) => (
                      <Link key={index} to={childRoute.layout + "/" + route.path + "/" + childRoute.path}>
                        <AccordionContent className=" relative flex align-middle py-2 mb-3 hover:cursor-pointer ">
                          <p className={`ml-4 !font-bold mr-24 ${activeRoute(childRoute.path) === true
                            ? "font-bold text-navy-700 dark:text-white"
                            : "font-medium text-gray-600"
                            }`}>
                            {childRoute.name}
                          </p>
                          {activeRoute(childRoute.path) ? (
                            <div class="absolute inset-y-0 right-0 h-9 w-1 rounded-lg bg-brand-500 dark:bg-brand-400" />
                          ) : null}
                        </AccordionContent>
                      </Link>
                    ))}
                  </AccordionItem>
                </Accordion>
                // "somewhere"
              ) : (
                <Link key={index} to={route.layout + "/" + route.path}>
                  <div className="relative mb-3 flex hover:cursor-pointer">
                    <li
                      className="my-[3px] flex cursor-pointer items-center px-8"
                      key={index}
                    >
                      <span
                        className={`${activeRoute(route.path) === true
                          ? "font-bold text-brand-500 dark:text-white"
                          : "font-medium text-gray-600"
                          }`}
                      >
                        {route.icon ? route.icon : <DashIcon />}{" "}
                      </span>
                      <p
                        className={`leading-1 ml-4 flex ${activeRoute(route.path) === true
                          ? "font-bold text-navy-700 dark:text-white"
                          : "font-medium text-gray-600"
                          }`}
                      >
                        {route.name}
                      </p>
                    </li>
                    {activeRoute(route.path) ? (
                      <div className="absolute right-0 top-px h-9 w-1 rounded-lg bg-brand-500 dark:bg-brand-400" />
                    ) : null}
                  </div>
                  <div>

                  </div>
                </Link>
              )
            }

          </div>


        );
      }
    });
  };
  // BRAND
  return createLinks(routes);
}

export default SidebarLinks;
