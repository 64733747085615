import { getPlan, addPrice, updatePrice, deletePrice } from '../../../redux/actions/plan';
import { React, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import toast, { Toaster } from 'react-hot-toast';
import Form from 'components/form';
import DialogCustom from "../../../components/dialog";
import Switch from 'components/switch';

import {
    FiEdit2,
    FiTrash,
} from "react-icons/fi";
import {
    Table,
    TableBody,
    TableCaption,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "../../../components/shadcn/table";

function Price(props) {
    const { dispatch, plan } = props
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [deleteDialogTitle, setDeleteDialogTitle] = useState("");
    const [deleteDialogDesc, setDeleteDialogDesc] = useState("");
    const [deleteDialogButtonDesc, setDeleteDialogButtonDesc] = useState("");
    const [priceFormData, setPriceFormData] = useState({});
    const [selectedPrice, setSelectedPrice] = useState({});
    const planId = plan.id
    const prices = plan.prices
    const handleCrudPrice = async () => {
        console.log(priceFormData, "priceFormData")
        if (!priceFormData.id) await dispatch(addPrice({ planId, ...priceFormData }))
        if (priceFormData.id) await dispatch(updatePrice({ planId, ...priceFormData }))
        await dispatch(getPlan(planId))
        setPriceFormData({})
        setIsDialogOpen(false)
    }

    const priceFields = [
        {
            id: "name",
            name: "name",
            label: "Price Name",
            placeholder: "Enter Price's Name",
            type: "text",
        },
        {
            id: "Status",
            name: "status",
            label: "Status",
            desc: "Set status as active/inactive",
            type: "switch",
        },
        {
            id: "upperLimit",
            name: "upperLimit",
            label: "upperLimit",
            placeholder: "upper limit in number of months",
            type: "number",
        },
        {
            id: "lowerLimit",
            name: "lowerLimit",
            label: "lowerLimit",
            placeholder: "lower limit in number of months",
            type: "number",
        },
        {
            id: "discount",
            name: "discount",
            label: "discount",
            placeholder: "discount in %",
            type: "number",
        },
    ];
    return (
        <>
            <DialogCustom
                open={isDeleteDialogOpen}
                onOpenChange={setIsDeleteDialogOpen}
                // dialogTitle={`${selectedPrice.status ? "Enable" : "Disable"}` + " Price"}
                dialogTitle={deleteDialogTitle}
                dialogWidth="w-1/2"
                // dialogDesc={}
                dialogDesc={deleteDialogDesc}
                content={
                    <div className='flex flex-row-reverse'>
                        {console.log("selectedPrice: "+JSON.stringify(selectedPrice))}
                        <button
                            onClick={async () => {
                                if (deleteDialogButtonDesc != "Delete") {
                                    await dispatch(updatePrice(selectedPrice))
                                    setPriceFormData({})
                                } else {
                                    await dispatch(deletePrice(selectedPrice.id))
                                }
                                await dispatch(getPlan(planId))
                                setSelectedPrice({})
                                setIsDeleteDialogOpen(false)
                            }}
                            className={` linear rounded-xl border-2 text-white border-white  px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-white hover:shadow-lg
      ${selectedPrice.status ? "bg-green-700 hover:text-green-500 hover:border-2 hover:border-green-700 " : "bg-red-700 hover:text-red-500 hover:border-2 hover:border-red-700 "}
      `}>
                            {deleteDialogButtonDesc}
                        </button>
                        <button
                            onClick={() => {
                                setIsDeleteDialogOpen(false);
                            }}
                            className="mr-4 text-white linear rounded-xl border-2 border-white bg-brand-700 px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-700 hover:shadow-lg  ">
                            Cancel
                        </button>
                    </div>
                }
            />
            <DialogCustom
                open={isDialogOpen}
                onOpenChange={setIsDialogOpen}
                dialogTitle={`${priceFormData.id ? "Update " : "Add "} Price`}
                dialogWidth="w-1/2"
                dialogDesc={`${priceFormData.id ? "Update " : "Add "} the neccessary fields and save.`}
                content={
                    <>
                        <Form
                            fields={priceFields}
                            formData={priceFormData}
                            onChange={(newFormData) => setPriceFormData(newFormData)}
                        />
                        <div className='flex justify-end'>
                            <button
                                onClick={handleCrudPrice}
                                className="border-2 text-white linear rounded-full bg-brand-700 px-4 py-3 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-500  active:!bg-white/70">
                                {priceFormData.id ? "Update" : "Add" + " Price"}
                            </button>
                        </div>
                    </>
                }
            />
            {prices.length == 0 &&
                <div className='p-10 flex flex-col justify-center items-center'>
                    <h4 className='mb-5 text-2xl text-brand-500 font-medium'> No Prices Added! Click below to add one. </h4>
                    <button
                        onClick={() => {
                            setIsDialogOpen(true);
                            console.log("SETTED")
                        }}
                        className="border-2 text-white linear rounded-xl bg-brand-700 px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-500  active:!bg-white/70">
                        Add Price
                    </button>
                </div>
            }
            {prices.length > 0 &&
                <Table className="border-b border-grey-500">
                    <TableCaption className="mb-3 font-bold  text-2xl text-start text-black">
                        <div className='flex justify-between items-center'>
                            <div className='flex justify-between w-1/2 items-center'>

                            </div>
                            <div className='flex  justify-end'>
                                <button
                                    onClick={() => {
                                        setIsDialogOpen(true);
                                        setPriceFormData({})
                                    }}
                                    className="border-2 text-white linear rounded-full bg-brand-700 px-4 py-3 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-500  active:!bg-white/70">
                                    Add Price
                                </button>

                            </div>
                        </div>
                    </TableCaption>
                    <TableHeader>

                        <TableRow>
                            <TableHead className="w-1/5"> Price </TableHead>
                            <TableHead className="w-1/5"> Limit </TableHead>
                            <TableHead className="w-1/5"> Discount </TableHead>
                            <TableHead className="w-1/5"> Status </TableHead>
                            <TableHead className="w-1/6 text-center ">Actions</TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {prices.map((price) => (
                            <TableRow key={price.id}>
                                <TableCell className="font-medium">
                                    <h4 className='text-brand-500 font-black'> {price.name} </h4>
                                </TableCell>
                                <TableCell className="font-medium">{price.lowerLimit + " - " + price.upperLimit + " Months"}</TableCell>
                                <TableCell className="font-medium">{price.discount + " %"}</TableCell>
                                <TableCell>
                                    <Switch
                                        key={price.id}
                                        id={price.id}
                                        checked={price["status"] || false}
                                        onChange={(e) => {
                                            setSelectedPrice({ ...price, ["status"]: e.target.checked })
                                            setDeleteDialogTitle(`${selectedPrice.status ? "Enable" : "Disable"}` + " Price")
                                            setDeleteDialogDesc(`Are you sure you want to ${selectedPrice.status ? "Disable" : "Enable"} this price ?`)
                                            setDeleteDialogButtonDesc(`${selectedPrice.status ? "Disalbe" : "Enable"}`)
                                            setIsDeleteDialogOpen(true)
                                        }}
                                    />
                                </TableCell>
                                <TableCell className="w-1/6  ">
                                    <div className='w-full flex justify-around'>
                                        <div
                                            onClick={() => {
                                                setPriceFormData(price)
                                                setIsDialogOpen(true)
                                            }}
                                            className='mx-2 bg-yellow-500 w-fit p-1 rounded-3xl cursor-pointer' >
                                            <FiEdit2 className='h-4 w-4 text-white' />
                                        </div>


                                        <div
                                            onClick={() => {
                                                setDeleteDialogTitle("Delete Price")
                                                setDeleteDialogDesc(`Are you sure you want to delete this price ?`)
                                                setDeleteDialogButtonDesc(`Delete`)
                                                setSelectedPrice(price)
                                                setIsDeleteDialogOpen(true)
                                            }}
                                            className='mx-2 bg-red-500 w-fit p-1 rounded-3xl cursor-pointer'>
                                            <FiTrash className='h-4 w-4 text-white' />
                                        </div>
                                    </div>
                                </TableCell>

                            </TableRow>

                        ))}


                    </TableBody>
                </Table>
            }
        </>

    );
}

export default Price;