
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
const apiUrl = process.env.REACT_APP_URL

export const sendResetPasswordLink = createAsyncThunk(
    'forgetPassword/sendResetPasswordLink',
    async (email) => {
      try {
        const response = await fetch(`${apiUrl}open/auth/sendresetpasswordlink`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email }),
        });
        console.log('rdx email :', email);
        const data = await response.json();
        return data;
      } catch (error) {
        console.error('Error:', error);
        throw error;
      }
    }
  );

  
  export const verifyResetPasswordToken = createAsyncThunk(
    'forgetPassword/verifyResetPasswordToken',
    async (token) => {
      try {
        const response = await fetch(`${apiUrl}open/auth/verifyresetpasswordtoken`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ token }),
        });
        const data = await response.json();
        console.log("api call res data :"+data)
        return data;
      } catch (error) {
        console.error('Error:', error);
        throw error;
      }
    }
  );

  export const resetPassword = createAsyncThunk(
    'forgetPassword/resetPassword',
    async ({ token, password }) => {
      try {
        const response = await fetch(`${apiUrl}open/auth/resetpassword/${token}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ password }),
        });
        console.log('rdx password :', password);
        console.log('rdx Password obj:', {password});
        const data = await response.json();
        console.log('Password updated successfully:', data);
        return data;
      } catch (error) {
        console.error('Error updating password:', error);
        throw error;
      }
    }
  );
  
  

export const forgetPasswordSlice = createSlice({
    name: 'forgetPassword',
    initialState: {
      // status: 'idle',
      // resetLinkSent: false,
      // error: null,
      loading: true,
      isValidToken:false,
    },
    reducers: {},
    extraReducers: (builder) => {
    builder
      .addCase(sendResetPasswordLink.pending, (state) => {
        // state.status = 'loading';
        // state.error = null;
        
      })
      .addCase(sendResetPasswordLink.fulfilled, (state, action) => {
        // state.status = 'succeeded';
        // state.resetLinkSent = true;
        
      })
      .addCase(sendResetPasswordLink.rejected, (state, action) => {
        // state.status = 'failed';
        // state.error = action.error.message || 'Failed to send reset password link';
        
      });

    builder
      .addCase(verifyResetPasswordToken.pending, (state) => {
        // state.status = 'loading';
        // state.error = null;
        // state.loading = true; // Set loading to true
      })
      /*.addCase(verifyResetPasswordToken.fulfilled, (state, action) => {
       
        state.isValidToken = action.payload.success;
        state.loading = false; 
        
      })*/
      .addCase(verifyResetPasswordToken.fulfilled, (state, action) => {
      state.loading = false; 
      console.log("fulfilled req action.payload.success: "+action.payload.success)
      console.log("fulfilled req state.isValidToken: "+state.isValidToken)
    state.isValidToken = action.payload.success;
    
})
      .addCase(verifyResetPasswordToken.rejected, (state, action) => {
        // state.status = 'failed';
        // state.error = action.error.message || 'Failed to verify reset password token';
        state.loading = false; // Set loading to false
        state.isValidToken = false;
      });
  },
  });


export default forgetPasswordSlice.reducer


