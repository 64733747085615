
import { React, useState, useEffect } from 'react'
import CustomTabs from '../../../../components/tabs';
import { useDispatch, useSelector } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import { BusinessRequiredFields } from './BusinessRequiredFields';
import { BusinessOptionalFields } from './BusinessOptionalFields';
import { updateUserInput, resetUserInput } from "../../../../redux/actions/business";
import { getBusiness, addBusiness, updateBusiness } from "../../../../redux/actions/business";
import { useNavigate } from "react-router-dom";

const BusinessCRUD = () => {
    const navigate = useNavigate();
    const businessId = window.location.pathname.split("/")[3];
    const isAddBusiness = businessId === "add-business";
    const dispatch = useDispatch();

    useEffect(() => {
        if (!isAddBusiness) {
          dispatch(getBusiness(businessId));
        } else {
          console.log("reset ---------");
          dispatch(resetUserInput()); // Reset user input only when transitioning to "Add Business"
        }
      }, []);

    const userInput = useSelector(state => state.business.value.userInput);
    
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        dispatch(updateUserInput({ [name]: value }));
      };
    
      const handleSwitchChange = (event) => {
        const status = event.target.checked;
        dispatch(updateUserInput({ status: status }));
      };
    
      const handleButtonClick = async() => {
        if (isAddBusiness) {
            const action = await dispatch(addBusiness(userInput));
            if (action.payload.success) {
                notifySuccess(action.payload.message);
                setTimeout(()=>{
                    navigate("/admin/business")
                },1000)
              } else {
                notifyError(action.payload.message);
              }
        } else {
            const action = await dispatch(updateBusiness({ ...userInput, id: businessId }));
            if (action.payload.success) {
                notifySuccess(action.payload.message);
                setTimeout(()=>{
                    navigate("/admin/business")
                },1000)
              } else {
                notifyError(action.payload.message);
              }
        }
      };

      const notifySuccess = (message) =>
    toast.success(message, {
      style: {
        padding: "35px",
        color: "#a0ca00",
      },
      duration: 1000,
      iconTheme: {
        primary: "#a0ca00",
        secondary: "#222c25",
      },
    });
  const notifyError = (message) =>
    toast.error(message, {
      style: {
        border: "1px solid #fff",
        padding: "35px",
        color: "red",
      },
      duration: 5000,
      iconTheme: {
        primary: "red",
        secondary: "#fff",
      },
    });
      
    return (
        <div className='h-full min-h-[84vh] !w-full rounded-xl bg-white mt-3 '>
            {/* <h1 className='text-3xl text-navy-700 text-center p-5'> {module.name} </h1> */}
            <Toaster />
            <div className="flex flex-col md:flex-row sm:flex-row-reverse">
                <CustomTabs
                    tabs={
                        ["Basic Information", "Additional Information"]
                    }
                    tabContents={
                        [
                        <BusinessRequiredFields
                        userInput={userInput}
                        handleInputChange={handleInputChange}
                        handleSwitchChange={handleSwitchChange}
                        handleButtonClick={handleButtonClick}
                        isAddBusiness={isAddBusiness}

                        />,
                        <BusinessOptionalFields 
                        userInput={userInput}
                        handleInputChange={handleInputChange}
                        handleSwitchChange={handleSwitchChange}
                        handleButtonClick={handleButtonClick}
                        isAddBusiness={isAddBusiness}
                        />
                        ]
                    }
                />
            </div>
        </div>
    );
};

export default BusinessCRUD;