import { configureStore } from '@reduxjs/toolkit'
import  StateReducer  from './actions/state'
import  ZoneReducer  from './actions/zone'
import  DistrictReducer  from './actions/district'
import  CenterReducer  from './actions/center'
import  AuthReducer  from './actions/auth'
import  UserReducer  from './actions/user'
import RoleReducer from './actions/role'
import ModulesReducer from './actions/modules'
import PlansReducer from './actions/plan'
import PermissionReducer from './actions/permission'
import BusinessReducer from './actions/business'
import SubscriptionReducer from './actions/subscription'
import FeatureReducer from './actions/feature'
import DashboardReducer from './actions/dashboard'

import signInReducer from './actions/signIn'
import ForgetPasswordReducer from './actions/forgetpassword'
import dashboard from './actions/dashboard'


export default configureStore({
  reducer: {
    state: StateReducer,
    zone: ZoneReducer,
    district: DistrictReducer,
    center: CenterReducer,
    signIn: signInReducer,
    auth: AuthReducer,
    user: UserReducer,
    role: RoleReducer,
    auth: AuthReducer,
    forgetPassword: ForgetPasswordReducer,
    business: BusinessReducer,
    module: ModulesReducer,
    plan : PlansReducer,
    permissions: PermissionReducer, 
    subscription: SubscriptionReducer, 
    feature:FeatureReducer,
    dashboard:DashboardReducer

    
  },
});