import { React, useState } from "react";
import SwitchField from "../fields/SwitchField";
import InputField from "../fields/InputField";
import TextField from "../fields/TextField";
import DropdownSelect from "../../components/select";
import Datepicker from "react-tailwindcss-datepicker";
import moment from "moment";
import Select from "react-select";

export const Form = (props) => {
  // console.log(props.data, "CALLED")
  console.log(props.fields, "FIELDS HERE");
  // console.log(props.formData, "FORMDATA HERE")
  // console.log(props.onChange, "ONCHANGE HERE")
  const { fields, formData, onChange } = props;

  const [value, setValue] = useState({
    startDate: null,
    endDate: null,
  });
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleOptionChange = (fieldName, selected) => {
    console.log(fieldName, selected);
    setSelectedOptions(selected);
    const newFormData = { ...formData, [fieldName]: selected };
    console.log(newFormData, "newFormData MULTI SELECT");
    onChange(newFormData);
  };

  const handleValueChange = (isSingle, fieldName, newValue) => {
    console.log("newValue:", fieldName, newValue);
    setValue(newValue);
    const newFormData = {
      ...formData,
      [fieldName]: isSingle ? newValue.startDate : newValue,
    };
    onChange(newFormData);
  };

  const handleFieldChange = (fieldName, value, type) => {
    // If the field is of type 'number', parse the value and ensure it's an integer
    if (type === "number") {
      const intValue = parseInt(value, 10);
      // If the parsed value is not a number or is not an integer, do not update the form data
      if (isNaN(intValue)) {
        return;
      }
      value = intValue;
    }

    const newFormData = { ...formData, [fieldName]: value };
    onChange(newFormData);
  };

  return (
    <div className="">
      {fields.map((field) => {
        let renderedField;

        // console.log(formData[field.name])
        switch (field.type) {
          case "switch":
            renderedField = (
              <SwitchField
                key={field.id}
                id={field.id}
                label={field.label}
                desc={field.desc}
                checked={formData[field.name] || false}
                onChange={(e) =>
                  handleFieldChange(field.name, e.target.checked)
                }
              />
            );
            break;
          case "text":
          case "mail":
          case "number":
            renderedField = (
              <InputField
                key={field.id}
                label={field.label}
                id={field.id}
                type={field.type}
                placeholder={field.placeholder}
                value={formData[field.name] || ""}
                onChange={(e) => handleFieldChange(field.name, e.target.value)}
              />
            );
            break;
          case "textarea":
            console.log("SHOULD BE HERE");
            renderedField = (
              <TextField
                key={field.id}
                label={field.label}
                id={field.id}
                placeholder={field.placeholder}
                columns={field.columns}
                rows={field.rows}
                value={formData[field.name] || ""}
                onChange={(e) => handleFieldChange(field.name, e.target.value)}
              />
            );
            break;
          case "dropdown":
            // console.log(formData[field.selectedvalue], "field.list.find(item => item.name === field.selectedValue)")
            console.log("field.isOutterLevel-"+JSON.stringify(field));
            console.log("formData-"+JSON.stringify(formData['type']));
            renderedField = (
              <DropdownSelect
                list={field.list}
                label={field.label}
                placeHolder={field.placeHolder}
                name={field.name}
                toSelect={field.toSelect}
                //selectedvalue={formData[field.selectedvalue]?.name ? formData[field.selectedvalue]?.name : field.selectedvalue}
                selectedvalue={
                  field.isOutterLevel? formData[field.selectedvalue]:formData[field.selectedvalue]?.name || field.placeHolder
                }
                // selectedvalue={
                //   formData[field.selectedvalue]?.name || field.placeHolder
                // }
                onChange={(value) => handleFieldChange(field.id, value)}
              />
            );
            break;
          case "datetime":
            // console.log(formData[field.selectedvalue], "field.list.find(item => item.name === field.selectedValue)")
            const value = moment(formData[field.name]).format("DD/MM/YYYY");
            renderedField = (
              <div>
                <label
                  htmlFor={field.id}
                  className="ml-3  text-sm font-bold text-navy-700 dark:text-white"
                >
                  {field.label}
                </label>
                <Datepicker
                  displayFormat={"DD/MM/YYYY"}
                  inputClassName="placeholder-gray-800 w-[100%] p-2 rounded-xl"
                  toggleClassName="text-navy-700 rounded-xl p-3"
                  primaryColor={"lime"}
                  containerClassName={
                    "border-2 border-grey-400 rounded-xl flex mt-2"
                  }
                  value={value}
                  asSingle={field.isSingle}
                  useRange={field.isRange}
                  // placeholder={value ? value : formData["placeholder"]}
                  // placeholder={field.placeholder}
                  placeholder={value ? value : formData["placeholder"]}
                  onChange={(e) => {
                    handleValueChange(field.isSingle, field.id, e);
                  }}
                />
              </div>
            );
            console.log("field.isRange" + field.isRange);
            console.log("field.isSingle" + field.isSingle);
            console.log("value" + value);
            break;
//           case "datetime":
//   // If formData[field.name] is defined (i.e., a date is selected), format it
//   const placeholder = formData[field.name] ? moment(formData[field.name]).format("DD/MM/YYYY") : "Select Date";
//   renderedField = (
//     <div>
//       <label
//         htmlFor={field.id}
//         className="ml-3  text-sm font-bold text-navy-700 dark:text-white"
//       >
//         {field.label}
//       </label>
//       <Datepicker
//         displayFormat={"DD/MM/YYYY"}
//         inputClassName="placeholder-gray-800 w-[100%] p-2 rounded-xl"
//         toggleClassName="text-navy-700 rounded-xl p-3"
//         primaryColor={"lime"}
//         containerClassName={
//           "border-2 border-grey-400 rounded-xl flex mt-2"
//         }
//         value={value}
//         asSingle={field.isSingle}
//         useRange={field.isRange}
//         placeholder={placeholder} // Use placeholder instead of value for the selected date
//         onChange={(e) => {
//           handleValueChange(field.isSingle, field.id, e);
//         }}
//       />
//     </div>
//   );
// break;

          case "multiselect":
            const mapOptionsToSelectOptions = (option) => ({
              value: option.id, // Use the 'id' key for selection
              label: option.name, // Display the 'name' key in the dropdown
            });

            // const selectOptions = field.options.map(mapOptionsToSelectOptions);
            const selectOptions = field.options
              ? field.options.map(mapOptionsToSelectOptions)
              : [];
            renderedField = (
              <Select
                isMulti={true}
                value={selectedOptions}
                onChange={(e) => {
                  handleOptionChange(field.name, e);
                }}
                options={selectOptions}
              />
            );
            break;
          default:
            renderedField = null;
        }

        return (
          <div key={field.id} className="my-5">
            {renderedField}
          </div>
        );
      })}
    </div>
  );
};

export default Form;
