// import { getPlan, addFeature, updateFeature } from '../../../redux/actions/plan';
// import { React, useState, useEffect } from 'react'
// import { useSelector, useDispatch } from 'react-redux'
// import toast, { Toaster } from 'react-hot-toast';
// import DropdownSelect from 'components/select';
// import Form from 'components/form';
// import CustomTabs from 'components/tabs';
// import DialogCustom from "../../../components/dialog";
// import Switch from 'components/switch';

// import Details from "./details"
// import Price from "./prices"

// import {
//     FiSearch,
//     FiEdit2,
//     FiTrash,
// } from "react-icons/fi";

// import {
//     Table,
//     TableBody,
//     TableCaption,
//     TableCell,
//     TableHead,
//     TableHeader,
//     TableRow,
// } from "../../../components/shadcn/table";

// function Feature(props) {
//     const { features, dispatch, planId, addedFeatures } = props
//     const [isDialogOpen, setIsDialogOpen] = useState(false);
//     const [isFeatureDialogOpen, setIsFeatureDialogOpen] = useState(false);
//     const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
//     const [featureFormData, setFeatureFormData] = useState({});
//     const [addedFeatureFormData, setAddedFeatureFormData] = useState({});
//     const [selectedFeature, setSelectedFeature] = useState({});

//     const handleCrudFeature = async () => {
//         if (!featureFormData.id) await dispatch(addFeature({ planId, ...featureFormData }))
//         await dispatch(getPlan(planId))
//         setIsDialogOpen(false)
//         setFeatureFormData({})
//     }
    
//     const handleCrudAddedFeature = async () => {
//         if (addedFeatureFormData.id) await dispatch(updateFeature({ planId, ...addedFeatureFormData }))
//         await dispatch(getPlan(planId))
//         setIsFeatureDialogOpen(false)
//         setAddedFeatureFormData({})
//     }



//     const featureFields = [
//         {
//             id: "features",
//             name: "features",
//             label: "Features",
//             placeholder: "Select the features to be included in this plan",
//             type: "multiselect",
//             options: features
//         }
//     ];
//     const addedFeatureFields = [
//         {
//             id: "maxCount",
//             name: "maxCount",
//             label: "Max Count",
//             placeholder: "Maximum count of the feature in this plan",
//             type: "number",
//         },
//         {
//             id: "status",
//             name: "status",
//             label: "Status",
//             placeholder: "Feature Status",
//             type: "switch",
//         },
//     ];
//     // console.log(features, "features PROPS")
//     return (
//         <>
//             <DialogCustom
//                 open={isDeleteDialogOpen}
//                 onOpenChange={setIsDeleteDialogOpen}
//                 dialogTitle={`${selectedFeature.status ? "Enable" : "Disable"}` + " Feature"}
//                 dialogWidth="w-1/2"
//                 dialogDesc={`Are you sure you want to ${selectedFeature.status ? "Disable" : "Enable"} this feature ?`}
//                 content={
//                     <div className='flex flex-row-reverse'>
//                         <button
//                             onClick={ async () => {
//                                 await dispatch(updateFeature(selectedFeature))
//                                 await dispatch(getPlan(planId))
//                                 setIsDeleteDialogOpen(false)
//                             }}
//                             className={` linear rounded-xl border-2 text-white border-white  px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-white hover:shadow-lg
//       ${selectedFeature.status ? "bg-green-700 hover:text-green-500 hover:border-2 hover:border-green-700 " : "bg-red-700 hover:text-red-500 hover:border-2 hover:border-red-700 "}
//       `}>
//                             {`${selectedFeature.status ? "Enable" : "Disable"}`}
//                         </button>
//                         <button
//                             onClick={() => {
//                                 setIsDeleteDialogOpen(false);
//                             }}
//                             className="mr-4 text-white linear rounded-xl border-2 border-white bg-brand-700 px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-700 hover:shadow-lg  ">
//                             Cancel
//                         </button>
//                     </div>
//                 }
//             />
//             <DialogCustom
//                 open={isDialogOpen}
//                 onOpenChange={setIsDialogOpen}
//                 dialogTitle={`${featureFormData.id ? "Update " : "Add "} Feature`}
//                 dialogWidth="w-1/2"
//                 dialogDesc={`${featureFormData.id ? "Update " : "Add "} the neccessary fields and save.`}
//                 content={
//                     <>
//                         <Form
//                             fields={featureFields}
//                             formData={featureFormData}
//                             onChange={(newFormData) => setFeatureFormData(newFormData)}
//                         />
//                         <div className='flex justify-end'>
//                             <button
//                                 onClick={handleCrudFeature}
//                                 className="border-2 text-white linear rounded-full bg-brand-700 px-4 py-3 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-500  active:!bg-white/70">
//                                 {featureFormData.id ? "Update" : "Add" + " Feature"}
//                             </button>
//                         </div>
//                     </>
//                 }
//             />
//             <DialogCustom
//                 open={isFeatureDialogOpen}
//                 onOpenChange={setIsFeatureDialogOpen}
//                 dialogTitle={`Update Feature`}
//                 dialogWidth="w-1/2"
//                 dialogDesc={`Update the neccessary fields and save.`}
//                 content={
//                     <>
//                         <Form
//                             fields={addedFeatureFields}
//                             formData={addedFeatureFormData}
//                             onChange={(newFormData) => setAddedFeatureFormData(newFormData)}
//                         />
//                         <div className='flex justify-end'>
//                             <button
//                                 onClick={handleCrudAddedFeature}
//                                 className="border-2 text-white linear rounded-full bg-brand-700 px-4 py-3 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-500  active:!bg-white/70">
//                                 {addedFeatureFormData.id ? "Update" : "Add" + " Feature"}
//                             </button>
//                         </div>
//                     </>
//                 }
//             />
//             {addedFeatures.length == 0 &&
//                 <div className='p-10 flex flex-col justify-center items-center'>
//                     <h4 className='mb-5 text-2xl text-brand-500 font-medium'> No Features Added! Click below to add one. </h4>
//                     <button
//                         onClick={() => {
//                             setIsDialogOpen(true);
//                             console.log("SETTED")
//                         }}
//                         className="border-2 text-white linear rounded-xl bg-brand-700 px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-500  active:!bg-white/70">
//                         Add Feature
//                     </button>
//                 </div>
//             }
//             {addedFeatures.length > 0 &&
//                 <Table className="border-b border-grey-500">
//                     <TableCaption className="mb-3 font-bold  text-2xl text-start text-black">
//                         <div className='flex justify-between items-center'>
//                             <div className='flex justify-between w-1/2 items-center'>
//                             </div>
//                             <div className='flex  justify-end'>
//                                 <button
//                                     onClick={() => setIsDialogOpen(true)}
//                                     className="border-2 text-white linear rounded-full bg-brand-700 px-4 py-3 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-500  active:!bg-white/70">
//                                     Add Feature
//                                 </button>

//                             </div>
//                         </div>
//                     </TableCaption>
//                     <TableHeader>

//                         <TableRow>
//                             <TableHead className="w-1/3"> Feature </TableHead>
//                             <TableHead className="w-1/3"> Max-Count </TableHead>
//                             <TableHead className="w-1/5"> Status </TableHead>
//                             <TableHead className="w-1/6 text-center ">Actions</TableHead>
//                         </TableRow>
//                     </TableHeader>
//                     <TableBody>
//                         {addedFeatures.map((feature) => (
//                             <TableRow key={feature.id}>
//                                 <TableCell className="font-medium">
//                                     <h4 className='text-brand-500 font-black'> {feature.feature.name} </h4>
//                                 </TableCell>
//                                 <TableCell className="font-medium">{feature.maxCount || 0}</TableCell>
//                                 <TableCell>
//                                     <Switch
//                                         key={feature.id}
//                                         id={feature.id}
//                                         checked={feature["status"] || false}
//                                         onChange={(e) => {
//                                             setSelectedFeature({ ...feature, ["status"]: e.target.checked })
//                                             setIsDeleteDialogOpen(true)

//                                         }}
//                                     />
//                                 </TableCell>
//                                 <TableCell className="w-1/6  ">
//                                     <div className='w-full flex justify-around'>
//                                         <div
//                                             onClick={() => {
//                                                 setAddedFeatureFormData(feature)
//                                                 setIsFeatureDialogOpen(true)
//                                             }} className='mx-2 bg-yellow-500 w-fit p-1 rounded-3xl cursor-pointer' >
//                                             <FiEdit2 className='h-4 w-4 text-white' />
//                                         </div>


//                                         {/* <div
//                                             // onClick={() => handleActionButtonClick(feature.id, "delete")}
//                                             className='mx-2 bg-red-500 w-fit p-1 rounded-3xl cursor-pointer'>
//                                             <FiTrash className='h-4 w-4 text-white' />
//                                         </div> */}
//                                     </div>
//                                 </TableCell>

//                             </TableRow>

//                         ))}


//                     </TableBody>
//                 </Table>
//             }
//         </>

//     );
// }

// const PlanDetails = () => {

//     const [formData, setFormData] = useState({});
//     const [planId, setPlanId] = useState(window.location.pathname.split("/")[3]);


//     // const features = [{
//     //     name: "attendance",
//     //     desc: "Enable students to record their attendances through the mobile app",
//     //     type: "boolean",
//     //     reset: null,
//     //     resetPeriod: null,
//     //     maxCount: null,
//     //     price: 25,
//     //     status: true
//     // }]


//     const dispatch = useDispatch();
//     const plan = useSelector((plans) => plans.plan.value.singlePlan);
//     console.log("plan for fet---------->>>>>>"+JSON.stringify(plan))
//     useEffect(() => {
//         dispatch(getPlan(planId)) 
//         // const action = dispatch(getPlan(planId)) 
//         // console.log("action -------.>>>>>>>"+JSON.stringify(action));
//         setFormData({
//             ...plan
//         })
//     }, [])
//     // console.log(plan, "PLANS")
//     return (
//         <div className="mt-3 h-full min-h-[84vh] !w-full rounded-xl bg-white flex flex-col md:flex-row sm:flex-row-reverse">
//             <CustomTabs
//                 tabs={
//                     [
//                         "Details",
//                         "Features",
//                         "Prices"
//                     ]
//                 }
//                 tabContents={
//                     [
//                         <Details />,
//                         <Feature features={plan.features} addedFeatures={plan.planfeatures} dispatch={dispatch} planId={planId} />,
//                         <Price dispatch={dispatch} plan={plan} />
//                     ]
//                 }
//             />


//         </div>


//     );
// };

// export default PlanDetails;
// {/* <Feature /> */ }






// // import { getPlan, addFeature, updateFeature } from '../../../redux/actions/plan';
// import { getModule } from '../../../redux/actions/modules';
// import { React, useState, useEffect } from 'react'
// import { useSelector, useDispatch } from 'react-redux'
// import toast, { Toaster } from 'react-hot-toast';
// import DropdownSelect from 'components/select';
// import Form from 'components/form';
// import CustomTabs from 'components/tabs';
// import DialogCustom from "../../../components/dialog";
// import Switch from 'components/switch';

// import Details from "./details"
// import Price from "./prices"

// import {
//     FiSearch,
//     FiEdit2,
//     FiTrash,
// } from "react-icons/fi";

// import {
//     Table,
//     TableBody,
//     TableCaption,
//     TableCell,
//     TableHead,
//     TableHeader,
//     TableRow,
// } from "../../../components/shadcn/table";

// function Feature(props) {
//     const { features, dispatch, planId, addedFeatures } = props
//     const [isDialogOpen, setIsDialogOpen] = useState(false);
//     const [isFeatureDialogOpen, setIsFeatureDialogOpen] = useState(false);
//     const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
//     const [featureFormData, setFeatureFormData] = useState({});
//     const [addedFeatureFormData, setAddedFeatureFormData] = useState({});
//     const [selectedFeature, setSelectedFeature] = useState({});

//     const handleCrudFeature = async () => {
//         if (!featureFormData.id) await dispatch(addFeature({ planId, ...featureFormData }))
//         await dispatch(getModule(planId))
//         setIsDialogOpen(false)
//         setFeatureFormData({})
//     }
    
//     const handleCrudAddedFeature = async () => {
//         if (addedFeatureFormData.id) await dispatch(updateFeature({ planId, ...addedFeatureFormData }))
//         await dispatch(getModule(planId))
//         setIsFeatureDialogOpen(false)
//         setAddedFeatureFormData({})
//     }



//     const featureFields = [
//         {
//             id: "features",
//             name: "features",
//             label: "Features",
//             placeholder: "Select the features to be included in this plan",
//             type: "multiselect",
//             options: features
//         }
//     ];
//     const addedFeatureFields = [
//         {
//             id: "maxCount",
//             name: "maxCount",
//             label: "Max Count",
//             placeholder: "Maximum count of the feature in this plan",
//             type: "number",
//         },
//         {
//             id: "status",
//             name: "status",
//             label: "Status",
//             placeholder: "Feature Status",
//             type: "switch",
//         },
//     ];
//     // console.log(features, "features PROPS")
//     return (
//         <>
//             <DialogCustom
//                 open={isDeleteDialogOpen}
//                 onOpenChange={setIsDeleteDialogOpen}
//                 dialogTitle={`${selectedFeature.status ? "Enable" : "Disable"}` + " Feature"}
//                 dialogWidth="w-1/2"
//                 dialogDesc={`Are you sure you want to ${selectedFeature.status ? "Disable" : "Enable"} this feature ?`}
//                 content={
//                     <div className='flex flex-row-reverse'>
//                         <button
//                             onClick={ async () => {
//                                 await dispatch(updateFeature(selectedFeature))
//                                 await dispatch(getModule(planId))
//                                 setIsDeleteDialogOpen(false)
//                             }}
//                             className={` linear rounded-xl border-2 text-white border-white  px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-white hover:shadow-lg
//       ${selectedFeature.status ? "bg-green-700 hover:text-green-500 hover:border-2 hover:border-green-700 " : "bg-red-700 hover:text-red-500 hover:border-2 hover:border-red-700 "}
//       `}>
//                             {`${selectedFeature.status ? "Enable" : "Disable"}`}
//                         </button>
//                         <button
//                             onClick={() => {
//                                 setIsDeleteDialogOpen(false);
//                             }}
//                             className="mr-4 text-white linear rounded-xl border-2 border-white bg-brand-700 px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-700 hover:shadow-lg  ">
//                             Cancel
//                         </button>
//                     </div>
//                 }
//             />
//             <DialogCustom
//                 open={isDialogOpen}
//                 onOpenChange={setIsDialogOpen}
//                 dialogTitle={`${featureFormData.id ? "Update " : "Add "} Feature`}
//                 dialogWidth="w-1/2"
//                 dialogDesc={`${featureFormData.id ? "Update " : "Add "} the neccessary fields and save.`}
//                 content={
//                     <>
//                         <Form
//                             fields={featureFields}
//                             formData={featureFormData}
//                             onChange={(newFormData) => setFeatureFormData(newFormData)}
//                         />
//                         <div className='flex justify-end'>
//                             <button
//                                 onClick={handleCrudFeature}
//                                 className="border-2 text-white linear rounded-full bg-brand-700 px-4 py-3 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-500  active:!bg-white/70">
//                                 {featureFormData.id ? "Update" : "Add" + " Feature"}
//                             </button>
//                         </div>
//                     </>
//                 }
//             />
//             <DialogCustom
//                 open={isFeatureDialogOpen}
//                 onOpenChange={setIsFeatureDialogOpen}
//                 dialogTitle={`Update Feature`}
//                 dialogWidth="w-1/2"
//                 dialogDesc={`Update the neccessary fields and save.`}
//                 content={
//                     <>
//                         <Form
//                             fields={addedFeatureFields}
//                             formData={addedFeatureFormData}
//                             onChange={(newFormData) => setAddedFeatureFormData(newFormData)}
//                         />
//                         <div className='flex justify-end'>
//                             <button
//                                 onClick={handleCrudAddedFeature}
//                                 className="border-2 text-white linear rounded-full bg-brand-700 px-4 py-3 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-500  active:!bg-white/70">
//                                 {addedFeatureFormData.id ? "Update" : "Add" + " Feature"}
//                             </button>
//                         </div>
//                     </>
//                 }
//             />
//             {addedFeatures.length == 0 &&
//                 <div className='p-10 flex flex-col justify-center items-center'>
//                     <h4 className='mb-5 text-2xl text-brand-500 font-medium'> No Features Added! Click below to add one. </h4>
//                     <button
//                         onClick={() => {
//                             setIsDialogOpen(true);
//                             console.log("SETTED")
//                         }}
//                         className="border-2 text-white linear rounded-xl bg-brand-700 px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-500  active:!bg-white/70">
//                         Add Feature
//                     </button>
//                 </div>
//             }
//             {addedFeatures.length > 0 &&
//                 <Table className="border-b border-grey-500">
//                     <TableCaption className="mb-3 font-bold  text-2xl text-start text-black">
//                         <div className='flex justify-between items-center'>
//                             <div className='flex justify-between w-1/2 items-center'>
//                             </div>
//                             <div className='flex  justify-end'>
//                                 <button
//                                     onClick={() => setIsDialogOpen(true)}
//                                     className="border-2 text-white linear rounded-full bg-brand-700 px-4 py-3 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-500  active:!bg-white/70">
//                                     Add Feature
//                                 </button>

//                             </div>
//                         </div>
//                     </TableCaption>
//                     <TableHeader>

//                         <TableRow>
//                             <TableHead className="w-1/3"> Feature </TableHead>
//                             <TableHead className="w-1/3"> Max-Count </TableHead>
//                             <TableHead className="w-1/5"> Status </TableHead>
//                             <TableHead className="w-1/6 text-center ">Actions</TableHead>
//                         </TableRow>
//                     </TableHeader>
//                     <TableBody>
//                         {addedFeatures.map((feature) => (
//                             <TableRow key={feature.id}>
//                                 <TableCell className="font-medium">
//                                     <h4 className='text-brand-500 font-black'> {feature.feature.name} </h4>
//                                 </TableCell>
//                                 <TableCell className="font-medium">{feature.maxCount || 0}</TableCell>
//                                 <TableCell>
//                                     <Switch
//                                         key={feature.id}
//                                         id={feature.id}
//                                         checked={feature["status"] || false}
//                                         onChange={(e) => {
//                                             setSelectedFeature({ ...feature, ["status"]: e.target.checked })
//                                             setIsDeleteDialogOpen(true)

//                                         }}
//                                     />
//                                 </TableCell>
//                                 <TableCell className="w-1/6  ">
//                                     <div className='w-full flex justify-around'>
//                                         <div
//                                             onClick={() => {
//                                                 setAddedFeatureFormData(feature)
//                                                 setIsFeatureDialogOpen(true)
//                                             }} className='mx-2 bg-yellow-500 w-fit p-1 rounded-3xl cursor-pointer' >
//                                             <FiEdit2 className='h-4 w-4 text-white' />
//                                         </div>


//                                         {/* <div
//                                             // onClick={() => handleActionButtonClick(feature.id, "delete")}
//                                             className='mx-2 bg-red-500 w-fit p-1 rounded-3xl cursor-pointer'>
//                                             <FiTrash className='h-4 w-4 text-white' />
//                                         </div> */}
//                                     </div>
//                                 </TableCell>

//                             </TableRow>

//                         ))}


//                     </TableBody>
//                 </Table>
//             }
//         </>

//     );
// }

// const PlanDetails = () => {

//     const [formData, setFormData] = useState({});
//     const [planId, setPlanId] = useState(window.location.pathname.split("/")[3]);


//     // const features = [{
//     //     name: "attendance",
//     //     desc: "Enable students to record their attendances through the mobile app",
//     //     type: "boolean",
//     //     reset: null,
//     //     resetPeriod: null,
//     //     maxCount: null,
//     //     price: 25,
//     //     status: true
//     // }]


//     const dispatch = useDispatch();
//     // const plan = useSelector((plans) => plans.plan.value.singlePlan);
//     const module = useSelector((modules) => modules.module.value.singleModule);
//     console.log("modules for fet---------->>>>>>"+JSON.stringify(modules))
//     useEffect(() => {
//         // dispatch(getPlan(planId))
//         dispatch(getModule(moduleId))
//         setFormData({
//             ...module
//         })
//     }, [dispatch])
//     // console.log(plan, "PLANS")
//     return (
//         <div className="mt-3 h-full min-h-[84vh] !w-full rounded-xl bg-white flex flex-col md:flex-row sm:flex-row-reverse">
//             <CustomTabs
//                 tabs={
//                     [
//                         "Details",
//                         "Features",
//                         "Prices"
//                     ]
//                 }
//                 tabContents={
//                     [
//                         <Details />,
//                         <Feature features={plan.features} addedFeatures={plan.planfeatures} dispatch={dispatch} planId={planId} />,
//                         <Price dispatch={dispatch} plan={plan} />
//                     ]
//                 }
//             />


//         </div>


//     );
// };

// export default PlanDetails;
// {/* <Feature /> */ }








import { getPlan, addFeature, updateFeature } from '../../../redux/actions/plan';
import { getModule } from '../../../redux/actions/modules';

import { React, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import toast, { Toaster } from 'react-hot-toast';
import DropdownSelect from 'components/select';
import Form from 'components/form';
import CustomTabs from 'components/tabs';
import DialogCustom from "../../../components/dialog";
import Switch from 'components/switch';

import Details from "./details"
import Price from "./prices"

import {
    FiSearch,
    FiEdit2,
    FiTrash,
} from "react-icons/fi";

import {
    Table,
    TableBody,
    TableCaption,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "../../../components/shadcn/table";

function Feature(props) {
    const { moduleId, dispatch, planId, addedFeatures } = props
    console.log("moduleId ========"+moduleId);
    useEffect(() => {
        dispatch(getModule(moduleId))        
    }, [dispatch])
    const module = useSelector((modules) => modules.module.value.singleModule);
    console.log("modulesssss "+JSON.stringify(module));
    const features = module.features
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isFeatureDialogOpen, setIsFeatureDialogOpen] = useState(false);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [featureFormData, setFeatureFormData] = useState({});
    const [addedFeatureFormData, setAddedFeatureFormData] = useState({});
    const [selectedFeature, setSelectedFeature] = useState({});

    const handleCrudFeature = async () => {
        if (!featureFormData.id) await dispatch(addFeature({ planId, ...featureFormData }))
        await dispatch(getPlan(planId))
        setIsDialogOpen(false)
        setFeatureFormData({})
    }
    
    const handleCrudAddedFeature = async () => {
        if (addedFeatureFormData.id) await dispatch(updateFeature({ planId, ...addedFeatureFormData }))
        await dispatch(getPlan(planId))
        setIsFeatureDialogOpen(false)
        setAddedFeatureFormData({})
    }



    const featureFields = [
        {
            id: "features",
            name: "features",
            label: "Features",
            placeholder: "Select the features to be included in this plan",
            type: "multiselect",
            options: features
        }
    ];
    const addedFeatureFields = [
        {
            id: "maxCount",
            name: "maxCount",
            label: "Max Count",
            placeholder: "Maximum count of the feature in this plan",
            type: "number",
        },
        {
            id: "status",
            name: "status",
            label: "Status",
            placeholder: "Feature Status",
            type: "switch",
        },
    ];
    // console.log(features, "features PROPS")
    return (
        <>
            <DialogCustom
                open={isDeleteDialogOpen}
                onOpenChange={setIsDeleteDialogOpen}
                dialogTitle={`${selectedFeature.status ? "Enable" : "Disable"}` + " Feature"}
                dialogWidth="w-1/2"
                dialogDesc={`Are you sure you want to ${selectedFeature.status ? "Disable" : "Enable"} this feature ?`}
                content={
                    <div className='flex flex-row-reverse'>
                        <button
                            onClick={ async () => {
                                await dispatch(updateFeature(selectedFeature))
                                await dispatch(getPlan(planId))
                                setIsDeleteDialogOpen(false)
                            }}
                            className={` linear rounded-xl border-2 text-white border-white  px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-white hover:shadow-lg
      ${selectedFeature.status ? "bg-green-700 hover:text-green-500 hover:border-2 hover:border-green-700 " : "bg-red-700 hover:text-red-500 hover:border-2 hover:border-red-700 "}
      `}>
                            {`${selectedFeature.status ? "Enable" : "Disable"}`}
                        </button>
                        <button
                            onClick={() => {
                                setIsDeleteDialogOpen(false);
                            }}
                            className="mr-4 text-white linear rounded-xl border-2 border-white bg-brand-700 px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-700 hover:shadow-lg  ">
                            Cancel
                        </button>
                    </div>
                }
            />
            <DialogCustom
                open={isDialogOpen}
                onOpenChange={setIsDialogOpen}
                dialogTitle={`${featureFormData.id ? "Update " : "Add "} Feature`}
                dialogWidth="w-1/2"
                dialogDesc={`${featureFormData.id ? "Update " : "Add "} the neccessary fields and save.`}
                content={
                    <>
                        <Form
                            fields={featureFields}
                            formData={featureFormData}
                            onChange={(newFormData) => setFeatureFormData(newFormData)}
                        />
                        <div className='flex justify-end'>
                            <button
                                onClick={handleCrudFeature}
                                className="border-2 text-white linear rounded-full bg-brand-700 px-4 py-3 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-500  active:!bg-white/70">
                                {featureFormData.id ? "Update" : "Add" + " Feature"}
                            </button>
                        </div>
                    </>
                }
            />
            <DialogCustom
                open={isFeatureDialogOpen}
                onOpenChange={setIsFeatureDialogOpen}
                dialogTitle={`Update Feature`}
                dialogWidth="w-1/2"
                dialogDesc={`Update the neccessary fields and save.`}
                content={
                    <>
                        <Form
                            fields={addedFeatureFields}
                            formData={addedFeatureFormData}
                            onChange={(newFormData) => setAddedFeatureFormData(newFormData)}
                        />
                        <div className='flex justify-end'>
                            <button
                                onClick={handleCrudAddedFeature}
                                className="border-2 text-white linear rounded-full bg-brand-700 px-4 py-3 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-500  active:!bg-white/70">
                                {addedFeatureFormData.id ? "Update" : "Add" + " Feature"}
                            </button>
                        </div>
                    </>
                }
            />
            {addedFeatures.length == 0 &&
                <div className='p-10 flex flex-col justify-center items-center'>
                    <h4 className='mb-5 text-2xl text-brand-500 font-medium'> No Features Added! Click below to add one. </h4>
                    <button
                        onClick={() => {
                            setIsDialogOpen(true);
                            console.log("SETTED")
                        }}
                        className="border-2 text-white linear rounded-xl bg-brand-700 px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-500  active:!bg-white/70">
                        Add Feature
                    </button>
                </div>
            }
            {addedFeatures.length > 0 &&
                <Table className="border-b border-grey-500">
                    <TableCaption className="mb-3 font-bold  text-2xl text-start text-black">
                        <div className='flex justify-between items-center'>
                            <div className='flex justify-between w-1/2 items-center'>
                            </div>
                            <div className='flex  justify-end'>
                                <button
                                    onClick={() => setIsDialogOpen(true)}
                                    className="border-2 text-white linear rounded-full bg-brand-700 px-4 py-3 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-500  active:!bg-white/70">
                                    Add Feature
                                </button>

                            </div>
                        </div>
                    </TableCaption>
                    <TableHeader>

                        <TableRow>
                            <TableHead className="w-1/3"> Feature </TableHead>
                            <TableHead className="w-1/3"> Max-Count </TableHead>
                            <TableHead className="w-1/5"> Status </TableHead>
                            <TableHead className="w-1/6 text-center ">Actions</TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {addedFeatures.map((feature) => (
                            <TableRow key={feature.id}>
                                <TableCell className="font-medium">
                                    <h4 className='text-brand-500 font-black'> {feature.feature.name} </h4>
                                </TableCell>
                                <TableCell className="font-medium">{feature.maxCount || 0}</TableCell>
                                <TableCell>
                                    <Switch
                                        key={feature.id}
                                        id={feature.id}
                                        checked={feature["status"] || false}
                                        onChange={(e) => {
                                            setSelectedFeature({ ...feature, ["status"]: e.target.checked })
                                            setIsDeleteDialogOpen(true)

                                        }}
                                    />
                                </TableCell>
                                <TableCell className="w-1/6  ">
                                    <div className='w-full flex justify-around'>
                                        <div
                                            onClick={() => {
                                                setAddedFeatureFormData(feature)
                                                setIsFeatureDialogOpen(true)
                                            }} className='mx-2 bg-yellow-500 w-fit p-1 rounded-3xl cursor-pointer' >
                                            <FiEdit2 className='h-4 w-4 text-white' />
                                        </div>


                                        {/* <div
                                            // onClick={() => handleActionButtonClick(feature.id, "delete")}
                                            className='mx-2 bg-red-500 w-fit p-1 rounded-3xl cursor-pointer'>
                                            <FiTrash className='h-4 w-4 text-white' />
                                        </div> */}
                                    </div>
                                </TableCell>

                            </TableRow>

                        ))}


                    </TableBody>
                </Table>
            }
        </>

    );
}

const PlanDetails = () => {

    const [formData, setFormData] = useState({});
    const [planId, setPlanId] = useState(window.location.pathname.split("/")[3]);


    // const features = [{
    //     name: "attendance",
    //     desc: "Enable students to record their attendances through the mobile app",
    //     type: "boolean",
    //     reset: null,
    //     resetPeriod: null,
    //     maxCount: null,
    //     price: 25,
    //     status: true
    // }]


    const dispatch = useDispatch();
    const plan = useSelector((plans) => plans.plan.value.singlePlan);
    console.log("plan for fet---------->>>>>>"+JSON.stringify(plan))
    console.log("plan for fet plan.moduleId---------->>>>>>"+JSON.stringify(plan.moduleId))
    // useEffect(()=>{
    //     const action = dispatch(getModule(plan.moduleId))
    //     console.log("action module fet -------->>>>>>>"+JSON.stringify(action));
    // },[])
    const module = useSelector((modules) => modules.module.value.singleModule);
    useEffect(() => {
        dispatch(getPlan(planId)) 
        // const action = dispatch(getPlan(planId)) 
        // console.log("action -------.>>>>>>>"+JSON.stringify(action));
        setFormData({
            ...plan
        })
    }, [])
    // console.log(plan, "PLANS")
    return (
        <div className="mt-3 h-full min-h-[84vh] !w-full rounded-xl bg-white flex flex-col md:flex-row sm:flex-row-reverse">
            <CustomTabs
                tabs={
                    [
                        "Details",
                        "Features",
                        "Prices"
                    ]
                }
                tabContents={
                    [
                        <Details />,
                        // <Feature features={plan.features} addedFeatures={plan.planfeatures} dispatch={dispatch} planId={planId} />,
                        <Feature  addedFeatures={plan.planfeatures} dispatch={dispatch} planId={planId} moduleId={plan.moduleId} />,
                        <Price dispatch={dispatch} plan={plan} />
                    ]
                }
            />


        </div>


    );
};

export default PlanDetails;
{/* <Feature /> */ }
