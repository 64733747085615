import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { createHeaders } from "./headers";

export const getBusinesses = createAsyncThunk("business/get", async (query) => {
  console.log(query);
  let filteredQuery = Object.fromEntries(
    Object.entries(query).filter(
      ([key, value]) => value != null && value !== ""
    )
  );
  let params = new URLSearchParams(filteredQuery);
  let response = await fetch(
    `${process.env.REACT_APP_URL}api/business/?${params.toString()}`,
    {
      headers: createHeaders(),
    }
  );
  let json = await response.json();
  console.log(json);
  return json;
});

export const StudentsParentsCount = createAsyncThunk("StudentsParentsCount/get", async () => {
  // console.log(query);
  // let filteredQuery = Object.fromEntries(
  //   Object.entries(query).filter(
  //     ([key, value]) => value != null && value !== ""
  //   )
  // );
  // let params = new URLSearchParams(filteredQuery);
  let response = await fetch(
    `${process.env.REACT_APP_URL}api/studentparentcount`,
    {
      headers: createHeaders(),
    }
  );
  let json = await response.json();
  console.log(json);
  return json;
});

export const getBusiness = createAsyncThunk(
  "business/getSingleBusiness",
  async (businessId) => {
    // let params = new URLSearchParams(filteredQuery);
    let response = await fetch(
      `${process.env.REACT_APP_URL}api/business/fetchSingleBusiness/${businessId}`,
      {
        headers: createHeaders(),
      }
    );
    let json = await response.json();
    console.log(json);
    return json;
  }
);

export const addBusiness = createAsyncThunk("business/add", async (data) => {
  const response = await fetch(`${process.env.REACT_APP_URL}api/business/`, {
    method: "POST",
    headers: createHeaders(),
    body: JSON.stringify(data),
  });
  const json = await response.json();
  return json;
});

export const updateBusiness = createAsyncThunk(
  "business/update",
  async (data) => {
    console.log(data, "ID and DATA for update");
    let response = await fetch(`${process.env.REACT_APP_URL}api/business/`, {
      method: "PUT", // or 'PATCH' depending on your API
      headers: createHeaders(),
      body: JSON.stringify({ ...data }), // Include the id in the request body
    });
    let json = await response.json();
    return json;
  }
);

// export const deleteBusiness = createAsyncThunk("business/delete", async (id) => {
//   console.log(id, "ID for delete");
//   let response = await fetch(`${process.env.REACT_APP_URL}api/business/${id}`, {
//     method: 'DELETE',
//     headers: createHeaders(),
//   });
//   let json = await response.json();
//   return json;
// });

export const businessesSlice = createSlice({
  name: "business",
  initialState: {
    value: {
      isError: false,
      isLoading: false,
      status: "",
      // parentsCount:0,
      // studentsCount:0,
      data: {
        count: 0,
        activeCount: 0,
        inactiveCount: 0,
        rows: [],
        totalStudents: 0,
        totalParents: 0,
      },
      userInput: {
        name: "",
        code: "",
        type: "",
        mobile: "",
        status: false,
        email: "",
        registeredAddress: "",
        country: "",
        referCode: "",
        category: "",
        signupSource: "",
        panNo: "",
        gstNo: "",
      },
    },
  },
  reducers: {
    updateUserInput: (state, action) => {
      console.log(
        "inside updateUserInput redux --------------------------->" +
          JSON.stringify(action.payload)
      );
      state.value.userInput = { ...state.value.userInput, ...action.payload };
    },
    resetUserInput: (state) => {
      // Reset all userInput values to ""
      // Object.keys(state.value.userInput).forEach((key) => {
      //   state.value.userInput[key] = "";
      // });

      // Object.keys(state.value.userInput).map((key) => {
      //   if (key === "status") {
      //     return [key, false];
      //   } else {
      //     return [key, ""];
      //   }
      // })
      state.value.userInput = Object.fromEntries(
        Object.keys(state.value.userInput).map((key) => {
          if (key === "status") {
            return [key, false];
          } else {
            return [key, ""];
          }
        })
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBusinesses.fulfilled, (state, action) => {
        console.log(action.payload, "DATA FETCHED");
        state.value.data.rows = action.payload.rows;
        // state.value.data.count = action.payload.count;
        state.value.data.count = action.payload.totalBusinesses;
        state.value.data.activeCount = action.payload.activeBusinesses;
        state.value.data.inactiveCount = action.payload.inactiveBusinesses;

        // console.log('Updated state:', state);
      })
      .addCase(getBusinesses.pending, (state) => {
        state.status = "Fetching todos. Please wait a moment...";
      })
      .addCase(getBusinesses.rejected, (state) => {
        state.status = "Failed to  fetch data...";
      })

      .addCase(updateBusiness.fulfilled, (state, action) => {
        // state.value.userInput = Object.fromEntries(
        //   Object.keys(state.value.userInput).map((key) => [key, ""])
        // );
        // state.value.userInput = Object.fromEntries(
        //   Object.keys(state.value.userInput).map((key) => {
        //     if (key === "status") {
        //       return [key, false];
        //     } else {
        //       return [key, ""];
        //     }
        //   })
        // );
        
      })
      .addCase(updateBusiness.pending, (state) => {
        state.status = "Updating state. Please wait a moment...";
      })
      .addCase(updateBusiness.rejected, (state) => {
        state.status = "Failed to update state...";
      })
      // .addCase(deleteBusiness.fulfilled, (state, action) => {
      //   // filter out the state with the deleted id
      //   state.value.data = state.value.data.filter(item => item.id !== action.payload.id);
      // })
      // .addCase(deleteBusiness.pending, (state) => {
      //   state.status = "Deleting state. Please wait a moment...";
      // })
      // .addCase(deleteBusiness.rejected, (state) => {
      //   state.status = "Failed to delete state...";
      // })

      //   .addCase(addBusiness.fulfilled, (state, action) => {
      //     state.value.userInput = {}
      //     // state.value.data.push(action.payload); // add the new state to the list
      //  })
      .addCase(addBusiness.fulfilled, (state, action) => {
        // state.value.userInput = Object.fromEntries(
        //   Object.keys(state.value.userInput).map((key) => [key, ""])
        // );
        // state.value.userInput = Object.fromEntries(
        //   Object.keys(state.value.userInput).map((key) => {
        //     if (key === "status") {
        //       return [key, false];
        //     } else {
        //       return [key, ""];
        //     }
        //   })
        // );
        
      })
      .addCase(addBusiness.pending, (state) => {
        state.status = "Adding state. Please wait a moment...";
      })
      .addCase(addBusiness.rejected, (state) => {
        state.status = "Failed to add state...";
      })
      // .addCase(getBusiness.fulfilled, (state, action) => {
      //   // Handle the fulfilled state of getBusiness
      //   console.log(action.payload, "SINGLE BUSINESS DATA FETCHED");
      //   state.value.userInput = {...state.value.userInput, ...action.payload}
      // })

      .addCase(getBusiness.fulfilled, (state, action) => {
        // Handle the fulfilled state of getBusiness
        console.log(action.payload, "SINGLE BUSINESS DATA FETCHED");
        
        // Iterate over the keys of state.value.userInput
        Object.keys(state.value.userInput).forEach(key => {
            // Update the state value only if the key exists in action.payload
            if (action.payload.hasOwnProperty(key)) {
                state.value.userInput[key] = action.payload[key];
            }
        });
    })
      .addCase(getBusiness.pending, (state) => {
        // Handle the pending state of getBusiness
        state.status = "Fetching single business. Please wait...";
      })
      .addCase(getBusiness.rejected, (state) => {
        // Handle the rejected state of getBusiness
        state.status = "Failed to fetch single business...";
      })

      .addCase(StudentsParentsCount.fulfilled, (state, action) => {
        state.value.data.totalStudents = action.payload.studentsCount;
        state.value.data.totalParents = action.payload.parentsCount;

        // console.log('Updated state:', state);
      })
      .addCase(StudentsParentsCount.pending, (state) => {
        // state.status = "Updating state. Please wait a moment...";
      })
      .addCase(StudentsParentsCount.rejected, (state) => {
        // state.status = "Failed to update state...";
      })
      
  },
});

export const { updateUserInput, resetUserInput } = businessesSlice.actions;
export default businessesSlice.reducer;
