import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { createHeaders } from './headers';


export const getModules = createAsyncThunk("module/get", async (query) => {
  console.log(query)
  let filteredQuery = Object.fromEntries(
    Object.entries(query).filter(([key, value]) => value != null && value !== '')
  );
  let params = new URLSearchParams(filteredQuery);
  let response = await fetch(`${process.env.REACT_APP_URL}api/module/?${params.toString()}`, {
    headers: createHeaders()
  });
  let json = await response.json();
  console.log(json);
  return json;
});

export const getModule = createAsyncThunk("singlemodule/get", async (moduleId) => {
  try {
    let response = await fetch(`${process.env.REACT_APP_URL}api/module/${moduleId}`, {
      headers: createHeaders()
    });
    let json = await response.json();
    console.log(json);
    return json;
  } catch (err) {
    console.log(err)
  }
});

export const addModule = createAsyncThunk("module/add", async (data) => {
  const response = await fetch(`${process.env.REACT_APP_URL}api/module/`, {
    method: 'POST',
    headers: createHeaders(),
    body: JSON.stringify(data),
  });
  const json = await response.json();
  return json;
});


export const updateModule = createAsyncThunk("module/update", async (data) => {
  console.log(data, "ID and DATA for update");
  let response = await fetch(`${process.env.REACT_APP_URL}api/module/`, {
    method: 'PUT', // or 'PATCH' depending on your API
    headers: createHeaders(),
    body: JSON.stringify({ ...data }) // Include the id in the request body
  });
  let json = await response.json();
  return json;
});

export const deleteModule = createAsyncThunk("module/delete", async (id) => {
  console.log(id, "ID for delete");
  let response = await fetch(`${process.env.REACT_APP_URL}api/module/${id}`, {
    method: 'DELETE',
    headers: createHeaders(),
  });
  let json = await response.json();
  return json;
});

export const modulesSlice = createSlice({
  name: 'module',
  initialState: {
    value: {
      isError: false,
      singleModule: {},
      isLoading: false,
      status: '',
      data: {
        count: 0,
        activeCount: 0,
        inactiveCount: 0,
        rows: []
      }
    }
  },
  reducers: {

  },
  extraReducers: (builder) => {
    builder
      .addCase(getModules.fulfilled, (state, action) => {
        console.log(action.payload, "DATA FETCHED")
        state.value.data.rows = action.payload.rows
        state.value.data.count = action.payload.totalModules
        state.value.data.activeCount = action.payload.activeModules
        state.value.data.inactiveCount = action.payload.inactiveModules
        // console.log('Updated state:', state);
      })
      .addCase(getModule.fulfilled, (state, action) => {
        state.value.singleModule = action.payload
      })
      .addCase(getModules.pending, (state) => {
        state.status = "Fetching todos. Please wait a moment..."
      })
      .addCase(getModules.rejected, (state) => {
        state.status = "Failed to  fetch data..."
      })
      .addCase(updateModule.fulfilled, (state, action) => {
        // find the index of the state with the updated id
        // const index = state.value.data.findIndex(item => item.id === action.payload.id);
        // if (index !== -1) {
        //   // replace the state at the found index with the updated state
        //   state.value.data[index] = action.payload;
        // }
      })
      .addCase(updateModule.pending, (state) => {
        state.status = "Updating state. Please wait a moment...";
      })
      .addCase(updateModule.rejected, (state) => {
        state.status = "Failed to update state...";
      })
      .addCase(deleteModule.fulfilled, (state, action) => {
        // filter out the state with the deleted id
        state.value.data.rows = state.value.data.rows.filter(item => item.id !== action.payload.id);
      })
      .addCase(deleteModule.pending, (state) => {
        state.status = "Deleting state. Please wait a moment...";
      })
      .addCase(deleteModule.rejected, (state) => {
        state.status = "Failed to delete state...";
      })
      .addCase(addModule.fulfilled, (state, action) => {
        // state.value.data.push(action.payload); // add the new state to the list
      })
      .addCase(addModule.pending, (state) => {
        state.status = "Adding state. Please wait a moment...";
      })
      .addCase(addModule.rejected, (state) => {
        state.status = "Failed to add state...";
      });
  }
})

export default modulesSlice.reducer
