import { getModule } from '../../../redux/actions/modules';
// import { updateFeature, addFeature } from '../../../redux/actions/plan';
import { addFeature, updateFeature } from '../../../redux/actions/feature';
// import { getPlans } from '../../../redux/actions/role';
// import { getPlans } from '../../../redux/actions/plan';
import { React, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import toast, { Toaster } from 'react-hot-toast';
import DropdownSelect from 'components/select';
import Form from 'components/form';
import CustomTabs from 'components/tabs';
import DialogCustom from "../../../components/dialog";
import Switch from 'components/switch';

import Details from "./details"

import {
    FiTrendingUp,
    FiX,
    FiLayers,
    FiSearch,
    FiSettings,
    FiEdit2,
    FiTrash,
} from "react-icons/fi";

import {
    Table,
    TableBody,
    TableCaption,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "../../../components/shadcn/table";

function Feature(props) {
    const { dispatch, module } = props
    // dispatch(getPlans())
    const plans = useSelector((plans) => plans.plan.value.data.rows);
    console.log("module id --------------"+JSON.stringify(module));
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [featureQuery, setFeatureQuery] = useState({ name: "" });
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [featureFormData, setFeatureFormData] = useState({});
    const [selectedFeature, setSelectedFeature] = useState({});
    // const [features, setFeatures] = useState(module.features);
    console.log(featureQuery, "FEATURE");
    const features = module.features
    let totalPrice
    if (module.features) totalPrice = module.features.reduce((total, feature) => total + feature.price, 0);
    console.log(totalPrice, "totalPrice")
    const handleCrudFeature = async () => {
        if (featureFormData.id) await dispatch(updateFeature({ ...featureFormData }))
        if (!featureFormData.id) await dispatch(addFeature({ moduleId: module.id, ...featureFormData }))

        await dispatch(getModule(module.id))
        setIsDialogOpen(false)
        setFeatureFormData({})
        setSelectedFeature({})
    }

    // const handleCrudFeature = async () => {
    //     try {
    //       setIsDialogOpen(false);
    //       if (featureFormData.id) {
    //         const action = await dispatch(updateFeature({ ...featureFormData }))
    //         // dispatch(getZones(filter));
    //         dispatch(getModule(module.id))
    //         if (action.payload.success) {
    //           notifySuccess(action.payload.message);
    //         } else {
    //           notifyError(action.payload.message);
    //         }
    //       } else {
    //         // setFeatureFormData({})
    //         setSelectedFeature({})
    //         const action = await dispatch(addFeature({ moduleId: module.id, ...featureFormData }))
    //         // dispatch(getZones(filter));
    //         dispatch(getModule(module.id))
    //         if (action.payload.success) {
    //           notifySuccess(action.payload.message);
    //         } else {
    //           notifyError(action.payload.message);
    //         }
    //       }
    //     //   setFormData({});
    //       //dispatch(getZones());
    //       setIsDialogOpen(false)
    //     setFeatureFormData({})
    //     setSelectedFeature({})
    //     } catch (error) {
    //       console.error("Failed to add feature:", error);
    //     }
    //   };
      const notifySuccess = (message) =>
    toast.success(message, {
      style: {
        padding: "35px",
        color: "#a0ca00",
      },
      duration: 5000,
      iconTheme: {
        primary: "#a0ca00",
        secondary: "#222c25",
      },
    });
  const notifyError = (message) =>
    toast.error(message, {
      style: {
        border: "1px solid #fff",
        padding: "35px",
        color: "red",
      },
      duration: 5000,
      iconTheme: {
        primary: "red",
        secondary: "#fff",
      },
    });

    // useEffect(() => {
    //     console.log(featureQuery.name, "featureQuery")
    //     // dispatch(getModule(moduleId))
    //     // features = ;
    //     // if(featureQuery.name == "") {
    //     //     console.log("yes, empty")
    //     //     setFeatures(features)
    //     //     return null
    //     // } else {
    //     //     setFeatures(features.filter(feature => feature.name.includes(featureQuery.name)))
    //     // }
    //     // console.log(features.filter(feature => feature.name.includes(featureQuery.name)))
    // }, [featureQuery])

    const featureFields = [
        {
            id: "name",
            name: "name",
            label: "Feature Name",
            placeholder: "Enter Feature's Name",
            type: "text",
        },
        // {
        //     id: "price",
        //     name: "price",
        //     label: "price",
        //     placeholder: "price",
        //     type: "number",
        // },
        {
            id: "reset",
            name: "reset",
            label: "Reset",
            desc: "Set reset as active/inactive",
            type: "switch",
        },
        {
            id: "resetDate",
            name: "resetDate",
            label: "Reset",
            isSingle: true,
            placeholder: "Reset Date",
            desc: "Set reset date",
            type: "datetime",
        },
        {
            id: "Status",
            name: "status",
            label: "Status",
            desc: "Set status as active/inactive",
            type: "switch",
        },
        {
            id: "type",
            name: "type",
            label: "Feature Type",
            desc: "Select a Type",
            type: "dropdown",
            toSelect: "name",
            isOutterLevel:true,
            selectedvalue: "type",
            placeHolder:'select feature type',
            list: [
                {id: 1, name: "Boolean"},
                {id: 2, name: "Count"},
            ],
        },
        // {
        //     id: "planId",
        //     name: "planId",
        //     label: "Plan",
        //     placeHolder: "Select Role",
        //     type: "dropdown",
        //     //list: states.map((state) => ({ id: state.id, name: state.name })),
        //     list: plans,
        //     toSelect: "id",
        //     //selectedvalue: formData.state ? formData.state.name : 'oops!',
        //     selectedvalue: "role",
        //   },
    ];
    // console.log(features, "features PROPS")
    return (
        <>
            <DialogCustom
                open={isDeleteDialogOpen}
                onOpenChange={setIsDeleteDialogOpen}
                dialogTitle={`${selectedFeature.status ? "Enable" : "Disable"}` + " Feature"}
                dialogWidth="w-1/2"
                dialogDesc={`Are you sure you want to ${selectedFeature.status ? "Disable" : "Enable"} this feature ?`}
                content={
                    <div className='flex flex-row-reverse'>
                        <button
                            onClick={async () => {
                                await dispatch(updateFeature(selectedFeature))
                                await dispatch(getModule(module.id))
                                setIsDeleteDialogOpen(false)
                            }}
                            className={` linear rounded-xl border-2 text-white border-white  px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-white hover:shadow-lg
      ${selectedFeature.status ? "bg-green-700 hover:text-green-500 hover:border-2 hover:border-green-700 " : "bg-red-700 hover:text-red-500 hover:border-2 hover:border-red-700 "}
      `}>
                            {`${selectedFeature.status ? "Enable" : "Disable"}`}
                        </button>
                        <button
                            onClick={() => {
                                setIsDeleteDialogOpen(false);
                            }}
                            className="mr-4 text-white linear rounded-xl border-2 border-white bg-brand-700 px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-700 hover:shadow-lg  ">
                            Cancel
                        </button>
                    </div>
                }
            />
            <DialogCustom
                open={isDialogOpen}
                onOpenChange={setIsDialogOpen}
                dialogTitle={`${featureFormData.id ? "Update " : "Add "} Feature`}
                dialogWidth="w-1/2"
                dialogDesc={`${featureFormData.id ? "Update " : "Add "} the neccessary fields and save.`}
                content={
                    <>
                        <Form
                            fields={featureFields}
                            formData={featureFormData}
                            onChange={(newFormData) => setFeatureFormData(newFormData)}
                        />
                        <div className='flex justify-end'>
                            {console.log("featureFields--"+JSON.stringify(featureFields))}
                            {console.log("featureFormData--"+JSON.stringify(featureFormData))}
                            <button
                                onClick={handleCrudFeature}
                                className="border-2 text-white linear rounded-full bg-brand-700 px-4 py-3 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-500  active:!bg-white/70">
                                {featureFormData.id ? "Update" : "Add" + " Feature"}
                            </button>
                        </div>
                    </>
                }
            />
            {features.length == 0 &&
                <div className='p-10 flex flex-col justify-center items-center'>
                    <h4 className='mb-5 text-2xl text-brand-500 font-medium'> No Features Added! Click below to add one. </h4>
                    <button
                        onClick={() => {
                            setIsDialogOpen(true);
                            console.log("SETTED")
                        }}
                        className="border-2 text-white linear rounded-xl bg-brand-700 px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-500  active:!bg-white/70">
                        Add Feature
                    </button>
                </div>
            }
            {features.length > 0 &&
                <Table className="border-b border-grey-500">
                    <TableCaption className="mb-3 font-bold  text-2xl text-start text-black">
                        {/* <p className='text-navy-700'> {module.name + " - Min. Rs." + totalPrice} </p> */}
                        <div className='flex justify-between items-center'>
                            <div className='flex justify-between w-1/2 items-center'>
                                {/* <div className="mr-5 p-4 flex h-full items-center rounded-full bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white ">
                                    <p className="pl-3 pr-2 text-xl">
                                        <FiSearch className="h-4 w-4 text-gray-400 dark:text-white" />
                                    </p>
                                    <input
                                        value={featureQuery.name}
                                        onChange={(e) => {
                                            setFeatureQuery({ ...featureQuery, ["name"]: e.target.value })
                                        }}
                                        type="text"
                                        placeholder="Search..."
                                        className="block h-full w-full  bg-lightPrimary text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:w-fit"
                                    />
                                </div> */}
                                {/* <DropdownSelect
                                    list={[
                                        { name: "All", id: 1 },
                                        { name: "Active", id: 2 },
                                        { name: "Inactive", id: 3 },
                                    ]}
                                    triggerStyles="bg-lightPrimary !rounded-full"
                                    // listStyles="text-sm text-red-700"
                                    placeHolder={"Status"}
                                    toSelect={"name"}
                                onChange={(value) => {
                                    setFeatureQuery({ ...featureQuery, ["status"]: value })
                                }}
                                /> */}
                            </div>
                            <div className='flex  justify-end'>
                                <button
                                    onClick={() => {
                                        setIsDialogOpen(true)
                                        setFeatureFormData({})
                                    }}
                                    className="border-2 text-white linear rounded-full bg-brand-700 px-4 py-3 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-500  active:!bg-white/70">
                                    Add Feature
                                </button>

                            </div>
                        </div>
                    </TableCaption>
                    <TableHeader>

                        <TableRow>
                            <TableHead className="w-1/3"> Feature </TableHead>
                            <TableHead className="w-1/3"> Price </TableHead>
                            <TableHead className="w-1/5"> Status </TableHead>
                            <TableHead className="w-1/6 text-center ">Actions</TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {features.map((feature) => (
                            <TableRow key={feature.id}>
                                <TableCell className="font-medium">
                                    <h4 className='text-brand-500 font-black'> {feature.name} </h4>
                                </TableCell>
                                <TableCell className="font-medium">{feature.price || 0}</TableCell>
                                <TableCell>
                                    <Switch
                                        key={feature.id}
                                        id={feature.id}
                                        checked={feature["status"] || false}
                                        onChange={(e) => {
                                            setSelectedFeature({ ...feature, ["status"]: e.target.checked })
                                            setIsDeleteDialogOpen(true)

                                        }}
                                    />
                                </TableCell>
                                <TableCell className="w-1/6  ">
                                    <div className='w-full flex justify-around'>
                                        <div
                                            onClick={() => {
                                                setFeatureFormData(feature)
                                                setIsDialogOpen(true)
                                            }}
                                            className='mx-2 bg-yellow-500 w-fit p-1 rounded-3xl cursor-pointer' >
                                            <FiEdit2 className='h-4 w-4 text-white' />
                                        </div>


                                        {/* <div
                                            // onClick={() => handleActionButtonClick(feature.id, "delete")}
                                            className='mx-2 bg-red-500 w-fit p-1 rounded-3xl cursor-pointer'>
                                            <FiTrash className='h-4 w-4 text-white' />
                                        </div> */}
                                    </div>
                                </TableCell>

                            </TableRow>

                        ))}


                    </TableBody>
                    <Toaster />
                </Table>
            }
        </>

    );
}

const ModuleDetails = () => {

    const [formData, setFormData] = useState({});
    const [moduleId, setModuleId] = useState(window.location.pathname.split("/")[3]);

    const dispatch = useDispatch();
    const module = useSelector((modules) => modules.module.value.singleModule);
    useEffect(() => {
        dispatch(getModule(moduleId))
        // dispatch(getPlans({ status: "Active", limit: 100 }))
        setFormData({
            ...module
        })
    }, [dispatch])
    // console.log(module.features, "moduleId")

    return (
        <div className='h-full min-h-[84vh] !w-full rounded-xl bg-white mt-3 '>
            {/* <h1 className='text-3xl text-navy-700 text-center p-5'> {module.name} </h1> */}
            <div className="flex flex-col md:flex-row sm:flex-row-reverse">
                <CustomTabs
                    tabs={
                        ["Details", "Features"]
                    }
                    tabContents={
                        [<Details module={module} />, <Feature dispatch={dispatch} module={module} />]
                    }
                />
            </div>
        </div>
    );
};

export default ModuleDetails;
{/* <Feature /> */ }