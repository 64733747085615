

import { useDispatch, useSelector } from 'react-redux';
// import { setUsername, setPassword, setError, setErrorMessage } from '../../../redux/actions/signIn';
// import { Link } from 'react-router-dom';
import InputField from "components/fields/InputField";
import { FcGoogle } from "react-icons/fc";
import Checkbox from "components/checkbox";
import toast, { Toaster } from 'react-hot-toast';
import { useState, useEffect } from 'react';
import { login } from '../../../redux/actions/auth';
import { Link, useNavigate } from 'react-router-dom';



export default function SignIn() {
  const dispatch = useDispatch();
  const navigate = useNavigate();  
  const isLoggedIn = useSelector((auth) => auth.auth.value.isLoggedIn);
  const status = useSelector((auth) => auth.auth.value.status);
  const [ username, setUsername] = useState("")
  const [ password, setPassword] = useState("")
  const [ validation, setValidation] = useState({ valid: false, error: "" })
  
  // useEffect(() => {
  //   dispatch(login({username: username, password: password}));
  // }, [dispatch]);

  useEffect(() => {
    validate(username, password);
  }, [username, password]);

  const validate = (username, password) => {
    console.log(username, password, "validate");
  
    // username must be 3-16 characters (letters, numbers, underscores and hyphens only)
    const usernameRegex = /^[a-zA-Z0-9_-]{3,16}$/;
  
    // password must be 8-20 characters, at least one letter and one number
    const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,20}$/;
  
    if (!usernameRegex.test(username)) {
      setValidation({ valid: false, error: "Invalid username. Must be 3-16 characters long and can include letters, numbers, underscores and hyphens only." });
    } else if (!passwordRegex.test(password)) {
      setValidation({ valid: false, error: "Invalid password. Must be 8-20 characters long and must include at least one letter and one number." });
    } else {
      setValidation({ valid: true, error: "" });
    }
  };
  
  /*
  const handleLogin = async (username, password) => {
    if(!validation.valid) return notifyError(validation.error)
    dispatch( login({username, password}))
    console.log(status, "handleLogin")
  console.log(isLoggedIn, "handleLogin")
    if(!isLoggedIn) {
      return notifyError(status)
    } else {
      notifySuccess(status)
      navigate("/admin/default")
      return 0
      // return <Redirect to='/admin/default'  />
    }
  };*/
  
  
  const handleLogin = async (username, password) => {
    if(!validation.valid) return notifyError(validation.error)
    const action = await dispatch( login({username, password}))
      if(action.payload.success){
      	notifySuccess(action.payload.message);
      	setTimeout(() => {
        navigate("/admin/default")
      }, 1000);
      }
      else{
      	notifyError(action.payload.message);
      }
    }
  
  
  
      
   
  const notifySuccess = (message) =>
    toast.success(message, {
      style: {
        padding: "35px",
        color: "#a0ca00",
      },
      duration: 1000,
      iconTheme: {
        primary: "#a0ca00",
        secondary: "#222c25",
      },
    });
  const notifyError = (message) =>
    toast.error(message, {
      style: {
        border: "1px solid #fff",
        padding: "35px",
        color: "red",
      },
      duration: 5000,
      iconTheme: {
        primary: "red",
        secondary: "#fff",
      },
    });
  
  return (
    <div className="mt-16 mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">Sign In</h4>
        <p className="mb-9 ml-1 text-base text-gray-600">Enter your username and password to sign in!</p>
        {/* <div className="mb-6 flex h-[50px] w-full items-center justify-center gap-2 rounded-xl bg-lightPrimary hover:cursor-pointer dark:bg-navy-800">
          <div className="rounded-full text-xl"><FcGoogle /></div>
          <h5 className="text-sm font-medium text-navy-700 dark:text-white">Sign In with Google</h5>
        </div>
        <div className="mb-6 flex items-center gap-3">
          <div className="h-px w-full bg-gray-200 dark:bg-navy-700" />
          <p className="text-base text-gray-600 dark:text-white"> or </p>
          <div className="h-px w-full bg-gray-200 dark:bg-navy-700" />
        </div> */}
        {/*!validation.valid && <p className='text-red-500 text-xs m-5'>{validation.error}</p>*/}
        <InputField
          variant="auth"
          extra="mb-3"
          label="Username*"
          placeholder="Enter your username"
          id="username"
          type="text"
          value={username}
          onChange={ (e) => {
            const newUsername = e.target.value
            setUsername(newUsername);
            // validate(e.target.value, password); 
          }}
        />
        <InputField
          variant="auth"
          extra="mb-3"
          label="Password*"
          placeholder="Min. 8 characters"
          id="password"
          type="password"
          value={password}
          onChange={ (e) => {
            const newPassword = e.target.value
            setPassword(newPassword);
            // validate(username, e.target.value);
          }}
        />
        {/* {error && <p className="text-red-500 text-3px">{error}</p>} */}
        <div className="mb-4 flex items-center justify-end px-2">
          {/*<div className="flex items-center">
            <Checkbox />
            <p className="ml-2 text-sm font-medium text-navy-700 dark:text-white">Keep me logged In</p>
          </div>*/}
          {/*<a
            className="text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
            href="forget-password"
          >
            Forgot Password?
          </a>*/}
           <Link
            to="/auth/forget-password"
            className="text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
          >
            Forgot Password?
          </Link>  
        </div>
        <button onClick={ () => {
          handleLogin(username, password);
          console.log("DONE")
        }} 
        className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
          Sign in
        </button>

        {/* <AlertDialogDemo 
          title= 'Sign in'
          desc= "click to sign in"
          content= {
            <div className="mb-4 flex items-center justify-between px-2">
          <div className="flex items-center">
            <Checkbox />
            <p className="ml-2 text-sm font-medium text-navy-700 dark:text-white">Keep me logged In</p>
          </div>
          <a
            className="text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
            href="forget-password"
          >
            Forgot Password?
          </a>
        </div>
          }
          action= { () => {
            console.log("DONE")
          } }
        /> */}
        {/*         

        <div className="mt-6 flex items-center gap-2">
          <div className="h-px w-full bg-gray-200 dark:bg-navy-700" />
          <p className="text-base text-gray-600 dark:text-white"> or </p>
          <div className="h-px w-full bg-gray-200 dark:bg-navy-700" />
        </div> */}
        <div className="mt-4 flex items-center justify-center">
          {/*<p className="text-sm font-medium text-navy-700 dark:text-white">Don’t have an account?</p>*/}
          {/* <Link to="/signup" className="ml-2 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white">
            Sign Up
          </Link> */}
        </div>
        {/* <button onClick={notify}>Make me a toast</button> */}
      <Toaster />
      </div>
    </div>
  );
}

