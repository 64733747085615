import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { createHeaders } from "./headers";

export const getSubscriptions = createAsyncThunk("subscription/get", async (query) => {
  console.log(query);
  let filteredQuery = Object.fromEntries(
    Object.entries(query).filter(
      ([key, value]) => value != null && value !== ""
    )
  );
  let params = new URLSearchParams(filteredQuery);
  let response = await fetch(
    `${process.env.REACT_APP_URL}api/subscription/?${params.toString()}`,
    {
      headers: createHeaders(),
    }
  );
  let json = await response.json();
  console.log(json);
  return json;
});

// export const fetchFeatures = createAsyncThunk(
//   "subscription/features-settings",
//   async (businessId) => {
//     // let params = new URLSearchParams(filteredQuery);
//     let response = await fetch(
//       `${process.env.REACT_APP_URL}api/subscription/features-settings`,
//       {
//         headers: createHeaders(),
//       }
//     );
//     let json = await response.json();
//     console.log(json);
//     return json;
//   }
// );

export const fetchFeatures = createAsyncThunk("subscription/features-settings/fetch", async (id) => {
  console.log("data id :"+id);
  const response = await fetch(`${process.env.REACT_APP_URL}api/subscription/features-settings/${id}`, {
    headers: createHeaders(),
    // body: JSON.stringify({...data}),
  });
  const json = await response.json();
  console.log("json"+json);
  return json;
});

export const updateFeatures = createAsyncThunk(
  "subscription/features-settings/update",
  async (data) => {
    // console.log(data, "ID and DATA for update");
    let response = await fetch(`${process.env.REACT_APP_URL}api/subscription/features-settings/`, {
      method: "PUT", // or 'PATCH' depending on your API
      headers: createHeaders(),
      body: JSON.stringify({ ...data }), // Include the id in the request body
    });
    let json = await response.json();
    return json;
  }
);

export const addSubscription = createAsyncThunk("subscription/add", async (data) => {
  const response = await fetch(`${process.env.REACT_APP_URL}api/subscription/`, {
    method: "POST",
    headers: createHeaders(),
    body: JSON.stringify(data),
  });
  const json = await response.json();
  return json;
});

export const updateSubscription = createAsyncThunk(
  "subscription/update",
  async (data) => {
    console.log(data, "ID and DATA for update");
    let response = await fetch(`${process.env.REACT_APP_URL}api/subscription/`, {
      method: "PUT", // or 'PATCH' depending on your API
      headers: createHeaders(),
      body: JSON.stringify({ ...data }), // Include the id in the request body
    });
    let json = await response.json();
    return json;
  }
);

export const deleteSubscription = createAsyncThunk("subscription/delete", async (id) => {
  console.log(id, "ID for delete");
  let response = await fetch(`${process.env.REACT_APP_URL}api/subscription/${id}`, {
    method: 'DELETE',
    headers: createHeaders(),
  });
  let json = await response.json();
  return json;
});

export const subscriptionsSlice = createSlice({
  name: "subscription",
  initialState: {
    value: {
      isError: false,
      isLoading: false,
      status: "",
      data: {
        count: 0,
        activeCount: 0,
        inactiveCount: 0,
        rows: [],
      },
      features:[]
      
    },
  },
  reducers: {
    
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSubscriptions.fulfilled, (state, action) => {
        console.log(action.payload, "DATA FETCHED");
        state.value.data.rows = action.payload.rows;
        // state.value.data.count = action.payload.count;
        state.value.data.count = action.payload.totalSubscriptions;
        state.value.data.activeCount = action.payload.activeSubscriptions;
        state.value.data.inactiveCount = action.payload.inactiveSubscriptions;

        // console.log('Updated state:', state);
      })
      .addCase(getSubscriptions.pending, (state) => {
        state.status = "Fetching todos. Please wait a moment...";
      })
      .addCase(getSubscriptions.rejected, (state) => {
        state.status = "Failed to  fetch data...";
      })

      .addCase(updateSubscription.fulfilled, (state, action) => {
        // state.value.userInput = Object.fromEntries(
        //   Object.keys(state.value.userInput).map((key) => [key, ""])
        // );
        
        
      })
      .addCase(updateSubscription.pending, (state) => {
        state.status = "Updating state. Please wait a moment...";
      })
      .addCase(updateSubscription.rejected, (state) => {
        state.status = "Failed to update state...";
      })
      // .addCase(deleteSubscription.fulfilled, (state, action) => {
      //   // filter out the state with the deleted id
      //   state.value.data = state.value.data.filter(item => item.id !== action.payload.id);
      // })
      // .addCase(deleteSubscription.pending, (state) => {
      //   state.status = "Deleting state. Please wait a moment...";
      // })
      // .addCase(deleteSubscription.rejected, (state) => {
      //   state.status = "Failed to delete state...";
      // })

      //   .addCase(addSubscription.fulfilled, (state, action) => {
      //     state.value.userInput = {}
      //     // state.value.data.push(action.payload); // add the new state to the list
      //  })
      .addCase(addSubscription.fulfilled, (state, action) => {
        // state.value.userInput = Object.fromEntries(
        //   Object.keys(state.value.userInput).map((key) => [key, ""])
        // );
        
        
      })
      .addCase(addSubscription.pending, (state) => {
        state.status = "Adding state. Please wait a moment...";
      })
      .addCase(addSubscription.rejected, (state) => {
        state.status = "Failed to add state...";
      })
      
      
      .addCase(fetchFeatures.fulfilled, (state, action) => {
        
        // console.log("action.payload"+JSON.stringify(action.payload.features));
        state.value.features = action.payload.features

        
        
      })
      .addCase(fetchFeatures.pending, (state) => {
        state.status = "fetchFeatures . Please wait a moment...";
      })
      .addCase(fetchFeatures.rejected, (state) => {
        state.status = "Failed to fetchFeatures...";
      })
      
  },
});

export const { updateUserInput, resetUserInput } = subscriptionsSlice.actions;
export default subscriptionsSlice.reducer;
