
import { React, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getZones,
  addZone,
  updateZone,
  deleteZone,
} from "../../../redux/actions/zone";
import { getStates } from "../../../redux/actions/state";
import toast, { Toaster } from "react-hot-toast";
import DropdownSelect from "components/select";
import Paginate from "components/paginate";

import {
  FiTrendingUp,
  FiX,
  FiLayers,
  FiSearch,
  FiUnlock,
  FiEdit2,
  FiTrash,
} from "react-icons/fi";

import Widget from "components/widget/Widget";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../components/shadcn/table";

import DialogCustom from "../../../components/dialog";
import Form from "../../../components/form";

export const Zone = (props) => {
  const [formData, setFormData] = useState({});
  // const [isLoading,setIsLoading ] = useState(true);
  // const [page, setPage] = useState(1);
  const [filter, setFilter] = useState({
    name: "",
    status: "All",
    limit: 10,
    page: 1,
  });
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const notifySuccess = (message) =>
    toast.success(message, {
      style: {
        padding: "35px",
        color: "#a0ca00",
      },
      duration: 5000,
      iconTheme: {
        primary: "#a0ca00",
        secondary: "#222c25",
      },
    });
  const notifyError = (message) =>
    toast.error(message, {
      style: {
        border: "1px solid #fff",
        padding: "35px",
        color: "red",
      },
      duration: 5000,
      iconTheme: {
        primary: "red",
        secondary: "#fff",
      },
    });

  const dispatch = useDispatch();

  // const stateCount = useSelector((states) => states.state.value.data.count);
  // console.log("stateCount ------>...." + stateCount); 
  // console.log("stateCount ------>...." + typeof stateCount);
  useEffect(() => {
    dispatch(getZones({ name: "", status: false }));
    dispatch(getStates({ status: "Active", limit: 100 }));
  }, [dispatch]);
  
  // useEffect(() => {
  //   // dispatch(getStates({status: "All" }));
  //   dispatch(getStates({ status: "Active", limit: 100 }));
  //   // setFormData({})
  // }, [])
  // if(isLoading){
  //   return(
  //     <div className="mt-3 h-full min-h-[84vh] w-full rounded-xl bg-white p-4 flex justify-center items-center">
  //       <h1>Loading...</h1>
  //     </div>
  //   )
  // }
  const zones = useSelector((zones) => zones.zone.value.data);
  const states = useSelector((states) => states.state.value.data.rows);
  //const states = useSelector((state) => state.state.value.data);
  console.log("states ...: " + JSON.stringify(states));
  console.log("zones ...: " + JSON.stringify(zones));
  // const count = useSelector((zones) => zones.zone.value.count);
  // const activeCount = useSelector((zones) => zones.zone.value.data.activeCount);
  // const inactiveCount = useSelector((zones) => zones.zone.value.data.inactiveCount);
  const apiStatus = useSelector((zones) => zones.zone.value.status);
  console.log(
    "apiStatus ---------------------------------------------------->> " +
      apiStatus
  );
  const [open, setOpen] = useState(false);
  const transparent = true;

  const handleActionButtonClick = (zoneId, action) => {
    if (action === "edit") setIsDialogOpen(true);
    if (action === "delete") setIsDeleteDialogOpen(true);
    if (zoneId == null) {
      console.log(zoneId, action, "zoneId, action");
      setIsDialogOpen(true);
      return setFormData({});
    }

    const selectedZone = zones.rows.find((zone) => zone.id === zoneId);
    setFormData(selectedZone);
    //setFormData({...selectedZone, stateId: selectedZone.state.id});
    console.log(
      "FormData :" +
        JSON.stringify({ ...selectedZone, stateId: selectedZone.state.id })
    );
  };

  useEffect(() => {
    dispatch(getZones(filter)); 
  }, [filter]);
  // setFilter({...filter, page:1})

  /*const handleCrudZone = async () => {
    try {
      setIsDialogOpen(false);
      if (formData.id) await dispatch(updateZone(formData));
      if (!formData.id) await dispatch(addZone(formData));
      notifySuccess(apiStatus);

      setFormData({});
      //dispatch(getZones());
    } catch (error) {
      console.error("Failed to add state:", error);
    }
  };*/
  const handleCrudZone = async () => {
    try {
      setIsDialogOpen(false);
      if (formData.id) {
        const action = await dispatch(updateZone(formData));
        dispatch(getZones(filter));
        if (action.payload.success) {
          notifySuccess(action.payload.message);
        } else {
          notifyError(action.payload.message);
        }
      } else {
        const action = await dispatch(addZone(formData));
        dispatch(getZones(filter));
        if (action.payload.success) {
          notifySuccess(action.payload.message);
        } else {
          notifyError(action.payload.message);
        }
      }
      setFormData({});
      //dispatch(getZones());
    } catch (error) {
      console.error("Failed to add zone:", error);
    }
  };

  /*const handleDelete = async () => {
    try {
      setIsDeleteDialogOpen(false);
      console.log(formData, "inside delete");
      await dispatch(deleteZone(formData.id));
      // setFormData({});
      notifySuccess(apiStatus);
      // console.log("cleared FormData ->", formData);
      dispatch(getZones());
    } catch (error) {
      console.error("Failed to add state:", error);
    }
  };*/

  const handleDelete = async () => {
    try {
      setIsDeleteDialogOpen(false);
      const action = await dispatch(deleteZone(formData.id));
      dispatch(getZones(filter));
      if (action.payload.success) {
        notifySuccess(action.payload.message);
      } else {
        notifyError(action.payload.message);
      }
      //dispatch(getZones());
    } catch (error) {
      console.error("Failed to delete zone:", error);
    }
  };

  console.log(zones, "ROLES");

  const fields = [
    {
      id: "rolename",
      name: "name",
      label: "Zone Name",
      placeholder: "Enter Zone's Name",
      type: "text",
    },
    {
      id: "Status",
      name: "status",
      label: "Status",
      desc: "Set status as active/inactive",
      type: "switch",
    },
    {
      id: "stateId",
      name: "stateId",
      label: "State",
      placeHolder: "Select State",
      type: "dropdown",
      //list: states.map((state) => ({ id: state.id, name: state.name })),
      list: states,
      toSelect: "id",
      //selectedvalue: formData.state ? formData.state.name : 'oops!',
      selectedvalue: "state",
    },
  ];

  return (
    <div className="mt-3 h-full min-h-[84vh] w-full rounded-xl bg-white p-4">
      <Toaster />
      <div className="h-full w-full rounded-xl">
        <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-3 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-3">
          <Widget
            icon={<FiLayers className="h-7 w-7" />}
            title={"Total Zones"}
            subtitle={zones.count || 0}
            styling={{
              iconContStyles: "bg-brand-500",
              iconStyles: "text-white",
              border: "border-solid border-2 border-yellow-100",
            }}
          />
          <Widget
            icon={<FiTrendingUp className="h-7 w-7" />}
            title={"Active Zones"}
            subtitle={zones.activeCount || 0}
            styling={{
              iconContStyles: "bg-green-500",
              iconStyles: "text-white",
              border: "border-solid border-2 border-green-100",
            }}
          />
          <Widget
            icon={<FiX className="h-7 w-7" />}
            title={"Inactive Zones"}
            subtitle={zones.inactiveCount || 0}
            styling={{
              iconContStyles: "bg-red-500",
              iconStyles: "text-white",
              border: "border-solid border-2 border-red-100",
            }}
          />
        </div>

        <div className="mt-3  rounded-xl border-2 border-solid border-gray-100 p-4">
          {/* {zones.rows.length > 0 && ( */}
          <Table className="border-grey-500 border-b">
            <TableCaption className="text-black mb-3  text-start text-2xl font-bold">
              <div className="flex items-center justify-between">
                <div className="flex w-1/2 items-center justify-between">
                  <div className="mr-5 flex h-full items-center rounded-full bg-lightPrimary p-4 text-navy-700 dark:bg-navy-900 dark:text-white ">
                    <p className="pl-3 pr-2 text-xl">
                      <FiSearch className="h-4 w-4 text-gray-400 dark:text-white" />
                    </p>
                    <input
                      // value={filter.name}
                      onChange={(e) => {
                        setFilter({  ...filter, page:1, ["name"]: e.target.value });
                      }}
                      type="text"
                      placeholder="Search..."
                      className="block h-full w-full  bg-lightPrimary text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:w-fit"
                    />
                  </div>
                  <DropdownSelect
                    list={[
                      { name: "All", id: 1 },
                      { name: "Active", id: 2 },
                      { name: "Inactive", id: 3 },
                    ]}
                    triggerStyles="bg-lightPrimary !rounded-full"
                    // listStyles="text-sm text-red-700"
                    placeHolder={"Status"}
                    toSelect={"name"}
                    onChange={(value) => {
                      setFilter({ ...filter, page:1, ["status"]: value });
                    }}
                  />
                </div>
                <div className="flex  justify-end">
                  <button
                    onClick={() => handleActionButtonClick(null, "add")}
                    className="linear rounded-full border-2 bg-brand-700 px-4 py-3 text-center text-base font-medium text-white transition duration-200 hover:border-2 hover:border-brand-500 hover:!bg-white hover:text-brand-500  active:!bg-white/70"
                  >
                    Add Zone
                  </button>
                </div>
              </div>
            </TableCaption>
            <TableHeader>
              <TableRow>
                <TableHead className="w-1/3">Zone</TableHead>
                <TableHead className="w-1/5">Total Districts</TableHead>
                <TableHead>Status</TableHead>
                <TableHead className="w-1/6 text-center ">Actions</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {zones.rows.length > 0 &&
                zones.rows.map((zone) => (
                  <TableRow key={zone.id}>
                    <TableCell className="font-medium">
                      <h4 className="font-black text-brand-500">
                        {" "}
                        {zone.name}{" "}
                      </h4>
                    </TableCell>
                    <TableCell className="font-medium">
                      {zone.districts ? zone.districts.length : 0}
                    </TableCell>
                    <TableCell>
                      {zone.status ? (
                        <div className="flex w-fit items-center justify-start rounded-2xl bg-green-500  ">
                          <FiTrendingUp className="h-6 w-6 p-1 text-white " />
                          {/* <span>Active</span> */}
                        </div>
                      ) : (
                        <div className="flex w-fit items-center justify-start rounded-xl bg-red-500">
                          <FiX className="h-6 w-6 p-1  text-white" />
                          {/* <span>Inactive</span> */}
                        </div>
                      )}
                    </TableCell>
                    <TableCell className="w-1/6  ">
                      <div className="flex w-full justify-around">
                        <div
                          onClick={() =>
                            handleActionButtonClick(zone.id, "edit")
                          }
                          className="mx-2 w-fit cursor-pointer rounded-3xl bg-yellow-500 p-1"
                        >
                          <FiEdit2 className="h-4 w-4 text-white" />
                        </div>

                        <div
                          onClick={() =>
                            handleActionButtonClick(zone.id, "delete")
                          }
                          className="mx-2 w-fit cursor-pointer rounded-3xl bg-red-500 p-1"
                        >
                          <FiTrash className="h-4 w-4 text-white" />
                        </div>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          {/* )} */}
          {zones.rows.length > 0 && (
            <Paginate
              page={filter.page}
              totalCount={zones.count}
              limit={filter.limit}
              changePage={(value) => {
                // console.log(value)
                setFilter({ ...filter, ["page"]: value });
              }}
            />
          )}

          {zones.rows.length == 0 && (
            <div className="flex flex-col items-center justify-center p-10">
              <h4 className="mb-5 text-2xl font-medium text-brand-500">
                {" "}
                No Zones Added! Click below to add one.{" "}
              </h4>
              <button
                onClick={() => handleActionButtonClick(null, "add")}
                className="linear rounded-xl border-2 bg-brand-700 px-4 py-2 text-center text-base font-medium text-white transition duration-200 hover:border-2 hover:border-brand-500 hover:!bg-white hover:text-brand-500  active:!bg-white/70"
              >
                Add Zone
              </button>
            </div>
          )}

          <DialogCustom
            open={isDialogOpen}
            onOpenChange={setIsDialogOpen}
            dialogTitle="Edit Zone"
            dialogWidth="w-1/2"
            dialogDesc="Update the neccessary fields and save."
            content={
              <>
                <Form
                  fields={fields}
                  formData={formData}
                  onChange={(newFormData) => setFormData(newFormData)}
                />
                <div className="flex justify-end">
                  <button
                    onClick={handleCrudZone}
                    className="linear rounded-full border-2 bg-brand-700 px-4 py-2 text-center text-base font-medium text-white transition duration-200 hover:border-2 hover:border-brand-500 hover:!bg-white hover:text-brand-500  active:!bg-white/70"
                  >
                    {formData.id ? "Update" : "Add" + " Zone"}
                  </button>
                </div>
              </>
            }
          />
          <DialogCustom
            open={isDeleteDialogOpen}
            onOpenChange={setIsDeleteDialogOpen}
            dialogTitle="Delete Zone"
            dialogWidth="w-1/2"
            dialogDesc="Are you sure you want to delete this zone ?"
            content={
              <div className="flex flex-row-reverse">
                <button
                  onClick={handleDelete}
                  className=" linear rounded-xl border-2 border-white bg-red-700 px-4 py-2 text-center text-base font-medium text-white transition duration-200 hover:border-2 hover:border-red-700 hover:!bg-white hover:text-red-500 hover:shadow-lg  "
                >
                  Delete
                </button>
                <button
                  onClick={() => {
                    setIsDeleteDialogOpen(false);
                  }}
                  className="linear mr-4 rounded-xl border-2 border-white bg-brand-700 px-4 py-2 text-center text-base font-medium text-white transition duration-200 hover:border-2 hover:border-brand-700 hover:!bg-white hover:text-brand-500 hover:shadow-lg  "
                >
                  Cancel
                </button>
              </div>
            }
          />
        </div>
      </div>
    </div>
  );
};

export default Zone;



