// import MiniCalendar from "components/calendar/MiniCalendar";
// import WeeklyRevenue from "views/admin/default/components/WeeklyRevenue";
// import TotalSpent from "views/admin/default/components/TotalSpent";
// import PieChartCard from "views/admin/default/components/PieChartCard";
// import { IoMdHome } from "react-icons/io";
// import { IoDocuments } from "react-icons/io5";
// import { MdBarChart, MdDashboard } from "react-icons/md";

// import { columnsDataCheck, columnsDataComplex } from "./variables/columnsData";

// import Widget from "components/widget/Widget";
// import CheckTable from "views/admin/default/components/CheckTable";
// import ComplexTable from "views/admin/default/components/ComplexTable";
// import DailyTraffic from "views/admin/default/components/DailyTraffic";
// import TaskCard from "views/admin/default/components/TaskCard";
// import tableDataCheck from "./variables/tableDataCheck.json";
// import tableDataComplex from "./variables/tableDataComplex.json";

// const Dashboard = () => {
//   return (
//     <div>
//       {/* Card widget */}

//       <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-6">
//         <Widget
//           icon={<MdBarChart className="h-7 w-7" />}
//           title={"Earnings"}
//           subtitle={"$340.5"}
//         />
//         <Widget
//           icon={<IoDocuments className="h-6 w-6" />}
//           title={"Spend this month"}
//           subtitle={"$642.39"}
//         />
//         <Widget
//           icon={<MdBarChart className="h-7 w-7" />}
//           title={"Sales"}
//           subtitle={"$574.34"}
//         />
//         <Widget
//           icon={<MdDashboard className="h-6 w-6" />}
//           title={"Your Balance"}
//           subtitle={"$1,000"}
//         />
//         <Widget
//           icon={<MdBarChart className="h-7 w-7" />}
//           title={"New Tasks"}
//           subtitle={"145"}
//         />
//         <Widget
//           icon={<IoMdHome className="h-6 w-6" />}
//           title={"Total Projects"}
//           subtitle={"$2433"}
//         />
//       </div>

//       {/* Charts */}

//       <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-2">
//         <TotalSpent />
//         <WeeklyRevenue />
//       </div>

//       {/* Tables & Charts */}

//       <div className="mt-5 grid grid-cols-1 gap-5 xl:grid-cols-2">
//         {/* Check Table */}
//         <div>
//           <CheckTable
//             columnsData={columnsDataCheck}
//             tableData={tableDataCheck}
//           />
//         </div>

//         {/* Traffic chart & Pie Chart */}

//         <div className="grid grid-cols-1 gap-5 rounded-[20px] md:grid-cols-2">
//           <DailyTraffic />
//           <PieChartCard />
//         </div>

//         {/* Complex Table , Task & Calendar */}

//         <ComplexTable
//           columnsData={columnsDataComplex}
//           tableData={tableDataComplex}
//         />

//         {/* Task chart & Calendar */}

//         <div className="grid grid-cols-1 gap-5 rounded-[20px] md:grid-cols-2">
//           <TaskCard />
//           <div className="grid grid-cols-1 rounded-[20px]">
//             <MiniCalendar />
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Dashboard;


import MiniCalendar from "components/calendar/MiniCalendar";
import WeeklyRevenue from "views/admin/default/components/WeeklyRevenue";
import TotalSpent from "views/admin/default/components/TotalSpent";
import PieChartCard from "views/admin/default/components/PieChartCard";
import { IoMdHome } from "react-icons/io";
import { IoDocuments } from "react-icons/io5";
import { MdBarChart, MdDashboard,MdOutlineAddBusiness, MdOutlinePeopleAlt, MdOutlineSchool, MdViewList, MdOutlinePermContactCalendar } from "react-icons/md";
// import { PiStudent } from "react-icons/pi";
import { columnsDataCheck, columnsDataComplex } from "./variables/columnsData";

import Widget from "components/widget/Widget";
import CheckTable from "views/admin/default/components/CheckTable";
import ComplexTable from "views/admin/default/components/ComplexTable";
import DailyTraffic from "views/admin/default/components/DailyTraffic";
import TaskCard from "views/admin/default/components/TaskCard";
import tableDataCheck from "./variables/tableDataCheck.json";
import tableDataComplex from "./variables/tableDataComplex.json";
import {getBusinesses, StudentsParentsCount} from "../../../redux/actions/business"
import {getModules} from "../../../redux/actions/modules"
import {getPlans} from "../../../redux/actions/plan"
import {getSubscriptions} from "../../../redux/actions/subscription"
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// import { getDashboardData } from "../../../redux/actions/admin/dashboard";
import { getDashboardData } from "../../../redux/actions/dashboard";


const Dashboard = () => {
  const dispatch = useDispatch()
  const dashboardData = useSelector((data) => data.dashboard.value.data);

  useEffect(() => {
    dispatch( getDashboardData() )
  }, [dispatch])

  let action
  useEffect(()=>{
    const fetchAPI = async() =>{
      await dispatch(getBusinesses({status: "All", limit:500}))
      await dispatch(getModules({status: "All"}))
      await dispatch(getPlans({status: "All"}))
      await dispatch(getSubscriptions({status: "All"}))
      action = await dispatch(StudentsParentsCount())

    }
    fetchAPI()

  },[])
  const business = useSelector((state)=>state.business.value.data)
  const modules = useSelector((state)=>state.module.value.data)
  const plans = useSelector((state)=>state.plan.value.data)
  const subscriptions = useSelector((state)=>state.subscription.value.data)
  console.log("businesses ---"+JSON.stringify(business.rows));
  console.log("action ---"+JSON.stringify(action));
  // let totalStudents = 0
  
  // useEffect(()=>{
  //   processBusinessRows();
  // },[business])
  
// async function processBusinessRows() {
//   console.log("business.count: "+business.count);
//   for (let i = 0; i < business.count; i++) {
//       const businessRow = business.rows[i];
//       console.log("coding: " + businessRow.code);
//       const studentCount = await dispatch(getPlans({status: "All"}));
//       // await dispatch(getPlans({status: "All"}));
//   }
// }
// {console.log("dd data:"+JSON.stringify(dashboardData))}
  return (
    <div>
      {/* Card widget */}

      <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-6">
        <Widget
          icon={<MdOutlineAddBusiness className="h-7 w-7" />}
          title={"Total Businesses"}
          subtitle={business.count}
        />
        <Widget
          // icon={<IoDocuments className="h-6 w-6" />}
          icon={<MdOutlinePeopleAlt className="h-6 w-6" />}
          title={"Total Parents"}
          subtitle={business.totalParents}
        />
        <Widget
          // icon={<MdBarChart className="h-7 w-7" />}
          icon={<MdOutlineSchool className="h-7 w-7" />}
          title={"  Total Students"}
          subtitle={business.totalStudents}
        />
        <Widget
          icon={<MdDashboard className="h-6 w-6" />}
          title={"Total Modules"}
          subtitle={modules.count}
        />
        <Widget
          // icon={<MdBarChart className="h-7 w-7" />}
          icon={<MdViewList className="h-7 w-7" />}
          title={"Total Plans"}
          subtitle={plans.count}
        />
        <Widget
          // icon={<IoMdHome className="h-6 w-6" />}
          icon={<MdOutlinePermContactCalendar className="h-6 w-6" />}
          title={"Total Subscriptions"}
          subtitle={subscriptions.count}
        />
      </div>

      {/* Charts */}

      {/* <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-2"> */}
      { dashboardData.moduleFeatures && 
      <div className="mt-5">
        {/* <TotalSpent /> */}
        <WeeklyRevenue data={dashboardData.moduleFeatures}/>
      </div>}

      {/* Tables & Charts */}

      {/* <div className="mt-5 grid grid-cols-1 gap-5 xl:grid-cols-2"> */}
        {/* Check Table */}
        {/* <div>
          <CheckTable
            columnsData={columnsDataCheck}
            tableData={tableDataCheck}
          />
        </div> */}

        {/* Traffic chart & Pie Chart */}

        {/* <div className="grid grid-cols-1 gap-5 rounded-[20px] md:grid-cols-2">
          <DailyTraffic />
          <PieChartCard />
        </div> */}

        {/* Complex Table , Task & Calendar */}

        {/* <ComplexTable
          columnsData={columnsDataComplex}
          tableData={tableDataComplex}
        /> */}

        {/* Task chart & Calendar */}

        {/* <div className="grid grid-cols-1 gap-5 rounded-[20px] md:grid-cols-2">
          <TaskCard />
          <div className="grid grid-cols-1 rounded-[20px]">
            <MiniCalendar />
          </div>
        </div> */}
      {/* </div> */}
    </div>
  );
};

export default Dashboard;

