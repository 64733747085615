
// import React, { useEffect } from "react";
// import InputField from "../../../../components/fields/InputField";
// import SwitchField from "../../../../components/fields/SwitchField";
// import TextField from "../../../../components/fields/TextField";
// import { useDispatch, useSelector } from "react-redux";
// import { updateUserInput } from "../../../../redux/actions/business";
// import { useState } from "react";

// export const BusinessOptionalFields = () => {
//   const [inputValue, setInputValue] = useState({});
//   const dispatch = useDispatch();

//   const userInput = useSelector(
//     (businesses) => businesses.business.value.userInput
//   );
//   console.log(
//     "userInput rdx ----------------------" + JSON.stringify(userInput)
//   );

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setInputValue((prev) => ({ ...prev, [name]: value }));
//   };

//   useEffect(() => {
//     dispatch(updateUserInput(inputValue));
//   }, [inputValue]);

//   const handleAddBusiness = (e) => {
//     console.log("inside click");
// };
  

//   return (
//     <div className="h-full min-h-[84vh] w-full rounded-xl bg-white p-0">
//       <div className="mt-2 flex h-fit w-full justify-between space-x-5 rounded-xl p-4 ">
//         <TextField
//           name="registeredAddress"
//           label="Registered Address"
//           id="registeredAddress"
//           placeholder="Enter registered address"
//           extra="w-[100%]"
//           rows={3}
//           onChange={handleInputChange}
//           value={userInput.registeredAddress}
//         />

//         <InputField
//           name="country"
//           label="Country"
//           id="country"
//           type="text"
//           placeholder="Enter country"
//           required={false}
//           extra="w-[100%]"
//           onChange={handleInputChange}
//           value={userInput.country}
//         />
//       </div>


//       <div className="mt-2 flex h-fit w-full justify-between space-x-5 rounded-xl p-4 ">
//         <InputField
//           name="category"
//           label="Category"
//           id="category"
//           type="text"
//           placeholder="Enter category"
//           required={false}
//           extra="w-[100%]"
//           onChange={handleInputChange}
//           value={userInput.category}
//         />

//         <InputField
//           name="signupSource"
//           label="Signup Source"
//           id="signupSource"
//           type="text"
//           placeholder="Enter signup source"
//           required={false}
//           extra="w-[100%]"
//           onChange={handleInputChange}
//           value={userInput.signupSource}
//         />
//       </div>

//       <div className="mt-2 flex h-fit w-full justify-between space-x-5 rounded-xl p-4 ">
//         <InputField
//           name="panNo"
//           label="Pan No"
//           id="panNo"
//           type="text"
//           placeholder="Enter PAN number"
//           required={false}
//           extra="w-[100%]"
//           onChange={handleInputChange}
//           value={userInput.panNo}
//         />

//         <InputField
//           name="gstNo"
//           label="GST No"
//           id="gstNo"
//           type="text"
//           placeholder="Enter GST number"
//           required={false}
//           extra="w-[100%]"
//           onChange={handleInputChange}
//           value={userInput.gstNo}
//         />
//       </div>

//       <div className="mt-2 flex h-fit w-full justify-between space-x-5 rounded-xl p-4 ">
//         <InputField
//           name="referCode"
//           label="Refer Code"
//           id="referCode"
//           type="text"
//           placeholder="Enter refer code"
//           required={false}
//           extra="w-[49%]"
//           onChange={handleInputChange}
//           value={userInput.referCode}
//         />

//         {/* <InputField
//           name="logo"
//           label="Logo"
//           id="logo"
//           type="text"
//           placeholder="Enter logo URL"
//           required={false}
//           extra="w-[100%]"
//           onChange={handleInputChange}
//           value={userInput.logo}
//         /> */}
//       </div>
//       <div className="mt-2 flex h-fit w-full justify-end space-x-5 rounded-xl p-4 ">
//             <button
//                 onClick={handleAddBusiness}
//                 className="linear rounded-xl border-2 bg-brand-700 px-4 py-2 text-center text-base font-medium text-white transition duration-200 hover:border-2 hover:border-brand-500 hover:!bg-white hover:text-brand-500  active:!bg-white/70"
//               >
//                 Add Business
//               </button>
//       </div>

//     </div>
//   );
// };




// import React, { useEffect } from "react";
// import InputField from "../../../../components/fields/InputField";
// import SwitchField from "../../../../components/fields/SwitchField";
// import TextField from "../../../../components/fields/TextField";
// import { useDispatch, useSelector } from "react-redux";
// import { updateUserInput, resetUserInput } from "../../../../redux/actions/business";
// import { getBusiness, addBusiness, updateBusiness } from "../../../../redux/actions/business";

// export const BusinessOptionalFields = () => {
//   const businessId = window.location.pathname.split("/")[3];
//   const dispatch = useDispatch();

//   // useEffect(() => {
//   //   dispatch(resetUserInput());
//   // }, [dispatch, businessId]);

//   const userInput = useSelector(state => state.business.value.userInput);

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     dispatch(updateUserInput({ [name]: value }));
//   };

//   const handleButtonClick = () => {
//     if (businessId === "add-business") {
//       dispatch(addBusiness(userInput));
//     } else {
//       dispatch(updateBusiness({ ...userInput, id: businessId }));
//     }
//   };

//   return (
//     <div className="h-full min-h-[84vh] w-full rounded-xl bg-white p-0">
//       <div className="mt-2 flex h-fit w-full justify-between space-x-5 rounded-xl p-4 ">
//         <TextField
//           name="registeredAddress"
//           label="Registered Address"
//           id="registeredAddress"
//           placeholder="Enter registered address"
//           extra="w-[100%]"
//           rows={3}
//           onChange={handleInputChange}
//           value={userInput.registeredAddress}
//         />

//         <InputField
//           name="country"
//           label="Country"
//           id="country"
//           type="text"
//           placeholder="Enter country"
//           required={false}
//           extra="w-[100%]"
//           onChange={handleInputChange}
//           value={userInput.country}
//         />
//       </div>

//       <div className="mt-2 flex h-fit w-full justify-between space-x-5 rounded-xl p-4 ">
//         <InputField
//           name="category"
//           label="Category"
//           id="category"
//           type="text"
//           placeholder="Enter category"
//           required={false}
//           extra="w-[100%]"
//           onChange={handleInputChange}
//           value={userInput.category}
//         />

//         <InputField
//           name="signupSource"
//           label="Signup Source"
//           id="signupSource"
//           type="text"
//           placeholder="Enter signup source"
//           required={false}
//           extra="w-[100%]"
//           onChange={handleInputChange}
//           value={userInput.signupSource}
//         />
//       </div>

//       <div className="mt-2 flex h-fit w-full justify-between space-x-5 rounded-xl p-4 ">
//         <InputField
//           name="panNo"
//           label="Pan No"
//           id="panNo"
//           type="text"
//           placeholder="Enter PAN number"
//           required={false}
//           extra="w-[100%]"
//           onChange={handleInputChange}
//           value={userInput.panNo}
//         />

//         <InputField
//           name="gstNo"
//           label="GST No"
//           id="gstNo"
//           type="text"
//           placeholder="Enter GST number"
//           required={false}
//           extra="w-[100%]"
//           onChange={handleInputChange}
//           value={userInput.gstNo}
//         />
//       </div>

//       <div className="mt-2 flex h-fit w-full justify-between space-x-5 rounded-xl p-4 ">
//         <InputField
//           name="referCode"
//           label="Refer Code"
//           id="referCode"
//           type="text"
//           placeholder="Enter refer code"
//           required={false}
//           extra="w-[49%]"
//           onChange={handleInputChange}
//           value={userInput.referCode}
//         />
//       </div>

//       <div className="mt-2 flex h-fit w-full justify-end space-x-5 rounded-xl p-4 ">
//         <button
//           onClick={handleButtonClick}
//           className="linear rounded-xl border-2 bg-brand-700 px-4 py-2 text-center text-base font-medium text-white transition duration-200 hover:border-2 hover:border-brand-500 hover:!bg-white hover:text-brand-500  active:!bg-white/70"
//         >
//           {businessId === "add-business" ? "Add Business" : "Edit Business"}
//         </button>
//       </div>
//     </div>
//   );
// };




import React, { useEffect } from "react";
import InputField from "../../../../components/fields/InputField";
import SwitchField from "../../../../components/fields/SwitchField";
import TextField from "../../../../components/fields/TextField";
import { useDispatch, useSelector } from "react-redux";
import { updateUserInput, resetUserInput } from "../../../../redux/actions/business";
import { getBusiness, addBusiness, updateBusiness } from "../../../../redux/actions/business";

export const BusinessOptionalFields = (props) => {
  const {
    userInput,
    handleInputChange,
    handleSwitchChange,
    handleButtonClick,
    isAddBusiness
      } = props
  // const businessId = window.location.pathname.split("/")[3];
  // const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(resetUserInput());
  // }, [dispatch, businessId]);

  // const userInput = useSelector(state => state.business.value.userInput);

  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   dispatch(updateUserInput({ [name]: value }));
  // };

  // const handleButtonClick = () => {
  //   if (businessId === "add-business") {
  //     dispatch(addBusiness(userInput));
  //   } else {
  //     dispatch(updateBusiness({ ...userInput, id: businessId }));
  //   }
  // };
      const businessId = isAddBusiness
      console.log("isAddBusiness ---------"+isAddBusiness)
  return (
    <div className="h-full min-h-[84vh] w-full rounded-xl bg-white p-0">
      <div className="mt-2 flex h-fit w-full justify-between space-x-5 rounded-xl p-4 ">
        <TextField
          name="registeredAddress"
          label="Registered Address"
          id="registeredAddress"
          placeholder="Enter registered address"
          extra="w-[100%]"
          rows={3}
          onChange={handleInputChange}
          value={userInput.registeredAddress}
        />

        <InputField
          name="country"
          label="Country"
          id="country"
          type="text"
          placeholder="Enter country"
          required={false}
          extra="w-[100%]"
          onChange={handleInputChange}
          value={userInput.country}
        />
      </div>

      <div className="mt-2 flex h-fit w-full justify-between space-x-5 rounded-xl p-4 ">
        <InputField
          name="category"
          label="Category"
          id="category"
          type="text"
          placeholder="Enter category"
          required={false}
          extra="w-[100%]"
          onChange={handleInputChange}
          value={userInput.category}
        />

        <InputField
          name="signupSource"
          label="Signup Source"
          id="signupSource"
          type="text"
          placeholder="Enter signup source"
          required={false}
          extra="w-[100%]"
          onChange={handleInputChange}
          value={userInput.signupSource}
        />
      </div>

      <div className="mt-2 flex h-fit w-full justify-between space-x-5 rounded-xl p-4 ">
        <InputField
          name="panNo"
          label="Pan No"
          id="panNo"
          type="text"
          placeholder="Enter PAN number"
          required={false}
          extra="w-[100%]"
          onChange={handleInputChange}
          value={userInput.panNo}
        />

        <InputField
          name="gstNo"
          label="GST No"
          id="gstNo"
          type="text"
          placeholder="Enter GST number"
          required={false}
          extra="w-[100%]"
          onChange={handleInputChange}
          value={userInput.gstNo}
        />
      </div>

      <div className="mt-2 flex h-fit w-full justify-between space-x-5 rounded-xl p-4 ">
        <InputField
          name="referCode"
          label="Refer Code"
          id="referCode"
          type="text"
          placeholder="Enter refer code"
          required={false}
          extra="w-[49%]"
          onChange={handleInputChange}
          value={userInput.referCode}
        />
      </div>

      <div className="mt-2 flex h-fit w-full justify-end space-x-5 rounded-xl p-4 ">
        <button
          onClick={handleButtonClick}
          className="linear rounded-xl border-2 bg-brand-700 px-4 py-2 text-center text-base font-medium text-white transition duration-200 hover:border-2 hover:border-brand-500 hover:!bg-white hover:text-brand-500  active:!bg-white/70"
        >
          {isAddBusiness ? "Add Business" : "Edit Business"}
        </button>
      </div>
    </div>
  );
};
