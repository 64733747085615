import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { createHeaders } from './headers';


export const getZones = createAsyncThunk("zone/get", async (query) => {
    console.log(query)

    let filteredQuery = Object.fromEntries(
      Object.entries(query).filter(([key, value]) => value != null && value !== '')
  );
  let params = new URLSearchParams(filteredQuery);

  let response = await fetch(`${process.env.REACT_APP_URL}api/zone/?${params.toString()}`, {
    headers: createHeaders()
  });
  let json = await response.json();
  return json;
});

export const addZone = createAsyncThunk("zone/add", async (data) => {
  const response = await fetch(`${process.env.REACT_APP_URL}api/zone/`, {
    method: 'POST',
    headers: createHeaders(),
    body: JSON.stringify(data),
  });
  const json = await response.json();
  return json;
});

    

export const updateZone = createAsyncThunk("zone/update", async (data) => {
  console.log(data, "ID and DATA for update");
  let response = await fetch(`${process.env.REACT_APP_URL}api/zone/`, {
    method: 'PUT', // or 'PATCH' depending on your API
    headers: createHeaders(),
    body: JSON.stringify({ ...data }) // Include the id in the request body
  });
  let json = await response.json();
  return json;
});

export const deleteZone = createAsyncThunk("zone/delete", async (id) => {
  console.log(id, "ID for delete");
  let response = await fetch(`${process.env.REACT_APP_URL}api/zone/${id}`, {
    method: 'DELETE',
    headers: createHeaders(),
  });
  let json = await response.json();
  return json;
});

export const zonesSlice = createSlice({
  name: 'zone',
  initialState: {
    value: {
      isError: false,
      isLoading: true,
      status: 'default',
      data: {
        count: 0,
        activeCount: 0,
        inactiveCount: 0,
        rows: []
      }
    }
  },
  reducers: {

  },
  extraReducers: (builder) => {
    builder
      .addCase(getZones.fulfilled, (state, action) => {
         console.log( "zone payload ------------------------------> "+JSON.stringify(action.payload))
         console.log( "zone payload len ------------------------------> "+JSON.stringify(action.payload.length))
        state.value.data.rows = action.payload.rows
        //state.value.data.count = action.payload.count
        state.value.data.count = action.payload.totalZones
        state.value.data.activeCount = action.payload.activeZones
        state.value.data.inactiveCount = action.payload.inactiveZones
        state.value.status = action.payload.message
        state.value.isLoading = false
        
        // console.log('Updated state:', state);
      })
      .addCase(getZones.pending, (state) => {
        state.value.status = "Fetching zones. Please wait a moment..."
      })
      .addCase(getZones.rejected, (state) => {
        state.value.status = "Failed to  fetch data... Server Error"
      })
      .addCase(updateZone.fulfilled, (state, action) => {
        console.log(action.payload.message, "action.payload.message")
        state.value.status = action.payload.message
      })
      .addCase(updateZone.pending, (state) => {
        state.value.status = "Updating zone. Please wait a moment...";
      })
      .addCase(updateZone.rejected, (state) => {
        state.value.status = "Failed to update state... Server Error";
      })
      .addCase(deleteZone.fulfilled, (state, action) => {
        // filter out the state with the deleted id
        state.value.status = action.payload.message
        // console.log(action.payload.message, "action.payload.message")
      })
      .addCase(deleteZone.pending, (state) => {
        state.value.status = "Deleting zone. Please wait a moment...";
      })
      .addCase(deleteZone.rejected, (state) => {
        state.value.status = "Failed to delete state...  Server Error";
      })
      .addCase(addZone.fulfilled, (state, action) => {
        // state.value.data.push(action.payload); // add the new state to the list
        state.value.status = action.payload.message
     })
     .addCase(addZone.pending, (state) => {
        state.value.status = "Adding zone. Please wait a moment...";
     })
     .addCase(addZone.rejected, (state) => {
        state.value.status = "Failed to add zone...  Server Error";
     });
  }
})

export default zonesSlice.reducer
