
import { useSelector, useDispatch } from 'react-redux'
import { fetchPermissions, updatePermission } from '../../../redux/actions/permission';
import { useParams } from 'react-router-dom';
import { React, useState, useEffect } from 'react'
import toast, { Toaster } from "react-hot-toast";
import {
  FiTrendingUp,
  FiX,
  FiLayers,
  FiLock,
  FiUnlock,
  FiEdit2,
  FiTrash,
} from "react-icons/fi";
import Widget from "components/widget/Widget";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../components/shadcn/table";
import DialogCustom from "../../../components/dialog";
import Form from "../../../components/form";
// function gg ( nn){
//   return `hello,${nn}!`;
// }
export const Permission = (props) => {
  // const msg =gg('nbbn');
  const [roleId, setRoleId] = useState(window.location.pathname.split("/")[3]);
  console.log(window.location.pathname.split("/")[3], "ROLE ID")

  // const { roleId } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchPermissions(roleId))
    // setFormData({})
}, [dispatch])
 
  const [formData, setFormData] = useState({});
  const permissions = useSelector((state) => state.permissions.value);
  const [refreshKey, setRefreshKey] = useState(false);
console.log(roleId);
 
  // useEffect(() => {
  //   dispatch(fetchPermissions(roleId));
  // }, [dispatch, roleId,refreshKey]);

  // dispatch(fetchPermissions(roleId));



  // const handleUpdate = (permissionId, field, value) => {
  //   dispatch(updatePermission({ id: permissionId, [field]: value }));
  //   };
  const handleUpdate = (permissionId, field, value) => {
    setFormData({ ...formData, [permissionId]: { ...formData[permissionId], [field]: value } });
  };

  // Fetch permissions when component mounts
  // useEffect(() => {
  //   dispatch(fetchPermissions(roleId));
  // }, [dispatch, roleId]);

  // Update permission when form data changes
  // useEffect(() => {
  //   if (Object.keys(formData).length !== 0) {
  //     dispatch(updatePermission(formData));
  //   }
  // }, [formData, dispatch]);

  // const handleSave = () => {
  //   Object.entries(formData).forEach(([id, changes]) => {
  //     dispatch(updatePermission({ id, ...changes }));
  //   });
  // }; 
  const notifySuccess = (message) =>
    toast.success(message, {
      style: {
        padding: "35px",
        color: "#a0ca00",
      },
      duration: 5000,
      iconTheme: {
        primary: "#a0ca00",
        secondary: "#222c25",
      },
    });
  const notifyError = (message) =>
    toast.error(message, {
      style: {
        border: "1px solid #fff",
        padding: "35px",
        color: "red",
      },
      duration: 5000,
      iconTheme: {
        primary: "red",
        secondary: "#fff",
      },
    });
    
  const handleSave = async() => {
    const action = await dispatch(updatePermission(Object.values(formData)));
      if(action.payload.success){
      	notifySuccess(action.payload.message);
      }
      else{
      	notifyError(action.payload.message);
      }
      //setRefreshKey(prevKey => !prevKey); // toggle refreshKey

  };


useEffect(() => {
  if (permissions && Array.isArray(permissions)) {
    const initialFormData = {};
    for (let i = 0; i < permissions.length; i++) {
      initialFormData[permissions[i].id] = permissions[i];
    }
    setFormData(initialFormData);
  }
}, [permissions]);


  // rest of the component code...
  // replace centers with permissions, center with permission, and adjust the table columns and rows to fit the permission data structure.
  return (



    <div className="mt-3 h-full min-h-[84vh] w-full rounded-xl bg-white p-4">
     <Toaster />
      <div className="h-full w-full rounded-xl">
        <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-3 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-3">
    
        </div>
        <div className='mt-3 p-4 rounded-xl border-solid border-2 border-gray-100'>
          <Table>
            <TableCaption className="mb-3 font-bold text-2xl text-start text-black">
              <div className='flex justify-end mr-10'>
                <button type="button" onClick={handleSave} className="text-black linear rounded-xl bg-brand-700 px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-brand-850/80 hover:text-white active:!bg-white/70">
                  Save
                </button>
              </div>
            </TableCaption>
            <TableHeader>
              <TableRow>
                <TableHead className="w-1/3">Name</TableHead>
                <TableHead className="w-1/5">Fetch</TableHead>
                <TableHead className="w-1/5">Add</TableHead>
                <TableHead className="w-1/5">Update</TableHead>
                <TableHead className="w-1/5">Delete</TableHead>
                <TableHead className="w-1/5">restrictToCreated</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>

              {Object.values(formData).map((permission) => (
                <TableRow key={permission.id}>
                  <TableCell className="text-brand-500 font-black">{permission.record}</TableCell>
                  <TableCell className="font-medium">
                    <input type="checkbox"  className="form-checkbox text-green-500 bg-white checked:bg-green-500 checked:border-transparent"  checked={permission.read} onChange={(e) => handleUpdate(permission.id, 'read', e.target.checked)} />
                  </TableCell>
                  <TableCell className="font-medium">
                    <input type="checkbox" checked={permission.add} onChange={(e) => handleUpdate(permission.id, 'add', e.target.checked)} />
                  </TableCell>
                  <TableCell className="font-medium">
                    <input type="checkbox" checked={permission.update} onChange={(e) => handleUpdate(permission.id, 'update', e.target.checked)} />
                  </TableCell>
                  <TableCell className="font-medium">
                    <input type="checkbox" checked={permission.delete} onChange={(e) => handleUpdate(permission.id, 'delete', e.target.checked)} />
                  </TableCell>
                  <TableCell className="font-medium">
                    <input type="checkbox" checked={permission.restrictToCreated} onChange={(e) => handleUpdate(permission.id, 'restrictToCreated', e.target.checked)} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </div>
    </div>

    //  <div>
    //   <h1>{msg}</h1>
    // </div>




  )
};

export default Permission;
