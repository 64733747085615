import React, { useState } from "react";
import InputField from "components/fields/InputField";
import { useDispatch, useSelector } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import { sendResetPasswordLink } from "../../../redux/actions/forgetpassword";

export default function ResetPassword() {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const status = useSelector((state) => state.forgetPassword.status);
  const error = useSelector((state) => state.forgetPassword.error);
  const resetLinkSent = useSelector(
    (state) => state.forgetPassword.resetLinkSent
  );
  const [validation, setValidation] = useState({ valid: false, error: "" });

  const apiUrl = process.env.NODE_ENV.REACT_APP_URL;

  const validate = (email) => {
    if (!email) {
      setValidation((prevState) => ({
        ...prevState,
        valid: false,
        error: "Email is required.",
      }));
      return;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setValidation((prevState) => ({
        ...prevState,
        valid: false,
        error: "Please enter a valid email address.",
      }));
    } else {
      setValidation((prevState) => ({ ...prevState, valid: true, error: "" }));
    }
  };

  const handleResetPassword = async(email) => {
    if (!validation.valid) {
      return notifyError(validation.error);
    } else {
      const action = await dispatch(sendResetPasswordLink(email));
      if(action.payload.success){
        notifySuccess(action.payload.message);
      }
      else{
        notifyError(action.payload.message);
      }
    }
  };

    const notifySuccess = (message) =>
  toast.success(message, {
    duration: 20000, 
    style: {
      padding: "35px",
      color: "#a0ca00",
    },
    iconTheme: {
      primary: "#a0ca00",
      secondary: "#222c25",
    },
  });

  const notifyError = (message) =>
    toast.error(message, {
      duration: 5000,
      style: {
        border: "1px solid #fff",
        padding: "35px",
        color: "red",
      },
      iconTheme: {
        primary: "red",
        secondary: "#fff",
      },
    });

  console.log(email);
  return (
    <div className="mt-16 mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
          Reset Password
        </h4>
        <p className="mb-9 ml-1 text-base text-gray-600">
          Enter your email to reset your password.
        </p>
        
        <InputField
          variant="auth"
          extra="mb-3"
          label="Email*"
          placeholder="mail@gmail.com"
          id="email"
          type="email"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
            validate(e.target.value);
          }}
        />
        
        <button
          className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
          onClick={() => {
            handleResetPassword(email);
            console.log("btn clicked email submitted");
          }}
        >
          Reset Password
        </button>
      </div>
      <Toaster />{/*just to add pop up */}
    </div>
  );
}
