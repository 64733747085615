import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import Navbar from "components/navbar";
import Sidebar from "components/sidebar";
import Footer from "components/footer/Footer";
import routes from "routes.js";
import { validateToken } from "../../redux/actions/auth";
import Permission from "views/admin/permission";

import PlanDetails from "views/admin/plan-details";
import ModuleDetails from "views/admin/module-details";
// import { AddBusiness } from "views/admin/business/add-business/AddBusiness";
// import AddBusiness from "views/admin/business/add-business/index";
// import  EditBusiness  from "views/admin/business/edit-business";
import BusinessCRUD from "views/admin/business/business-CRUD";
import FeaturesSettings from "views/admin/features-settings";

export default function Admin(props) {
  //const msg =gg('nbbn');
  // const access_token = useSelector((auth) => auth.auth.value.access_token);
  // console.log(access_token, "access_token")
  // const [authenticated, setAuthenticated] = React.useState(false)
  const dispatch = useDispatch();
  dispatch(validateToken());
  const { ...rest } = props;
  const location = useLocation();
  const [open, setOpen] = React.useState(true);
  const [currentRoute, setCurrentRoute] = React.useState("Main Dashboard");
  React.useEffect(() => {
    window.addEventListener("resize", () =>
      window.innerWidth < 1200 ? setOpen(false) : setOpen(true)
    );
  }, []);

  React.useEffect(() => {
    console.log(location.pathname, "location.pathname");
    getActiveRoute(routes);
    console.log(currentRoute, "currentRoute");
  }, [location.pathname]);

  React.useEffect(() => {
    console.log(currentRoute, "currentRoute");
  }, [currentRoute]);

  const getActiveRoute = (routes) => {
    let activeRoute = "Main Dashboard";
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(
          routes[i].layout + "/" + routes[i].path
        ) !== -1
      ) {
        if (routes[i].children) {
          const subRoute = location.pathname.split("/")[3];
          routes[i].subRoute = routes[i].children.find(
            (item) => item.path === subRoute
          );
        }
        setCurrentRoute(routes[i]);
      }
    }
    return activeRoute;
  };
  const getActiveNavbar = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
      ) {
        return routes[i].secondary;
      }
    }
    return activeNavbar;
  };
  // const getRoutes = (routes) => {
  //   return routes.map((prop, key) => {
  //     if (prop.layout === "/admin") {
  //       // console.log(prop)
  //       if (prop.isDropdown) {
  //         const subRoute = location.pathname.split("/")[3]
  //         // console.log(subRoute, "SUB")
  //         console.log(prop.path, "THIS IMPORTANT")
  //         const childComp = prop.children.find(item => item.path === subRoute);
  //         // console.log(childComp ? childComp.path : "NIL", "THIS ALSO IMPORTANT")

  //         return (
  //           childComp ?
  //             <Route path={`/${prop.path}/${childComp.path}`} element={childComp.component} key={key} />
  //             :
  //             <Route path={`/${prop.path}`} element={prop.component} key={key} />
  //         )
  //       }

  //       return (
  //         <Route path={`/${prop.path}`} element={prop.component} key={key} />
  //       );
  //     } else {
  //       return null;
  //     }
  //   });
  // };

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        if (prop.isDropdown) {
          const subRoute = location.pathname.split("/")[3];
          const childComp = prop.children.find(
            (item) => item.path === subRoute
          );
          return childComp ? (
            <Route
              path={`/${prop.path}/${childComp.path}`}
              element={childComp.component}
              key={key}
            />
          ) : (
            <Route path={`/${prop.path}`} element={prop.component} key={key} />
          );
        }

        // Handle dynamic routes
        if (prop.path.includes(":")) {
          const dynamicPath = prop.path.split(":");
          return (
            <Route
              path={`/${prop.path + dynamicPath[0]}`}
              element={prop.component}
              key={key}
            />
          );
        }

        return (
          <Route path={`/${prop.path}`} element={prop.component} key={key} />
        );
      } else {
        return null;
      }
    });
  };

  document.documentElement.dir = "ltr";
  // console.log(currentRoute, "currentRoute")
  return (
    <div className="flex h-full w-full">
      <Sidebar open={open} onClose={() => setOpen(false)} />
      {/* Navbar & Main Content */}
      <div className="h-full w-full bg-lightPrimary dark:!bg-navy-900">
        {/* Main Content */}
        <main
          className={`mx-[12px] h-full flex-none transition-all md:pr-2 xl:ml-[313px]`}
        >
          {/* Routes */}
          <div className="h-full">
            <Navbar
              onOpenSidenav={() => setOpen(!open)}
              logoText={"Maktab"}
              brandText={
                currentRoute.name
                  ? currentRoute.name
                  : "" +
                    (currentRoute.subRoute
                      ? ` / ${currentRoute.subRoute.name}`
                      : `${window.location.pathname.split("/")[2]}`)
              }
              secondary={getActiveNavbar(routes)}
              {...rest}
            />
            <div className="pt-5s mx-auto mb-auto h-full min-h-[84vh] p-2 md:pr-2">
              <Routes>
                <Route path={`/plan/:id`} element={<PlanDetails />} />
                <Route path={`/permission/:id`} element={<Permission />} />
                <Route path={`/module/:id`} element={<ModuleDetails />} />
                <Route
                  path="/business/:id"
                  element={<BusinessCRUD />}
                />
                <Route
                  path="/subscription/:id"
                  element={<FeaturesSettings />}
                />
                

                {getRoutes(routes)}
                <Route
                  path="/"
                  element={<Navigate to="/admin/default" replace />}
                />
              </Routes>
            </div>
            <div className="p-3"><Footer /></div>
          </div>
        </main>
      </div>
    </div>
  );
}
