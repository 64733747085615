import { React, useState, useEffect } from 'react'
import Form from 'components/form';
import { getPlan, updatePlan } from '../../../redux/actions/plan';
import { useSelector, useDispatch } from 'react-redux'


const Details = () => {

    const [formData, setFormData] = useState({});
    const [planId, setPlanId] = useState(window.location.pathname.split("/")[3]);


    const handleUpdatePlan = async () => {
        console.log(formData, "NEW FORM DATA");
        await dispatch(updatePlan(formData))
        await dispatch(getPlan(planId))
    }


    const fields = [
        {
            id: "name",
            name: "name",
            label: "Plan Name",
            placeholder: "Enter Plan's Name",
            type: "text",
        },
        {
            id: "Status",
            name: "status",
            label: "Status",
            desc: "Set status as active/inactive",
            type: "switch",
        },
        {
            id: "base price",
            name: "basePrice",
            label: "base price",
            placeholder: "base price",
            type: "number",
        }
    ];
    


    const dispatch = useDispatch();
    const plan = useSelector((plans) => plans.plan.value.singlePlan);
    useEffect(() => {
        dispatch(getPlan(planId))
        setFormData({
            ...plan
        })
    }, [dispatch])
    // console.log(plan, "PLANS")
    return (
        <div className='mt-5 rounded-xl border-2 border-grey-100'>
            <div className="p-3  flex flex-row shadow">
                <div className='w-1/2 border-r-2 border-grey-100 p-5'>
                    <div className='flex flex-row items-center justify-between w-full p-3' >
                        <h1 className="text-4xl font-bold capitalize text-navy-700"> {plan.name} </h1>
                        <h1 className={`text-sm font-bold capitalize text-white p-2 rounded-full ${plan.status ? "bg-green-500" : "bg-red-500"}`}> {plan.status ? "Active" : "Inactive"} </h1>
                    </div>
                    <Form
                        fields={fields}
                        formData={formData}
                        onChange={(newFormData) => {
                            console.log(newFormData, "HERE NEW");
                            setFormData(newFormData)
                        }}
                    />
                    <div className='flex justify-end'>
                        <button
                            onClick={handleUpdatePlan}
                            className="border-2 text-white linear rounded-full bg-brand-700 px-4 py-3 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-500  active:!bg-white/70">
                            {formData.id ? "Update" : "Add" + " Plan"}
                        </button>
                    </div>
                </div>
                <div className="h-full w-1/2  p-5">
                    {plan.module ? (
                        <>
                            <h1 className="text-2xl font-bold capitalize text-navy-700"> {plan.module.name} </h1>
                            <h1 className="text-sm text-brand-700"> {plan.module.desc} </h1>
                        </>
                    ) : (
                        <p>Loading...</p>
                    )}
                </div>
            </div>
            
        </div>


    );
};

export default Details;