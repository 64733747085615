import { React, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getBusinesses,
  addBusiness,
  updateBusiness,
} from "../../../redux/actions/business";
// import { getRoles } from '../../../redux/actions/role';
import toast, { Toaster } from "react-hot-toast";
import DropdownSelect from "components/select";
import Paginate from "components/paginate";
import { Link, useNavigate } from "react-router-dom";

import {
  FiTrendingUp,
  FiX,
  FiLayers,
  FiSearch,
  FiUnlock,
  FiEdit2,
  FiTrash,
} from "react-icons/fi";

import Widget from "components/widget/Widget";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../components/shadcn/table";

import DialogCustom from "../../../components/dialog";
import Form from "../../../components/form";


export const User = (props) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  // const [page, setPage] = useState(1);
  const [filter, setFilter] = useState({
    name: "",
    status: "All",
    limit: 10,
    page: 1,
  });
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const notifySuccess = (message) =>
    toast.success(message, {
      style: {
        padding: "35px",
        color: "#a0ca00",
      },
      duration: 3000,
      iconTheme: {
        primary: "#a0ca00",
        secondary: "#222c25",
      },
    });
  const notifyError = (message) =>
    toast.error(message, {
      style: {
        border: "1px solid #fff",
        padding: "35px",
        color: "red",
      },
      iconTheme: {
        primary: "red",
        secondary: "#fff",
      },
    });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBusinesses({}));
    // setFormData({})
  }, [dispatch]);

  //   useEffect(() => {
  //     dispatch(getRoles({  }));
  //     // setFormData({})
  //   }, [])

  const businesses = useSelector(
    (businesses) => businesses.business.value.data
  );
  //   const roles = useSelector((roles) => roles.role.value.data);

  // const count = useSelector((businesses) => businesses.business.value.count);
  // const activeCount = useSelector((businesses) => businesses.business.value.data.activeCount);
  // const inactiveCount = useSelector((businesses) => businesses.business.value.data.inactiveCount);
  const apiStatus = useSelector(
    (businesses) => businesses.business.value.status
  );
  const [open, setOpen] = useState(false);
  const transparent = true;

  const handleActionButtonClick = (userId, action) => {
    if (action === "edit") setIsDialogOpen(true);
    if (action === "delete") setIsDeleteDialogOpen(true);
    if (userId == null) {
      console.log(userId, action, "userId, action");
      setIsDialogOpen(true);
      return setFormData({});
    }

    const selectedBusiness = businesses.rows.find(
      (business) => business.id === userId
    );
    setFormData(selectedBusiness);
  };

  useEffect(() => {
    dispatch(getBusinesses(filter));
  }, [filter]);

  const handleCrudUser = async () => {
    try {
      setIsDialogOpen(false);
      if (formData.id) await dispatch(updateBusiness(formData));
      if (!formData.id) await dispatch(addBusiness(formData));
      notifySuccess(apiStatus);

      setFormData({});
      dispatch(getBusinesses());
    } catch (error) {
      console.error("Failed to add state:", error);
    }
  };

  // const handleDelete = async () => {
  //   try {
  //     setIsDeleteDialogOpen(false)
  //     console.log(formData, "inside delete")
  //     await dispatch(deleteUser(formData.id));
  //     // setFormData({});
  //     notifySuccess(apiStatus)
  //     // console.log("cleared FormData ->", formData);
  //     dispatch(getBusinesses());

  //   } catch (error) {
  //     console.error('Failed to add state:', error);
  //   }
  // };

  console.log(businesses, "ROLES");
  // const businesses = [];
  const fields = [
    {
      id: "username",
      name: "username",
      label: "User Name",
      placeholder: "Enter User's Name",
      type: "text",
      // reflectChange: false
    },
    {
      id: "Status",
      name: "status",
      label: "Status",
      desc: "Set status as active/inactive",
      type: "switch",
    },
    {
      id: "fullname",
      name: "fullname",
      label: "Full Name",
      placeholder: "Enter User's Full Name",
      type: "text",
      // reflectChange: false
    },
    {
      id: "email",
      name: "email",
      label: "Email",
      placeholder: "Enter User's Email",
      type: "mail",
      // reflectChange: false
    },
    {
      id: "mobile",
      name: "mobile",
      label: "User Mobile",
      placeholder: "Enter User's Mobile",
      type: "number",
    },
    // {
    //   id: "roleId",
    //   name: "roleId",
    //   label: "User Role",
    //   desc: "Set a business role",
    //   type: "dropdown",
    //   toSelect: "id",
    //   selectedvalue: "role",
    //   placeHolder: "select role",
    //   list: roles.rows
    // },
  ];

  return (
    <div className="mt-3 h-full min-h-[84vh] w-full rounded-xl bg-white p-4">
      <Toaster />
      <div className="h-full w-full rounded-xl">
        <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-3 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-3">
          <Widget
            icon={<FiLayers className="h-7 w-7" />}
            title={"Total Businesses"}
            subtitle={businesses.count}
            styling={{
              iconContStyles: "bg-brand-500",
              iconStyles: "text-white",
              border: "border-solid border-2 border-yellow-100",
            }}
          />
          <Widget
            icon={<FiTrendingUp className="h-7 w-7" />}
            title={"Active Businesses"}
            subtitle={businesses.activeCount}
            styling={{
              iconContStyles: "bg-green-500",
              iconStyles: "text-white",
              border: "border-solid border-2 border-green-100",
            }}
          />
          <Widget
            icon={<FiX className="h-7 w-7" />}
            title={"Inactive Businesses"}
            subtitle={businesses.inactiveCount}
            styling={{
              iconContStyles: "bg-red-500",
              iconStyles: "text-white",
              border: "border-solid border-2 border-red-100",
            }}
          />
        </div>

        <div className="mt-3  rounded-xl border-2 border-solid border-gray-100 p-4">
          {/* {businesses.rows.length > 0 && ( */}
          <Table className="border-grey-500 border-b">
            <TableCaption className="text-black mb-3  text-start text-2xl font-bold">
              <div className="flex items-center justify-between">
                <div className="flex w-1/2 items-center justify-between">
                  <div className="mr-5 flex h-full items-center rounded-full bg-lightPrimary p-4 text-navy-700 dark:bg-navy-900 dark:text-white ">
                    <p className="pl-3 pr-2 text-xl">
                      <FiSearch className="h-4 w-4 text-gray-400 dark:text-white" />
                    </p>
                    <input
                      // value={filter.name}
                      onChange={(e) => {
                        setFilter({...filter, page:1, ["name"]: e.target.value });
                      }}
                      type="text"
                      placeholder="Search..."
                      className="block h-full w-full  bg-lightPrimary text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:w-fit"
                    />
                  </div>
                  <DropdownSelect
                    list={[
                      { name: "All", id: 1 },
                      { name: "Active", id: 2 },
                      { name: "Inactive", id: 3 },
                    ]}
                    triggerStyles="bg-lightPrimary !rounded-full"
                    // listStyles="text-sm text-red-700"
                    placeHolder={"Status"}
                    toSelect={"name"}
                    onChange={(value) => {
                      setFilter({ ...filter, page:1, ["status"]: value });
                    }}
                  />
                </div>
                <div className="flex  justify-end linear rounded-full border-2 bg-brand-700 px-4 py-3 text-center text-base font-medium text-white transition duration-200 hover:border-2 hover:border-brand-500 hover:!bg-white hover:text-brand-500  active:!bg-white/70" 
                  onClick={() => navigate('add-business')}>
                Add Business
                  {/*<button
                      onClick={() => handleActionButtonClick(null, "add")}
                      className="border-2 text-white linear rounded-full bg-brand-700 px-4 py-3 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-500  active:!bg-white/70">
                      Add Business
                    </button>*/}
                  {/* <Link
                    to="add-business"
                    className=""
                  >
                    Add Business
                  </Link> */}
                </div>
              </div>
            </TableCaption>
            <TableHeader>
              <TableRow>
                <TableHead className="w-1/3"> Business </TableHead>
                <TableHead className="w-1/5"> Details </TableHead>
                <TableHead>Status</TableHead>
                <TableHead className="w-1/6 text-center ">Actions</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {businesses.rows.length > 0 &&
                businesses.rows.map((business) => (
                  <TableRow key={business.id}>
                    <TableCell className="font-medium">
                      {business.name}
                    </TableCell>
                    <TableCell className="font-medium">
                      <h4 className="font-black text-brand-500">
                        {" "}
                        {business.code}{" "}
                      </h4>
                      <p> {business.email} </p>
                      <p> {business.mobile} </p>
                    </TableCell>
                    <TableCell>
                      {business.status ? (
                        <div className="flex w-fit items-center justify-start rounded-2xl bg-green-500  ">
                          <FiTrendingUp className="h-6 w-6 p-1 text-white " />
                          {/* <span>Active</span> */}
                        </div>
                      ) : (
                        <div className="flex w-fit items-center justify-start rounded-xl bg-red-500">
                          <FiX className="h-6 w-6 p-1  text-white" />
                          {/* <span>Inactive</span> */}
                        </div>
                      )}
                    </TableCell>
                    <TableCell className="w-1/6  ">
                      {/* <div className="flex w-full justify-around">
                        <Link
                          to="edit-business/"
                          className="mx-2 w-fit cursor-pointer rounded-3xl bg-yellow-500 p-1"
                        >
                          <FiEdit2 className="h-4 w-4 text-white" />
                        </Link>
                      </div> */}
                      {/* working */}
                      {/* <div className="flex  justify-around mx-2 w-fit cursor-pointer rounded-3xl bg-yellow-500 p-1" onClick={() => navigate(`edit-business/${business.id}`)} >
                        <FiEdit2 className="h-4 w-4 text-white" />
                      </div> */}
                      <div className="flex  justify-around mx-2 w-fit cursor-pointer rounded-3xl bg-yellow-500 p-1" onClick={() => navigate(`${business.id}`)} >
                        <FiEdit2 className="h-4 w-4 text-white" />
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          {/* )} */}

          {businesses.rows.length > 0 && (
            <Paginate
              page={filter.page}
              totalCount={businesses.count}
              limit={filter.limit}
              changePage={(value) => {
                // console.log(value)
                setFilter({ ...filter, ["page"]: value });
              }}
            />
          )}

          {businesses.rows.length == 0 && (
            <div className="flex flex-col items-center justify-center p-10">
              <h4 className="mb-5 text-2xl font-medium text-brand-500">
                {" "}
                No Businesses Added! Click below to add one.{" "}
              </h4>
              <Link
                to="add-business"
                className="linear rounded-xl border-2 bg-brand-700 px-4 py-2 text-center text-base font-medium text-white transition duration-200 hover:border-2 hover:border-brand-500 hover:!bg-white hover:text-brand-500  active:!bg-white/70"
              >
                Add Business
              </Link>
            </div>
          )}

          <DialogCustom
            open={isDialogOpen}
            onOpenChange={setIsDialogOpen}
            dialogTitle="Edit User"
            dialogWidth="w-1/2"
            dialogDesc="Update the neccessary fields and save."
            content={
              <>
                <Form
                  fields={fields}
                  formData={formData}
                  onChange={(newFormData) => setFormData(newFormData)}
                />
                <div className="flex justify-end">
                  <button
                    onClick={handleCrudUser}
                    className="text-black linear rounded-xl bg-brand-700 px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-brand-850/80 hover:text-white  active:!bg-white/70"
                  >
                    {formData.id ? "Update" : "Add" + " User"}
                  </button>
                </div>
              </>
            }
          />
          {/*<DialogCustom

            open={isDeleteDialogOpen}
            onOpenChange={setIsDeleteDialogOpen}
            dialogTitle="Delete User"
            dialogWidth="w-1/2"
            dialogDesc="Are you sure you want to delete this business ?"
            content={
              <div className='flex flex-row-reverse'>
                <button
                  onClick={handleDelete}
                  className=" text-white linear rounded-xl border-2 border-white bg-red-700 px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-red-500 hover:border-2 hover:border-red-700 hover:shadow-lg  ">
                  Delete
                </button>
                <button
                  onClick={() => {
                    setIsDeleteDialogOpen(false);
                  }}
                  className="mr-4 text-white linear rounded-xl border-2 border-white bg-brand-700 px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-700 hover:shadow-lg  ">
                  Cancel
                </button>
              </div>
            }
          />*/}
        </div>
      </div>
    </div>
  );
};

export default User;
