import { React, useState, useEffect } from 'react'
import Form from 'components/form';
import { getModule, updateModule } from '../../../redux/actions/modules';
import { useSelector, useDispatch } from 'react-redux'
import {
    Table,
    TableBody,
    TableCaption,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "../../../components/shadcn/table";
import {
    FiTrendingUp,
    FiX,
} from "react-icons/fi";


const Details = (props) => {
    const { module } = props
    console.log(props, "props")
    const [formData, setFormData] = useState(module);
    // const [moduleId, setModuleId] = useState(window.location.pathname.split("/")[3]);

    // console.log(moduleId, "moduleId extactly where I want")
    const handleUpdateModule = async () => {
        // console.log(formData, "NEW FORM DATA");
        await dispatch(updateModule(formData))
        await dispatch(getModule(module.id))
    }


    const fields = [
        {
            id: "name",
            name: "name",
            label: "Module Name",
            placeholder: "Enter Module's Name",
            type: "text",
        },
        {
            id: "Status",
            name: "status",
            label: "Status",
            desc: "Set status as active/inactive",
            type: "switch",
        },
        {
            id: "desc",
            name: "desc",
            label: "Description",
            placeholder: "Description",
            type: "textarea",
        }
    ];



    const dispatch = useDispatch();
    // const module = useSelector((module) => module.module.value.singleModule);
    
    useEffect(() => {
        // dispatch(getModule(module.id))
        setFormData({
            ...module
        })
    }, [module])
    // console.log(moduleId, "moduleId moduleIdmoduleIdmoduleId moduleId")
    return (
        <div className='mt-5 rounded-xl border-2 border-grey-100'>
            <div className="p-3  flex flex-row shadow">
                <div className='w-1/2 border-r-2 border-grey-100 p-5'>
                    <div className='flex flex-row items-center justify-between w-full p-3' >
                        <h1 className="text-4xl font-bold capitalize text-navy-700"> {module.name} </h1>
                        <h1 className={`text-sm font-bold capitalize text-white p-2 rounded-full ${module.status ? "bg-green-500" : "bg-red-500"}`}> {module.status ? "Active" : "Inactive"} </h1>
                    </div>
                    <Form
                        fields={fields}
                        formData={formData}
                        onChange={(newFormData) => {
                            console.log(newFormData, "HERE NEW");
                            setFormData(newFormData)
                        }}
                    />
                    <div className='flex justify-end'>
                        <button
                            onClick={handleUpdateModule}
                            className="border-2 text-white linear rounded-full bg-brand-700 px-4 py-3 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-500  active:!bg-white/70">
                            {formData.id ? "Update" : "Add" + " Module"}
                        </button>
                    </div>
                </div>
                <div className="h-full w-1/2  p-5">
                    <h1 className="p-3 text-center text-4xl font-bold capitalize text-navy-700"> Plans </h1>
                    <Table className="border-b border-grey-500">
                        <TableCaption className="mb-3 font-bold  text-2xl text-start text-black">
                        </TableCaption>
                        <TableHeader>

                            <TableRow>
                                <TableHead className="w-1/12"> # </TableHead>
                                <TableHead className="w-1/3"> Plan </TableHead>
                                <TableHead className="w-1/12"> Price </TableHead>
                                <TableHead className="w-1/12"> Status </TableHead>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {module.plans && module.plans.map((plan, index) => (
                                <TableRow key={plan.id}>
                                    <TableCell className="font-medium w-5">
                                    {index}
                                    </TableCell>
                                    <TableCell className="font-medium">
                                        <h4 className='text-brand-500 font-black'> {plan.name} </h4>
                                    </TableCell>
                                    <TableCell className="font-medium"> Rs. {plan.basePrice || 0}</TableCell>
                                    <TableCell>
                                        {plan.status ?
                                            <div className="flex items-center justify-start w-fit bg-green-500 rounded-2xl  ">
                                                <FiTrendingUp className="h-6 w-6 text-white p-1 " />
                                                {/* <span>Active</span> */}
                                            </div> :
                                            <div className="flex items-center justify-start w-fit rounded-xl bg-red-500">
                                                <FiX className="h-6 w-6 text-white  p-1" />
                                                {/* <span>Inactive</span> */}
                                            </div>
                                        }
                                    </TableCell>
                                </TableRow>

                            ))}


                        </TableBody>
                    </Table>
                </div>
            </div>

        </div>


    );
};

export default Details;