
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { createHeaders } from './headers';


export const getDashboardData = createAsyncThunk("dashboard/get", async () => {

    let response = await fetch(`${process.env.REACT_APP_URL}api/dashboard`, {
        headers: createHeaders()
    });
    let json = await response.json();
    return json;
});


export const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState: {
        value: {
            isError: false,
            isLoading: false,
            status: 'default',
            data: {}
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getDashboardData.fulfilled, (dashboard, action) => {
                dashboard.value.data = action.payload
            })

    }
})

export default dashboardSlice.reducer
