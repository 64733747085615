import { useState } from 'react';
import InputField from "components/fields/InputField";
import Checkbox from "components/checkbox";

export default function SignUp() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [fullname, setFullname] = useState('');
  const [mobile, setMobile] = useState('');
  const [error, setError] = useState(null);

  const handleSignup = async () => {
    const response = await fetch(`http://localhost:3001/open/auth/createadminuser`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ username, password, email, fullname, mobile })
    });

    if (!response.ok) {
      const message = await response.text();
      setError(message);
    } else {
      const jwtToken = await response.text();
      console.log(jwtToken);
      setError(null); // clear error
      localStorage.setItem('jwtToken', jwtToken);
    }
  }

  return (
    <div className="mt-11 mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      {/* Sign up section */}
      <div className="mt-[1vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
          Sign Up
        </h4>
        <p className="mb-9 ml-1 text-base text-gray-600">
          Create your account!
        </p>
        {error && <p>{error}</p>}
        <InputField
          variant="auth"
          extra="mb-3"
          label="Username*"
          placeholder="Enter your username"
          id="username"
          type="text"
          value={username}
          onChange={e => setUsername(e.target.value)}
        />
        <InputField
          variant="auth"
          extra="mb-3"
          label="Email*"
          placeholder="Enter your email"
          id="email"
          type="email"
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
        <InputField
          variant="auth"
          extra="mb-3"
          label="Full Name*"
          placeholder="Enter your full name"
          id="fullname"
          type="text"
          value={fullname}
          onChange={e => setFullname(e.target.value)}
        />
        <InputField
          variant="auth"
          extra="mb-3"
          label="Mobile*"
          placeholder="Enter your mobile number"
          id="mobile"
          type="text"
          value={mobile}
          onChange={e => setMobile(e.target.value)}
        />
        <InputField
          variant="auth"
          extra="mb-3"
          label="Password*"
          placeholder="Min. 8 characters"
          id="password"
          type="password"
          value={password}
          onChange={e => setPassword(e.target.value)}
        />
        <button onClick={handleSignup} className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
          Sign Up
        </button>
        <div className="mt-4">
          <span className=" text-sm font-medium text-navy-700 dark:text-gray-600">
            Already have an account?
          </span>
          <a
            href=" "
            className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
          >
            Sign In
          </a>
        </div>
      </div>
    </div>
  );
}
