import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// import { useNavigate } from "react-router-dom";
const apiUrl = process.env.REACT_APP_URL

// console.log(process.env, "REDUX")
export const login = createAsyncThunk("auth/login", async (data) => {
    let response = await fetch(`${apiUrl}open/auth/login`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
           ...data
        }),
    });
    let json = await response.json();
    return json
});

export const validateToken = createAsyncThunk("auth/validate", async (_, thunkAPI) => {
    const token = localStorage.getItem("x_access_token")
    // const token = thunkAPI.getState().auth.value;
    console.log(token, "TOKEN INSIDE REDUX")
    let response = await fetch(`${apiUrl}open/auth/validatetoken`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "x-access-token": token
        }
    });
    let validation = await response.json();
    if (!validation.auth) {
        thunkAPI.dispatch(updateToken(null));
        // localStorage.setItem("x_access_token", null)
        localStorage.removeItem('x_access_token');
        // const navigate = useNavigate();
        // navigate("/auth/sign-in");
        window.location.href = "/auth/sign-in"
    }
    console.log("validation ---------------------------------> "+JSON.stringify(validation));
    return validation
});


export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        value: {
            isLoggedIn: false,
            user: null,
            status: "",
            access_token: null,
            permissions: null
        }
    },
    reducers: {
        updateToken: (state, action) => {
          state.access_token = action.payload;
        },
      },
    extraReducers: (builder) => {
        builder
        .addCase(login.fulfilled, (state, action) => {
            console.log(action.payload, "PAYLOAD")
            
            state.value.status = action.payload.message
                if(action.payload.success == true) {
                    console.log("action.payload ------------------------------> "+JSON.stringify(action.payload))
                    console.log("action.payload.user ------------------------------> "+JSON.stringify(action.payload.user))
                    state.value.isLoggedIn = true
                    state.value.user = action.payload.user
                    state.value.access_token = action.payload.token
                    localStorage.setItem("x_access_token", action.payload.token)
                    localStorage.setItem("user_name", action.payload.user)
                } else {
                    state.value.isLoggedIn = false
                    state.value.user = null
                    state.value.access_token = null
                    localStorage.removeItem('x_access_token');

                    // if (localStorage.getItem('x_access_token')) {
					// 	localStorage.removeItem('x_access_token');
					// }
                }
            })
            .addCase(login.pending, (state) => {
                state.value.status = "Fetching"
            })
            .addCase(login.rejected, (state) => {
                state.status = "Failed to  fetch data..."
            })
    }
})

export const { updateToken } = authSlice.actions;


export default authSlice.reducer