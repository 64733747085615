
import React, { useEffect } from "react";
import InputField from "../../../../components/fields/InputField";
import SwitchField from "../../../../components/fields/SwitchField";
import { useDispatch, useSelector } from "react-redux";
// import { updateUserInput, resetUserInput } from "../../../../redux/actions/business";
// import { getBusiness, addBusiness, updateBusiness } from "../../../../redux/actions/business";

export const BusinessRequiredFields = (props) => {
  const {
userInput,
handleInputChange,
handleSwitchChange,
handleButtonClick,
isAddBusiness
  } = props
  // const businessId = window.location.pathname.split("/")[3];
  // const isAddBusiness = businessId === "add-business";
  // const dispatch = useDispatch();
  
  // useEffect(() => {
  //   if (!isAddBusiness) {
  //     dispatch(getBusiness(businessId));
  //   } else {
  //     console.log("reset ---------");
  //     dispatch(resetUserInput()); // Reset user input only when transitioning to "Add Business"
  //   }
  // }, []);

  // const userInput = useSelector(state => state.business.value.userInput);

  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   dispatch(updateUserInput({ [name]: value }));
  // };

  // const handleSwitchChange = (event) => {
  //   const status = event.target.checked;
  //   dispatch(updateUserInput({ status: status }));
  // };

  // const handleButtonClick = () => {
  //   if (isAddBusiness) {
  //     dispatch(addBusiness(userInput));
  //   } else {
  //     dispatch(updateBusiness({ ...userInput, id: businessId }));
  //   }
  // };

  return (
    <div className="h-full min-h-[84vh] w-full rounded-xl bg-white p-0">
      <div className="mt-2 flex h-fit w-full justify-between space-x-5 rounded-xl p-4 ">
        <InputField
          name="name"
          label="Business Name"
          id="name"
          type="text"
          placeholder="Enter business name"
          required={true}
          extra="w-[100%]"
          value={userInput.name}
          onChange={handleInputChange}
        />
        <InputField
          name="code"
          label="Business Code"
          id="code"
          type="text"
          placeholder="Enter business code"
          required={true}
          extra="w-[100%]"
          value={userInput.code}
          onChange={handleInputChange}
        />
      </div>

      <div className="mt-2 flex h-fit w-full justify-between space-x-5 rounded-xl p-4 ">
        <InputField
          name="type"
          label="Business Type"
          id="type"
          type="text"
          placeholder="Enter business type"
          required={true}
          extra="w-[100%]"
          value={userInput.type}
          onChange={handleInputChange}
        />
        <InputField
          name="mobile"
          label="Mobile"
          id="mobile"
          type="text"
          placeholder="Enter business mobile"
          required={true}
          extra="w-[100%]"
          value={userInput.mobile}
          onChange={handleInputChange}
        />
      </div>

      <div className="mt-2 flex h-fit w-full justify-between space-x-5 rounded-xl p-4 ">
        <SwitchField
          label="Business Status"
          id="businessName"
          desc="Set status as active/inactive"
          checked={userInput.status || false}
          extra="w-[100%]"
          onChange={handleSwitchChange}
        />
        <InputField
          name="email"
          label="Email"
          id="email"
          type="email"
          placeholder="Enter business email"
          required={true}
          extra="w-[100%]"
          value={userInput.email}
          onChange={handleInputChange}
        />
      </div>

      <div className="mt-2 flex h-fit w-full justify-end space-x-5 rounded-xl p-4 ">
        <button
          onClick={handleButtonClick}
          className="linear rounded-xl border-2 bg-brand-700 px-4 py-2 text-center text-base font-medium text-white transition duration-200 hover:border-2 hover:border-brand-500 hover:!bg-white hover:text-brand-500  active:!bg-white/70"
        >
          {isAddBusiness ? "Add Business" : "Edit Business"}
        </button>
      </div>
    </div>
  );
};

