import { React, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getUsers, addUser, updateUser, deleteUser } from '../../../redux/actions/user';
import { getRoles } from '../../../redux/actions/role';
import toast, { Toaster } from 'react-hot-toast';
import DropdownSelect from 'components/select';
import Paginate from 'components/paginate';


import {
  FiTrendingUp,
  FiX,
  FiLayers,
  FiSearch,
  FiUnlock,
  FiEdit2,
  FiTrash,
  FiSettings,
} from "react-icons/fi";

import Widget from "components/widget/Widget";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../components/shadcn/table";

import DialogCustom from "../../../components/dialog";
import Form from "../../../components/form";

export const User = (props) => {
  const [formData, setFormData] = useState({});
  // const [page, setPage] = useState(1);
  const [filter, setFilter] = useState({
    name: "",
    status: "All",
    limit: 10,
    page: 1
  });
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const notifySuccess = (message) => toast.success(message, {
    style: {
      padding: '35px',
      color: '#a0ca00',
    },
    duration: 3000,
    iconTheme: {
      primary: '#a0ca00',
      secondary: '#222c25',
    }
  })
  const notifyError = (message) => toast.error(message, {
    style: {
      border: '1px solid #fff',
      padding: '35px',
      color: 'red',
    },
    iconTheme: {
      primary: 'red',
      secondary: '#fff',
    }
  })

  const dispatch = useDispatch();
  const roleCount = useSelector((roles) => roles.role.value.data.count);
  console.log("roleCount ------>...." + roleCount); 
  // console.log("roleCount ------>...." + typeof roleCount);

  useEffect(() => {
    dispatch(getUsers({}))
    dispatch(getRoles({ status: "Active", limit: 100 }));
    // dispatch(getRoles({status: "All"}));
    // setFormData({})
  }, [dispatch])
  
  // useEffect(() => {
  //   dispatch(getRoles({status: "All"}));
  //   // dispatch(getRoles({  status: "Active", limit: 500}));
  //   // setFormData({})
  // }, [])

  const users = useSelector((users) => users.user.value.data);
  const roles = useSelector((roles) => roles.role.value.data);
  console.log("roles inside user ------------------------>"+JSON.stringify(roles))

  // const count = useSelector((users) => users.user.value.count);
  // const activeCount = useSelector((users) => users.user.value.data.activeCount);
  // const inactiveCount = useSelector((users) => users.user.value.data.inactiveCount);
  const apiStatus = useSelector((users) => users.user.value.status);
  const [open, setOpen] = useState(false);
  const transparent = true

  const handleActionButtonClick = (userId, action) => {
    if (action === 'edit') setIsDialogOpen(true);
    if (action === 'delete') setIsDeleteDialogOpen(true);
    if (userId == null) {
      console.log(userId, action, "userId, action")
      setIsDialogOpen(true)
      return setFormData({})
    }

    const selectedUser = users.rows.find((user) => user.id === userId);
    setFormData(selectedUser)
  };

  useEffect(() => {
    dispatch(getUsers(filter));
  }, [filter]);

  /*const handleCrudUser = async () => {
    try {
      setIsDialogOpen(false)
      if (formData.id) await dispatch(updateUser(formData));
      if (!formData.id) await dispatch(addUser(formData));
      notifySuccess(apiStatus)

      setFormData({});
      dispatch(getUsers());

    } catch (error) {
      console.error('Failed to add state:', error);
    }
  };*/
  
  const handleCrudUser = async () => {
  try {
    setIsDialogOpen(false);
    if (formData.id) {
      const action = await dispatch(updateUser(formData));
      dispatch(getUsers(filter));
      if(action.payload.success){
      	notifySuccess(action.payload.message);
      }
      else{
      	notifyError(action.payload.message);
      }
      
    } else {
      const action = await dispatch(addUser(formData));
      dispatch(getUsers(filter));
      if(action.payload.success){
      	notifySuccess(action.payload.message);
      }
      else{
      	notifyError(action.payload.message);
      }
    }
    setFormData({});
    //dispatch(getZones());
  } catch (error) {
    console.error("Failed to add User:", error);
  }
};

  /*const handleDelete = async () => {
    try {
      setIsDeleteDialogOpen(false)
      console.log(formData, "inside delete")
      await dispatch(deleteUser(formData.id));
      // setFormData({});
      notifySuccess(apiStatus)
      // console.log("cleared FormData ->", formData);
      dispatch(getUsers());

    } catch (error) {
      console.error('Failed to add state:', error);
    }
  };*/
  
  const handleDelete = async () => {
  try {
    setIsDeleteDialogOpen(false);
    const action = await dispatch(deleteUser(formData.id));
    dispatch(getUsers(filter));
    console.log("inside user del fn action----------------------> "+action)
    console.log("inside user del fn payload ----------------------> "+action.payload)
    if(action.payload.success){
      	notifySuccess(action.payload.message);
      }
      else{
      	notifyError(action.payload.message);
      }
    //dispatch(getZones());
  } catch (error) {
    console.error("Failed to delete user:", error);
  }
};

  console.log(users, "ROLES")
  // const users = [];
  const fields = [
    {
      id: "username",
      name: "username",
      label: "User Name",
      placeholder: "Enter User's Name",
      type: "text",
      // reflectChange: false
    },
    {
      id: "Status",
      name: "status",
      label: "Status",
      desc: "Set status as active/inactive",
      type: "switch",
    },
    {
      id: "fullname",
      name: "fullname",
      label: "Full Name",
      placeholder: "Enter User's Full Name",
      type: "text",
      // reflectChange: false
    },
    {
      id: "email",
      name: "email",
      label: "Email",
      placeholder: "Enter User's Email",
      type: "mail",
      // reflectChange: false
    },
    {
      id: "mobile",
      name: "mobile",
      label: "User Mobile",
      placeholder: "Enter User's Mobile",
      type: "number",
    },
    {
      id: "roleId",
      name: "roleId",
      label: "User Role",
      desc: "Set a user role",
      type: "dropdown",
      toSelect: "id",
      selectedvalue: "role",
      placeHolder: "select role",
      list: roles.rows
    },
  ];

  return (
    <div className="mt-3 h-full min-h-[84vh] w-full rounded-xl bg-white p-4">
      <Toaster />
      <div className="h-full w-full rounded-xl">
        <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-3 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-3">
          <Widget
            icon={<FiLayers className="h-7 w-7" />}
            title={"Total Users"}
            subtitle={users.count || 0}
            styling={
              {
                iconContStyles: "bg-brand-500",
                iconStyles: "text-white",
                border: "border-solid border-2 border-yellow-100"
              }
            }
          />
          <Widget
            icon={<FiTrendingUp className="h-7 w-7" />}
            title={"Active Users"}
            subtitle={users.activeCount || 0}
            styling={
              {
                iconContStyles: "bg-green-500",
                iconStyles: "text-white",
                border: "border-solid border-2 border-green-100"
              }
            }
          />
          <Widget
            icon={<FiX className="h-7 w-7" />}
            title={"Inactive Users"}
            subtitle={users.inactiveCount || 0}
            styling={
              {
                iconContStyles: "bg-red-500",
                iconStyles: "text-white",
                border: "border-solid border-2 border-red-100"
              }
            }
          />


        </div>

        <div className='mt-3  p-4 rounded-xl border-solid border-2 border-gray-100'>
          <Table className="border-b border-grey-500">
            <TableCaption className="mb-3 font-bold  text-2xl text-start text-black">
              <div className='flex justify-between items-center'>
                <div className='flex justify-between w-1/2 items-center'>
                  <div className="mr-5 p-4 flex h-full items-center rounded-full bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white ">
                    <p className="pl-3 pr-2 text-xl">
                      <FiSearch className="h-4 w-4 text-gray-400 dark:text-white" />
                    </p>
                    <input
                      // value={filter.name}
                      onChange={(e) => {
                        setFilter({...filter, page:1, ["name"]: e.target.value })
                      }}
                      type="text"
                      placeholder="Search..."
                      className="block h-full w-full  bg-lightPrimary text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:w-fit"
                    />
                  </div>
                  <DropdownSelect
                    list={[
                      { name: "All", id: 1 },
                      { name: "Active", id: 2 },
                      { name: "Inactive", id: 3 },
                    ]}
                    triggerStyles="bg-lightPrimary !rounded-full"
                    // listStyles="text-sm text-red-700"
                    placeHolder={"Status"}
                    toSelect={"name"}
                    onChange={(value) => {
                      setFilter({...filter, page:1, ["status"]: value })
                    }}
                  />
                </div>
                <div className='flex  justify-end'>
                  <button
                    onClick={() => handleActionButtonClick(null, "add")}
                    className="border-2 text-white linear rounded-full bg-brand-700 px-4 py-3 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-500  active:!bg-white/70">
                    Add User
                  </button>

                </div>
              </div>
            </TableCaption>
            <TableHeader>

              <TableRow>
                <TableHead className="w-1/3"> User </TableHead>
                <TableHead className="w-1/5"> Role </TableHead>
                <TableHead>Status</TableHead>
                <TableHead className="w-1/6 text-center ">Actions</TableHead>
              </TableRow>
            </TableHeader>

            <TableBody>
              {users.rows.length > 0 &&

                users.rows.map((user) => (
                  <TableRow key={user.id}>
                    <TableCell className="font-medium">
                      <h4 className='text-brand-500 font-black'> {user.fullname} </h4>
                      <p> {user.email} </p>
                      <p> {user.mobile} </p>
                    </TableCell>
                    <TableCell className="font-medium">{user.role ? user.role.name : "-"}</TableCell>
                    <TableCell>
                      {user.status ?
                        <div className="flex items-center justify-start w-fit bg-green-500 rounded-2xl  ">
                          <FiTrendingUp className="h-6 w-6 text-white p-1 " />
                          {/* <span>Active</span> */}
                        </div> :
                        <div className="flex items-center justify-start w-fit rounded-xl bg-red-500">
                          <FiX className="h-6 w-6 text-white  p-1" />
                          {/* <span>Inactive</span> */}
                        </div>
                      }
                    </TableCell>
                    <TableCell className="w-1/6  ">
                      <div className='w-full flex justify-around'>
                        <div
                          onClick={() => handleActionButtonClick(user.id, "edit")}
                          className='mx-2 bg-yellow-500 w-fit p-1 rounded-3xl cursor-pointer' >
                          <FiEdit2 className='h-4 w-4 text-white' />
                        </div>
                        


                        <div
                          onClick={() => handleActionButtonClick(user.id, "delete")}
                          className='mx-2 bg-red-500 w-fit p-1 rounded-3xl cursor-pointer'>
                          <FiTrash className='h-4 w-4 text-white' />
                        </div>
                      </div>
                    </TableCell>
                  </TableRow>
                ))


              }

            </TableBody>

          </Table>


          {users.rows.length > 0 &&
            <Paginate
              page={filter.page}
              totalCount={users.count}
              limit={filter.limit}
              changePage={(value) => {
                // console.log(value)
                setFilter({ ...filter, ["page"]: value })
              }}
            />
          }

          {users.rows.length == 0 &&
            <div className='p-10 flex flex-col justify-center items-center'>
              <h4 className='mb-5 text-2xl text-brand-500 font-medium'> No Users Added! Click below to add one. </h4>
              <button
                onClick={() => handleActionButtonClick(null, "add")}
                className="border-2 text-white linear rounded-xl bg-brand-700 px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-500  active:!bg-white/70">
                Add User
              </button>
            </div>
          }

          <DialogCustom

            open={isDialogOpen}
            onOpenChange={setIsDialogOpen}
            dialogTitle="Edit User"
            dialogWidth="w-1/2"
            dialogDesc="Update the neccessary fields and save."
            content={
              <>
                <Form
                  fields={fields}
                  formData={formData}
                  onChange={(newFormData) => setFormData(newFormData)}
                />
                <div className='flex justify-end'>
                  <button
                    onClick={handleCrudUser}
                    className="linear rounded-full border-2 bg-brand-700 px-4 py-2 text-center text-base font-medium text-white transition duration-200 hover:border-2 hover:border-brand-500 hover:!bg-white hover:text-brand-500  active:!bg-white/70"
                    >
                    {formData.id ? "Update" : "Add" + " User"}
                  </button>
                </div></>
            }
          />
          <DialogCustom

            open={isDeleteDialogOpen}
            onOpenChange={setIsDeleteDialogOpen}
            dialogTitle="Delete User"
            dialogWidth="w-1/2"
            dialogDesc="Are you sure you want to delete this user ?"
            content={
              <div className='flex flex-row-reverse'>
                <button
                  onClick={handleDelete}
                  className=" text-white linear rounded-xl border-2 border-white bg-red-700 px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-red-500 hover:border-2 hover:border-red-700 hover:shadow-lg  ">
                  Delete
                </button>
                <button
                  onClick={() => {
                    setIsDeleteDialogOpen(false);
                  }}
                  className="mr-4 text-white linear rounded-xl border-2 border-white bg-brand-700 px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-700 hover:shadow-lg  ">
                  Cancel
                </button>
              </div>
            }
          />
        </div>
      </div>
    </div>
  )
};

export default User