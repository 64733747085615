import React, { useState, useEffect } from "react";
import InputField from "components/fields/InputField";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import {
  verifyResetPasswordToken,
  resetPassword,
} from "../../../redux/actions/forgetpassword";
import { useNavigate } from "react-router-dom";

export default function CreateNewPassword() {
  const dispatch = useDispatch();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const loading = useSelector((state) => state.forgetPassword.loading);
  // const error = useSelector((state) => state.forgetPassword.error);
  const isValidToken = useSelector((state) => state.forgetPassword.isValidToken);
  // const [validToken, setValidToken] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");
  const navigate = useNavigate();
  const [validation, setValidation] = useState({
    passwordValid: false,
    passwordError: "",
  });

 
  const validatePassword = (password) => {
    const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,20}$/;
    if (!password) {
      setValidation((prevState) => ({
        ...prevState,
        passwordValid: false,
        passwordError: "Password is required.",
      }));
      //return false;
    } else if (!passwordRegex.test(password)) {
      setValidation((prevState) => ({
        ...prevState,
        passwordValid: false,
        passwordError:
          "Password should be between 8 to 20 characters and contain at least one letter and one number.",
      }));
      //return false;
    } else {
      setValidation((prevState) => ({
        ...prevState,
        passwordValid: true,
        passwordError: "",
      }));
      //return true;
    }
  };

  useEffect(() => {
    const verifyToken = async () => {
      try {
        const response = await dispatch(verifyResetPasswordToken(token));
        
        console.log("response of verifyResetPasswordToken :", JSON.stringify(response));
        console.log("isValidToken redux updated :", JSON.stringify(isValidToken));
        
        if(!response.payload.success){
          console.log("invalid token!!!!");
        	//notifyError("invalid token");
          navigate("/auth/sign-in");
        
        }
        
        // setValidToken(true);
      } catch (error) {
        console.error("TRYCATCH Error verifying reset password token:", error);
      }
    };

    verifyToken();
  }, [dispatch, token]);

 /* const handleClick = async () => {
    // Check if password and confirmPassword match
    if (password !== confirmPassword) {
      console.log("Passwords do not match");
      notifyError("Passwords do not match");
      return;
    }
    if (!password.trim() || !confirmPassword.trim()) {
    notifyError("Password and Confirm Password are required.");
    return;
  }
    else if (!validation.passwordValid) {
      return notifyError(validation.passwordError);
    }

    

  
    dispatch(resetPassword(token, password))
      .then((result) => {
        // Handle password reset success
        //alert('Password reset successful');
        console.log("main res :"+result)
        notifySuccess("Password reset successful");
        setTimeout(() => {
          navigate("/auth/sign-in");
        }, 5000); 
        //navigate("/auth/sign-in");
        // Optionally, redirect the user to a login page or another appropriate page
      })
      .catch((error) => {
        // Handle password reset failure
        console.error("Error resetting password:", error);
        notifyError("Error while resetting password");
      });
  }; */
  
  const handleClick = async () => {
  // Check if password and confirmPassword are empty
  if (!password.trim() || !confirmPassword.trim()) {
    notifyError("Password and Confirm Password are required.");
    return;
  }
  
  // Use the validatePassword function to check password validity
  validatePassword(password);
  if (!validation.passwordValid) {
    notifyError(validation.passwordError);
    return;
  }
  
  // Check if password and confirmPassword match
  if (password !== confirmPassword) {
    notifyError("Passwords do not match");
    return;
  }

  console.log("before sending password to api password: "+password)
  //dispatch(resetPassword(token, password))
     dispatch(resetPassword({ token: token, password: password }))
    .then((result) => {
      // Handle password reset success
      //alert('Password reset successful');
      if(result.payload.success){
      	console.log("main res :" + JSON.stringify(result))
      	console.log("after sending password to api password: "+password)
      notifySuccess("Password reset successful");
      setTimeout(() => {
        navigate("/auth/sign-in");
      }, 1000);
      }
      else{
      	notifyError("Resetting password failed!");
      }
      
   
    })
    .catch((error) => {
      // Handle password reset failure
      console.error("Error resetting password:", error);
      notifyError("Error while resetting password");
    });
};


  if (loading) {
    return <div className="flex items-center justify-center">Loading...</div>;
  }

  const notifySuccess = (message) =>
    toast.success(message, {
      duration: 1000,
      style: {
        padding: "35px",
        color: "#a0ca00",
      },
      iconTheme: {
        primary: "#a0ca00",
        secondary: "#222c25",
      },
    });
  const notifyError = (message) =>
    toast.error(message, {
      duration: 5000,
      style: {
        border: "1px solid #fff",
        padding: "35px",
        color: "red",
      },
      iconTheme: {
        primary: "red",
        secondary: "#fff",
      },
    });


  return (
    <div className="mt-16 mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
          Create New Password
        </h4>
        <p className="mb-9 ml-1 text-base text-gray-600">
          Enter a new password.
        </p>

        {/* Password */}
        <InputField
          variant="auth"
          extra="mb-3"
          label="New Password*"
          placeholder="Enter your new password"
          id="password"
          type="password"
          value={password}
          //onChange={handlePasswordChange}
          onChange={(e) => {
            setPassword(e.target.value);
            validatePassword(e.target.value);
          }}
        />
        {/* Confirm Password */}
        <InputField
          variant="auth"
          extra="mb-3"
          label="Confirm Password*"
          placeholder="Confirm your new password"
          id="confirmPassword"
          type="password"
          value={confirmPassword}
          //onChange={handleConfirmPasswordChange}
          onChange={(e) => {
            setConfirmPassword(e.target.value);
            validatePassword(e.target.value);
          }}
        />
        <button
          className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
          onClick={handleClick}
        >
          Set New Password
        </button>
        <Toaster />
      </div>
    </div>
  );
}
