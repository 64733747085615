import React from 'react';
import * as Tabs from '@radix-ui/react-tabs';
import { styled } from '@stitches/react';
import { violet, mauve, blackA, green } from '@radix-ui/colors';

const CustomTabs = (props) => {

    const { tabs, tabContents } = props;

    return (
        <TabsRoot defaultValue={"tab0"}>
            <TabsList aria-label="Manage your account">
                {tabs.map((tab, index) =>
                    <TabsTrigger value={"tab" + index}>{tab}</TabsTrigger>
                )}
            </TabsList>
            {tabContents.map((content, index) =>
                <TabsContent value={"tab" + index}>
                    {content}
                </TabsContent>
            )}
        </TabsRoot>
    )
};

const TabsRoot = styled(Tabs.Root, {
    display: 'flex',
    flexDirection: 'column',
    width: "100%",
    // boxShadow: `0 2px 10px ${blackA.blackA2}`,
});

const TabsList = styled(Tabs.List, {
    flexShrink: 0,
    display: 'flex',
    // borderBottom: `1px solid ${mauve.mauve6}`,
});

const TabsTrigger = styled(Tabs.Trigger, {
    all: 'unset',
    fontFamily: 'inherit',
    backgroundColor: 'white',
    padding: '0 20px',
    height: 45,
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 15,
    lineHeight: 1,
    color: "#7cb400",
    border: "2px solid #7cb400",
    userSelect: 'none',
    fontWeight: "800",
    fontSize: 17,
    '&:first-child': { 
        borderTopLeftRadius: 12,
        // borderBottomLeftRadius: 12,
    },
    '&:last-child': { 
        borderTopRightRadius: 12, 
        // borderBottomRightRadius: 12 
    },
    // '&:hover': { color: "#7cb400" },
    '&[data-state="active"]': {
        background: "#7cb400",
        color: "white",
        // boxShadow: 'inset 0 -1px 0 0 currentColor, 0 1px 0 0 currentColor',
    },
    '&:focus': { position: 'relative' },
});

const TabsContent = styled(Tabs.Content, {
    flexGrow: 1,
    padding: 20,
    border: 0,
    backgroundColor: 'white',
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
    outline: 'none',
    // '&:focus': { boxShadow: `0 0 0 2px black` },
});

const Flex = styled('div', { display: 'flex' });

const Text = styled('p', {
    marginTop: 0,
    marginBottom: 20,
    color: mauve.mauve11,
    fontSize: 15,
    lineHeight: 1.5,
});

const Fieldset = styled('fieldset', {
    all: 'unset',
    marginBottom: 15,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
});

const Label = styled('label', {
    fontSize: 13,
    lineHeight: 1,
    marginBottom: 10,
    color: violet.violet12,
    display: 'block',
});

const Input = styled('input', {
    all: 'unset',
    flex: '1 0 auto',
    borderRadius: 4,
    padding: '0 10px',
    fontSize: 15,
    lineHeight: 1,
    color: "#7cb400",
    boxShadow: `0 0 0 1px ${violet.violet7}`,
    height: 35,
    '&:focus': { boxShadow: `0 0 0 2px ${"#7cb400"}` },
});

const Button = styled('button', {
    all: 'unset',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 4,
    padding: '0 15px',
    fontSize: 15,
    lineHeight: 1,
    fontWeight: 500,
    height: 35,
    variants: {
        variant: {
            green: {
                backgroundColor: green.green4,
                color: green.green11,
                '&:hover': { backgroundColor: green.green5 },
                '&:focus': { boxShadow: `0 0 0 2px ${"#7cb400"}` },
            },
        },
    },
    defaultVariants: {
        variant: 'green',
    },
});

export default CustomTabs;