// import React, { useEffect } from "react";
// import { useState } from "react";
// import {
//   fetchFeatures,
//   getSubscriptions,
// } from "../../../redux/actions/subscription";
// import { useSelector, useDispatch } from "react-redux";
// import SwitchField from "../../../components/fields/SwitchField";
// import InputField from "../../../components/fields/InputField";

// const FeaturesSettings = () => {
//   const dispatch = useDispatch();
//   const subscriptionId = window.location.pathname.split("/")[3];
//   // const [features, setFeatures] = useState([]);
//   const [featuresSettings, setFeaturesSettings] = useState([]);
//   const id = subscriptionId;
//   // useEffect(() => {
//   //   dispatch(fetchFeatures(id));
//   // }, []);

//   useEffect(() => {
//     const fetchData = async () => {
//       const action = await dispatch(fetchFeatures(id));
//       // console.log("action ------"+JSON.stringify(features.payload.features));
//       // setFeatures(JSON.stringify(action.payload.features))
//       console.log(
//         "action.payload -------lllll>>>>>" + JSON.stringify(action.payload)
//       );
//       // setFeatures((action.payload.features))
//       // setFeatures((action.payload.featureDatas))
//       setFeaturesSettings(action.payload);
//     };

//     fetchData();
//   }, []);

//   // console.log("features ------ uE"+JSON.stringify(features));
//   const handleSwitchChange = (event) => {
//     const status = event.target.checked;
//     // dispatch(updateUserInput({ status: status }));
//   };

//   console.log(
//     "action.payload -------fffffff>>>>>" + JSON.stringify(featuresSettings)
//   );

//   return (
//     <div className="mt-3  h-full min-h-[84vh] w-full rounded-xl bg-white p-4">
//       <div className="flex space-x-10">
//         <div className="h-fit w-[20%]">
//           <div className="space-y-10">
//             {featuresSettings &&
//               featuresSettings.features &&
//               featuresSettings.features.map(
//                 (feature, index) =>
//                   feature.type === "Boolean" && (
//                     <div key={index} className="">
//                       <SwitchField
//                         label={feature.name}
//                         id={feature.name}
//                         // desc="Set status as active/inactive"
//                         // checked={userInput.status || false}
//                         checked={feature.status}
//                         extra="w-[100%]"
//                         onChange={handleSwitchChange}
//                       />
//                     </div>
//                   )
//               )}
//           </div>
//         </div>
//         <div className="h-fit w-fit">
//           <div className="space-y-10">
//             {featuresSettings &&
//               featuresSettings.features &&
//               featuresSettings.features.map((feature, index) => (
//                 // feature.type === 'Boolean' &&
//                 <div key={index} className="space-y-1 w-[250px] border-solid  border-2 border-black rounded-xl  p-3">
//                   <h1 className="font-black text-brand-500">{feature.name}</h1>
//                   <div className="flex justify-between items-center">
//                     <h1>Max Value</h1>
//                     <InputField value={featuresSettings.planFeatures.find(planFeature => planFeature.featureId === feature.id)?.maxCount} inputCSS="h-8 w-32 mt-0"/>
//                   </div>
//                   <div className="flex justify-between items-center">
//                   <h1>Usage Value</h1>
//                     <InputField value={featuresSettings.featureUsage.find(featureUsage => featureUsage.featureId === feature.id)?.usageCounter} inputCSS="h-8 w-32 mt-0"/>
//                   </div>
//                   <div className="flex justify-between items-center">
//                   <h1>Reset Date</h1>
//                     <InputField  value={new Date(feature.resetDate).toLocaleDateString()} inputCSS="h-8 w-32 mt-0"/>
//                   </div>

//                 </div>
//               ))}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default FeaturesSettings;

//  ****workiong
// import React, { useEffect } from "react";
// import { useState } from "react";
// import {
//   fetchFeatures,
//   getSubscriptions,
// } from "../../../redux/actions/subscription";
// import { useSelector, useDispatch } from "react-redux";
// import SwitchField from "../../../components/fields/SwitchField";
// import InputField from "../../../components/fields/InputField";
// import Datepicker from "react-tailwindcss-datepicker";

// const FeaturesSettings = () => {
//   const dispatch = useDispatch();
//   const subscriptionId = window.location.pathname.split("/")[3];
//   const [featuresSettings, setFeaturesSettings] = useState([]);
//   const id = subscriptionId;

//   useEffect(() => {
//     const fetchData = async () => {
//       const action = await dispatch(fetchFeatures(id));
//       console.log(
//         "action.payload -------lllll>>>>>" + JSON.stringify(action.payload)
//       );
//       setFeaturesSettings(action.payload);
//     };

//     fetchData();
//   }, []);

//   // const handleInputChange = (e) => {
//   //   const { name, value } = e.target;
//   //   setFeaturesSettings((prevSettings) => ({
//   //     ...prevSettings,
//   //     [name]: value,
//   //   }));
//   // };

//   const handleInputChange = (e, featureId, field) => {
//     const { value } = e.target;
//     // Determine the target feature based on featureId
//     const targetFeature = featuresSettings.features.find(
//       (feature) => feature.id === featureId
//     );

//     // Determine the target field within the feature and update its value
//     if (targetFeature) {
//       if (field === 'maxCount') {
//         // Update the maxCount in planFeatures
//         const updatedPlanFeatures = featuresSettings.planFeatures.map((planFeature) =>
//           planFeature.featureId === featureId ? { ...planFeature, maxCount: value } : planFeature
//         );

//         // Update the state
//         setFeaturesSettings((prevSettings) => ({
//           ...prevSettings,
//           planFeatures: updatedPlanFeatures
//         }));
//         console.log("Max Count PFet---> "+JSON.stringify(updatedPlanFeatures));
//       } else if (field === 'usageCounter') {
//         // Update the usageCounter in featureUsage
//         const updatedFeatureUsage = featuresSettings.featureUsage.map((featureUsage) =>
//           featureUsage.featureId === featureId ? { ...featureUsage, usageCounter: value } : featureUsage
//         );

//         // Update the state
//         setFeaturesSettings((prevSettings) => ({
//           ...prevSettings,
//           featureUsage: updatedFeatureUsage
//         }));
//         console.log("usageCounter FetUsg---> "+JSON.stringify(updatedFeatureUsage));

//       }
//     }
//   };

//   // console.log("features ------ uE"+JSON.stringify(features));
//   const handleSwitchChange = (event) => {
//     const status = event.target.checked;
//     // dispatch(updateUserInput({ status: status }));
//   };

//   // console.log(
//   //   "action.payload -------fffffff>>>>>" + JSON.stringify(featuresSettings)
//   // );

//   return (
//     <div className="mt-3  h-full min-h-[84vh] w-full rounded-xl bg-white p-4">
//       {/* <h1 className="pl-12 font-black text-brand-500">Boolean Features</h1> */}
//       <div className="flex justify-around space-x-10">
//         <div className="h-fit w-[25%]">
//             <h1 className="font-black text-brand-500 text-xl mb-3">Boolean Features</h1>
//           <div className="space-y-10">

//             {featuresSettings &&
//               featuresSettings.features &&
//               featuresSettings.features.map(
//                 (feature, index) =>
//                   feature.type === "Boolean" && (
//                     <div key={index} className="">
//                       <SwitchField
//                         label={feature.name}
//                         id={feature.name}
//                         // desc="Set status as active/inactive"
//                         // checked={userInput.status || false}
//                         checked={feature.status}
//                         extra="w-[100%]"
//                         onChange={handleSwitchChange}
//                       />
//                     </div>
//                   )
//               )}
//           </div>
//           {/* <Datepicker
//         displayFormat={"DD/MM/YYYY"}
//         inputClassName="placeholder-gray-800 w-[100%] p-2 rounded-xl"
//         toggleClassName="text-navy-700 rounded-xl p-3"
//         primaryColor={"lime"}
//         containerClassName={
//           "border-2 border-grey-400 rounded-xl flex mt-2"
//         }
//         value={value}
//         asSingle={field.isSingle}
//         useRange={field.isRange}
//         placeholder={placeholder} // Use placeholder instead of value for the selected date
//         onChange={(e) => {
//           handleValueChange(field.isSingle, field.id, e);
//         }}
//       /> */}

//         </div>
//         {/* <div className="h-fit w-fit">
//           <div className="space-y-10">
//             {featuresSettings &&
//               featuresSettings.features &&
//               featuresSettings.features.map((feature, index) => (
//                 // feature.type === 'Boolean' &&
//                 <div key={index} className="space-y-1 w-[250px] border-solid  border-2 border-black rounded-xl  p-3">
//                   <h1 className="font-black text-brand-500">{feature.name}</h1>
//                   <div className="flex justify-between items-center">
//                     <h1>Max Value</h1>
//                     <InputField value={featuresSettings.planFeatures.find(planFeature => planFeature.featureId === feature.id)?.maxCount} inputCSS="h-8 w-32 mt-0"/>
//                   </div>
//                   <div className="flex justify-between items-center">
//                   <h1>Usage Value</h1>
//                     <InputField value={featuresSettings.featureUsage.find(featureUsage => featureUsage.featureId === feature.id)?.usageCounter} inputCSS="h-8 w-32 mt-0"/>
//                   </div>
//                   <div className="flex justify-between items-center">
//                   <h1>Reset Date</h1>
//                     <InputField  value={new Date(feature.resetDate).toLocaleDateString()} inputCSS="h-8 w-32 mt-0"/>
//                   </div>

//                 </div>
//               ))}
//           </div>
//         </div> */}

//         <div className="h-fit w-fit">
//           <h1 className="font-black text-brand-500 text-xl mb-3">Boolean Features</h1>
//           <div className="flex space-x-5">

//             {/* First Column */}
//             <div className="space-y-5">
//               {featuresSettings &&
//                 featuresSettings.features &&
//                 featuresSettings.features
//                   .slice(0, Math.ceil(featuresSettings.features.length / 2))
//                   .map((feature, index) => (
//                     <div
//                       key={index}
//                       className="border-black w-[250px] space-y-1 rounded-xl border-2 border-solid p-3"
//                     >
//                       <h1 className="font-black text-brand-500">
//                         {feature.name}
//                       </h1>
//                       <div className="flex items-center justify-between">
//                         <h1>Max Value</h1>
//                         <InputField
//                         name="maxCount"
//                         id="maxCount"
//                         type="text"
//                           value={
//                             featuresSettings.planFeatures.find(
//                               (planFeature) =>
//                                 planFeature.featureId === feature.id
//                             )?.maxCount
//                           }
//                           // onChange={handleInputChange}
//                           onChange={(e) => handleInputChange(e, feature.id, 'maxCount')}
//                           inputCSS="h-8 w-32 mt-0"
//                         />
//                       </div>
//                       <div className="flex items-center justify-between">
//                         <h1>Usage Value</h1>
//                         <InputField
//                         name="usageCounter"
//                         id="usageCounter"
//                         type="text"
//                         // onChange={handleInputChange}
//                         onChange={(e) => handleInputChange(e, feature.id, 'usageCounter')}
//                           value={
//                             featuresSettings.featureUsage.find(
//                               (featureUsage) =>
//                                 featureUsage.featureId === feature.id
//                             )?.usageCounter
//                           }
//                           inputCSS="h-8 w-32 mt-0"
//                         />
//                       </div>
//                       <div className="flex items-center justify-between">
//                         <h1>Reset Date</h1>
//                         <InputField
//                           value={new Date(
//                             feature.resetDate
//                           ).toLocaleDateString()}
//                           inputCSS="h-8 w-32 mt-0"
//                         />
//                       </div>
//                     </div>
//                   ))}
//             </div>

//             {/* Second Column */}
//             <div className="space-y-5">
//               {featuresSettings &&
//                 featuresSettings.features &&
//                 featuresSettings.features
//                   .slice(Math.ceil(featuresSettings.features.length / 2))
//                   .map((feature, index) => (
//                     <div
//                       key={index}
//                       className="border-black w-[250px] space-y-1 rounded-xl border-2 border-solid p-3"
//                     >
//                       <h1 className="font-black text-brand-500">
//                         {feature.name}
//                       </h1>
//                       <div className="flex items-center justify-between">
//                         <h1>Max Value</h1>
//                         <InputField
//                           value={
//                             featuresSettings.planFeatures.find(
//                               (planFeature) =>
//                                 planFeature.featureId === feature.id
//                             )?.maxCount
//                           }
//                           inputCSS="h-8 w-32 mt-0"
//                         />
//                       </div>
//                       <div className="flex items-center justify-between">
//                         <h1>Usage Value</h1>
//                         <InputField
//                           value={
//                             featuresSettings.featureUsage.find(
//                               (featureUsage) =>
//                                 featureUsage.featureId === feature.id
//                             )?.usageCounter
//                           }
//                           inputCSS="h-8 w-32 mt-0"
//                         />
//                       </div>
//                       <div className="flex items-center justify-between">
//                         <h1>Reset Date</h1>
//                         <InputField
//                           value={new Date(
//                             feature.resetDate
//                           ).toLocaleDateString()}
//                           inputCSS="h-8 w-32 mt-0"
//                         />
//                       </div>
//                     </div>
//                   ))}
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default FeaturesSettings;

// 90% working
// import React, { useEffect } from "react";
// import { useState } from "react";
// import {
//   fetchFeatures,
//   getSubscriptions,
// } from "../../../redux/actions/subscription";
// import { useSelector, useDispatch } from "react-redux";
// import SwitchField from "../../../components/fields/SwitchField";
// import InputField from "../../../components/fields/InputField";
// import Datepicker from "react-tailwindcss-datepicker";
// import moment from "moment";

// const FeaturesSettings = () => {
//   const dispatch = useDispatch();
//   const subscriptionId = window.location.pathname.split("/")[3];
//   const [featuresSettings, setFeaturesSettings] = useState([]);
//   const id = subscriptionId;

//   useEffect(() => {
//     const fetchData = async () => {
//       const action = await dispatch(fetchFeatures(id));
//       console.log(
//         "action.payload -------lllll>>>>>" + JSON.stringify(action.payload)
//       );
//       setFeaturesSettings(action.payload);

//     };

//     fetchData();
//   }, []);

//   // const handleInputChange = (e) => {
//   //   const { name, value } = e.target;
//   //   setFeaturesSettings((prevSettings) => ({
//   //     ...prevSettings,
//   //     [name]: value,
//   //   }));
//   // };

//   const handleInputChange = (e, featureId, field) => {
//     const { value } = e.target;
//     // Determine the target feature based on featureId
//     const targetFeature = featuresSettings.features.find(
//       (feature) => feature.id === featureId
//     );

//     // Determine the target field within the feature and update its value
//     if (targetFeature) {
//       if (field === "maxCount") {
//         // Update the maxCount in planFeatures
//         const updatedPlanFeatures = featuresSettings.planFeatures.map(
//           (planFeature) =>
//             planFeature.featureId === featureId
//               ? { ...planFeature, maxCount: value }
//               : planFeature
//         );

//         // Update the state
//         setFeaturesSettings((prevSettings) => ({
//           ...prevSettings,
//           planFeatures: updatedPlanFeatures,
//         }));
//         console.log(
//           "Max Count PFet---> " + JSON.stringify(updatedPlanFeatures)
//         );
//       } else if (field === "usageCounter") {
//         // Update the usageCounter in featureUsage
//         const updatedFeatureUsage = featuresSettings.featureUsage.map(
//           (featureUsage) =>
//             featureUsage.featureId === featureId
//               ? { ...featureUsage, usageCounter: value }
//               : featureUsage
//         );

//         // Update the state
//         setFeaturesSettings((prevSettings) => ({
//           ...prevSettings,
//           featureUsage: updatedFeatureUsage,
//         }));
//         console.log(
//           "usageCounter FetUsg---> " + JSON.stringify(updatedFeatureUsage)
//         );
//       }
//     }
//   };

//   // console.log("features ------ uE"+JSON.stringify(features));
//   const handleSwitchChange = (event) => {
//     const status = event.target.checked;
//     // dispatch(updateUserInput({ status: status }));
//   };

//   // console.log(
//   //   "action.payload -------fffffff>>>>>" + JSON.stringify(featuresSettings)
//   // );

//   const [value, setValue] = useState(null);

//   // const handleDateChange = (date) => {
//   //   setValue(date);
//   // };
//   const handleDateChange = (date, featureId) => {
//     // Update the value only for the specific feature
//     const updatedFeatures = featuresSettings.features.map(feature =>
//       feature.id === featureId ? { ...feature, resetDate: date.startDate } : feature
//     );
//     setFeaturesSettings(prevSettings => ({
//       ...prevSettings,
//       features: updatedFeatures
//     }));

//     console.log(
//       "Date Fet---> " + JSON.stringify(updatedFeatures)
//     );
//   };

//   const formattedValue = value ? moment(value).format("DD/MM/YYYY") : "";

//   return (
//     <div className="mt-3  h-full min-h-[84vh] w-full rounded-xl bg-white p-4">
//       {/* <h1 className="pl-12 font-black text-brand-500">Boolean Features</h1> */}
//       <div className="flex justify-around space-x-10">
//         <div className="h-fit w-[25%]">
//           <h1 className="mb-3 text-xl font-black text-brand-500">
//             Boolean Features
//           </h1>
//           <div className="space-y-10">
//             {featuresSettings &&
//               featuresSettings.features &&
//               featuresSettings.features.map(
//                 (feature, index) =>
//                   feature.type === "Boolean" && (
//                     <div key={index} className="">
//                       <SwitchField
//                         label={feature.name}
//                         id={feature.name}
//                         // desc="Set status as active/inactive"
//                         // checked={userInput.status || false}
//                         checked={feature.status}
//                         extra="w-[100%]"
//                         onChange={handleSwitchChange}
//                       />
//                     </div>
//                   )
//               )}
//           </div>

//          <Datepicker
//             displayFormat={"DD/MM/YYYY"}
//             placeholder={value||"Select Date"}
//             inputClassName="placeholder-gray-800 w-[100%] p-2 rounded-xl border-2 border-gray-300 mt-2"
//             primaryColor={"lime"}
//             asSingle={true}
//             value={value}
//             useRange={false}
//             onChange={handleDateChange}
//           />

//         </div>
//         {/* <div className="h-fit w-fit">
//           <div className="space-y-10">
//             {featuresSettings &&
//               featuresSettings.features &&
//               featuresSettings.features.map((feature, index) => (
//                 // feature.type === 'Boolean' &&
//                 <div key={index} className="space-y-1 w-[250px] border-solid  border-2 border-black rounded-xl  p-3">
//                   <h1 className="font-black text-brand-500">{feature.name}</h1>
//                   <div className="flex justify-between items-center">
//                     <h1>Max Value</h1>
//                     <InputField value={featuresSettings.planFeatures.find(planFeature => planFeature.featureId === feature.id)?.maxCount} inputCSS="h-8 w-32 mt-0"/>
//                   </div>
//                   <div className="flex justify-between items-center">
//                   <h1>Usage Value</h1>
//                     <InputField value={featuresSettings.featureUsage.find(featureUsage => featureUsage.featureId === feature.id)?.usageCounter} inputCSS="h-8 w-32 mt-0"/>
//                   </div>
//                   <div className="flex justify-between items-center">
//                   <h1>Reset Date</h1>
//                     <InputField  value={new Date(feature.resetDate).toLocaleDateString()} inputCSS="h-8 w-32 mt-0"/>
//                   </div>

//                 </div>
//               ))}
//           </div>
//         </div> */}

//         <div className="h-fit w-fit">
//           <h1 className="mb-3 text-xl font-black text-brand-500">
//             Boolean Features
//           </h1>
//           <div className="flex space-x-5">
//             {/* First Column */}
//             <div className="space-y-5">
//               {featuresSettings &&
//                 featuresSettings.features &&
//                 featuresSettings.features
//                   .slice(0, Math.ceil(featuresSettings.features.length / 2))
//                   .map((feature, index) => (
//                     <div
//                       key={index}
//                       className="border-black w-[250px] space-y-1 rounded-xl border-2 border-solid p-3"
//                     >
//                       <h1 className="font-black text-brand-500">
//                         {feature.name}
//                       </h1>
//                       <div className="flex items-center justify-between">
//                         <h1>Max Value</h1>
//                         <InputField
//                           name="maxCount"
//                           id="maxCount"
//                           type="text"
//                           value={
//                             featuresSettings.planFeatures.find(
//                               (planFeature) =>
//                                 planFeature.featureId === feature.id
//                             )?.maxCount
//                           }
//                           // onChange={handleInputChange}
//                           onChange={(e) =>
//                             handleInputChange(e, feature.id, "maxCount")
//                           }
//                           inputCSS="h-8 w-32 mt-0"
//                         />
//                       </div>
//                       <div className="flex items-center justify-between">
//                         <h1>Usage Value</h1>
//                         <InputField
//                           name="usageCounter"
//                           id="usageCounter"
//                           type="text"
//                           // onChange={handleInputChange}
//                           onChange={(e) =>
//                             handleInputChange(e, feature.id, "usageCounter")
//                           }
//                           value={
//                             featuresSettings.featureUsage.find(
//                               (featureUsage) =>
//                                 featureUsage.featureId === feature.id
//                             )?.usageCounter
//                           }
//                           inputCSS="h-8 w-32 mt-0"
//                         />
//                       </div>
//                       {/* <div className="flex items-center justify-between">
//                         <h1>Reset Date</h1>
//                         <InputField
//                           value={new Date(
//                             feature.resetDate
//                           ).toLocaleDateString()}
//                           inputCSS="h-8 w-32 mt-0"
//                         />
//                       </div> */}
//                       <div className="flex items-center justify-between">
//                         {console.log("feature.resetDate ----- - - "+feature.resetDate)}
//                         {/* {setValue(feature.resetDate ? new Date(feature.resetDate) : null)} */}
//                         <h1>Reset Date</h1>
//                         <Datepicker
//         displayFormat={"DD/MM/YYYY"}
//         // placeholder={feature.resetDate||value||new Date(feature.resetDate)||"Select Date"}
//         placeholder={feature.resetDate||"Select Date"}
//         inputClassName="placeholder-gray-800 w-32 p-2 rounded-xl border-2 border-gray-300 mt-0"
//         primaryColor={"lime"}
//         asSingle={true}
//         value={new Date(feature.resetDate)}
//         useRange={false}
//         onChange={(date) => handleDateChange(date, feature.id)}
//       />
//                       </div>
//                     </div>
//                   ))}
//             </div>

//             {/* Second Column */}
//             <div className="space-y-5">
//               {featuresSettings &&
//                 featuresSettings.features &&
//                 featuresSettings.features
//                   .slice(Math.ceil(featuresSettings.features.length / 2))
//                   .map((feature, index) => (
//                     <div
//                       key={index}
//                       className="border-black w-[250px] space-y-1 rounded-xl border-2 border-solid p-3"
//                     >
//                       <h1 className="font-black text-brand-500">
//                         {feature.name}
//                       </h1>
//                       <div className="flex items-center justify-between">
//                         <h1>Max Value</h1>
//                         <InputField
//                           value={
//                             featuresSettings.planFeatures.find(
//                               (planFeature) =>
//                                 planFeature.featureId === feature.id
//                             )?.maxCount
//                           }
//                           inputCSS="h-8 w-32 mt-0"
//                         />
//                       </div>
//                       <div className="flex items-center justify-between">
//                         <h1>Usage Value</h1>
//                         <InputField
//                           value={
//                             featuresSettings.featureUsage.find(
//                               (featureUsage) =>
//                                 featureUsage.featureId === feature.id
//                             )?.usageCounter
//                           }
//                           inputCSS="h-8 w-32 mt-0"
//                         />
//                       </div>
//                       <div className="flex items-center justify-between">
//                         <h1>Reset Date</h1>
//                         <InputField
//                           value={new Date(
//                             feature.resetDate
//                           ).toLocaleDateString()}
//                           inputCSS="h-8 w-32 mt-0"
//                         />
//                       </div>
//                     </div>
//                   ))}
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default FeaturesSettings;

import React, { useEffect } from "react";
import { useState } from "react";
import {
  fetchFeatures,
  getSubscriptions,
  updateFeatures
} from "../../../redux/actions/subscription";
import { useSelector, useDispatch } from "react-redux";
import SwitchField from "../../../components/fields/SwitchField";
import InputField from "../../../components/fields/InputField";
import Datepicker from "react-tailwindcss-datepicker";
import moment from "moment";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";


const FeaturesSettings = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const subscriptionId = window.location.pathname.split("/")[3];
  const [featuresSettings, setFeaturesSettings] = useState([]);
  const id = subscriptionId;

  useEffect(() => {
    const fetchData = async () => {
      const action = await dispatch(fetchFeatures(id));
      console.log(
        "action.payload -------lllll>>>>>" + JSON.stringify(action.payload)
      );
      setFeaturesSettings(action.payload);
    };

    fetchData();
  }, []);

  const handleInputChange = (e, featureId, field) => {
    const { value } = e.target;
    // Determine the target feature based on featureId
    const targetFeature = featuresSettings.features.find(
      (feature) => feature.id === featureId
    );

    // Determine the target field within the feature and update its value
    if (targetFeature) {
      if (field === "maxCount") {
        // Update the maxCount in planFeatures
        const updatedPlanFeatures = featuresSettings.planFeatures.map(
          (planFeature) =>
            planFeature.featureId === featureId
              ? { ...planFeature, maxCount: value }
              : planFeature
        );

        // Update the state
        setFeaturesSettings((prevSettings) => ({
          ...prevSettings,
          planFeatures: updatedPlanFeatures,
        }));
        console.log(
          "Max Count PFet---> " + JSON.stringify(updatedPlanFeatures)
        );
      } else if (field === "usageCounter") {
        // Update the usageCounter in featureUsage
        const updatedFeatureUsage = featuresSettings.featureUsage.map(
          (featureUsage) =>
            featureUsage.featureId === featureId
              ? { ...featureUsage, usageCounter: value }
              : featureUsage
        );

        // Update the state
        setFeaturesSettings((prevSettings) => ({
          ...prevSettings,
          featureUsage: updatedFeatureUsage,
        }));
        console.log(
          "usageCounter FetUsg---> " + JSON.stringify(updatedFeatureUsage)
        );
      }
    }
  };

  // console.log("features ------ uE"+JSON.stringify(features));
  const handleSwitchChange = (event, featureId) => {
    const status = event.target.checked;
    // dispatch(updateUserInput({ status: status }));
    const targetFeature = featuresSettings.features.find(
      (feature) => feature.id === featureId
    );
    const updatedFeatures = featuresSettings.features.map((feature) =>
      feature.id === featureId ? { ...feature, status: status } : feature
    );

    setFeaturesSettings((prevSettings) => ({
      ...prevSettings,
      features: updatedFeatures,
    }));
    console.log("boolean Fet--!!!!!----> " + JSON.stringify(updatedFeatures));
  };

  const [value, setValue] = useState(null);

  const handleDateChange = (date, featureId) => {
    // Update the value only for the specific feature
    const updatedFeatures = featuresSettings.features.map((feature) =>
      feature.id === featureId
        ? { ...feature, resetDate: date.startDate }
        : feature
    );
    setFeaturesSettings((prevSettings) => ({
      ...prevSettings,
      features: updatedFeatures,
    }));

    console.log("Date Fet---> " + JSON.stringify(updatedFeatures));
  };

  // const formattedValue = value ? moment(value).format("DD/MM/YYYY") : "";

  const handleButtonClick = async() => {
        const action = await dispatch(updateFeatures(featuresSettings));
        console.log("action.payload---->"+action.payload);
        if (action.payload.success) {
            notifySuccess(action.payload.message);
          } else {
            notifyError(action.payload.message);
          }
  };

  const notifySuccess = (message) =>
    toast.success(message, {
      style: {
        padding: "35px",
        color: "#a0ca00",
      },
      duration: 1000,
      iconTheme: {
        primary: "#a0ca00",
        secondary: "#222c25",
      },
    });
  const notifyError = (message) =>
    toast.error(message, {
      style: {
        border: "1px solid #fff",
        padding: "35px",
        color: "red",
      },
      duration: 5000,
      iconTheme: {
        primary: "red",
        secondary: "#fff",
      },
    });

  return (
    <div className="mt-3  h-full min-h-[84vh] w-full rounded-xl bg-white py-10 px-20">
      {/* <h1 className=" font-black text-brand-500 px-9">Plan General Features</h1> */}
      <Toaster />
      <h1 className=" pb-3 text-xl font-black text-navy-700">
        Plan General Features
      </h1>
      <div className="flex justify-between space-x-10 ">
        <div className="h-fit w-[25%]">
          <h1 className="mb-3 text-xl font-black text-brand-500">Features</h1>
          <div className="space-y-5">
            {featuresSettings &&
              featuresSettings.features &&
              featuresSettings.features.map(
                (feature, index) =>
                  feature.type === "Boolean" && (
                    <div key={index} className="">
                      <SwitchField
                        label={feature.name}
                        id={feature.name}
                        // desc="Set status as active/inactive"
                        // checked={userInput.status || false}
                        checked={feature.status}
                        extra="w-[100%]"
                        onChange={(e) => handleSwitchChange(e, feature.id)}
                      />
                    </div>
                  )
              )}
          </div>
        </div>

        <div className="h-fit w-fit">
          <h1 className="mb-3 text-xl font-black text-brand-500">
            Usage Counter
          </h1>
          <div className="flex space-x-5">
            {/* First Column */}
            <div className="space-y-5">
              {featuresSettings &&
                featuresSettings.features &&
                featuresSettings.features
                  .slice(0, Math.ceil(featuresSettings.features.length / 2))
                  .map((feature, index) => (
                    <div
                      key={index}
                      className="border-black w-[250px] space-y-1 rounded-xl border-2 border-solid p-3"
                    >
                      <h1 className="font-black text-navy-700">
                        {feature.name}
                      </h1>
                      <div className="flex items-center justify-between">
                        <h1>Max Value</h1>
                        <InputField
                          name="maxCount"
                          id="maxCount"
                          type="text"
                          value={
                            featuresSettings.planFeatures.find(
                              (planFeature) =>
                                planFeature.featureId === feature.id
                            )?.maxCount
                          }
                          // onChange={handleInputChange}
                          onChange={(e) =>
                            handleInputChange(e, feature.id, "maxCount")
                          }
                          inputCSS="h-8 w-32 mt-0"
                        />
                      </div>
                      <div className="flex items-center justify-between">
                        <h1>Usage Value</h1>
                        <InputField
                          name="usageCounter"
                          id="usageCounter"
                          type="text"
                          // onChange={handleInputChange}
                          onChange={(e) =>
                            handleInputChange(e, feature.id, "usageCounter")
                          }
                          value={
                            featuresSettings.featureUsage.find(
                              (featureUsage) =>
                                featureUsage.featureId === feature.id
                            )?.usageCounter
                          }
                          inputCSS="h-8 w-32 mt-0"
                        />
                      </div>

                      <div className="flex items-center justify-between">
                        {console.log(
                          "feature.resetDate ----- - - " + feature.resetDate
                        )}
                        {/* {setValue(feature.resetDate ? new Date(feature.resetDate) : null)} */}
                        <h1>Reset Date</h1>
                        <Datepicker
                          displayFormat={"DD/MM/YYYY"}
                          // placeholder={feature.resetDate||value||new Date(feature.resetDate)||"Select Date"}
                          // placeholder={(feature.resetDate).substring(0, 10) || "Select Date"}
                          placeholder={
                            feature.resetDate
                              ? feature.resetDate.substring(0, 10)
                              : "Select Date"
                          }
                          inputClassName="placeholder-gray-800 w-[100%] p-2 rounded-xl border border-gray-200 mt-0 h-8"
                          primaryColor={"lime"}
                          asSingle={true}
                          value={new Date(feature.resetDate)}
                          useRange={false}
                          onChange={(date) =>
                            handleDateChange(date, feature.id)
                          }
                        />
                      </div>
                    </div>
                  ))}
            </div>

            {/* Second Column */}
            <div className="space-y-5">
              {featuresSettings &&
                featuresSettings.features &&
                featuresSettings.features
                  .slice(Math.ceil(featuresSettings.features.length / 2))
                  .map((feature, index) => (
                    <div
                      key={index}
                      className="border-black w-[250px] space-y-1 rounded-xl border-2 border-solid p-3"
                    >
                      <h1 className="font-black text-navy-700">
                        {feature.name}
                      </h1>
                      <div className="flex items-center justify-between">
                        <h1>Max Value</h1>
                        <InputField
                          value={
                            featuresSettings.planFeatures.find(
                              (planFeature) =>
                                planFeature.featureId === feature.id
                            )?.maxCount
                          }
                          onChange={(e) =>
                            handleInputChange(e, feature.id, "maxCount")
                          }
                          inputCSS="h-8 w-32 mt-0"
                        />
                      </div>
                      <div className="flex items-center justify-between">
                        <h1>Usage Value</h1>
                        <InputField
                          value={
                            featuresSettings.featureUsage.find(
                              (featureUsage) =>
                                featureUsage.featureId === feature.id
                            )?.usageCounter
                          }
                          onChange={(e) =>
                            handleInputChange(e, feature.id, "usageCounter")
                          }
                          inputCSS="h-8 w-32 mt-0"
                        />
                      </div>
                      <div className="flex items-center justify-between">
                        <h1>Reset Date</h1>

                        <Datepicker
                          displayFormat={"DD/MM/YYYY"}
                          // placeholder={feature.resetDate||value||new Date(feature.resetDate)||"Select Date"}
                          // placeholder={(feature.resetDate).substring(0, 10) || "Select Date"}
                          placeholder={
                            feature.resetDate
                              ? feature.resetDate.substring(0, 10)
                              : "Select Date"
                          }
                          inputClassName="placeholder-gray-800 w-[100%] p-2 rounded-xl border border-gray-200 mt-0 h-8 "
                          primaryColor={"lime"}
                          asSingle={true}
                          value={new Date(feature.resetDate)}
                          useRange={false}
                          onChange={(date) =>
                            handleDateChange(date, feature.id)
                          }
                        />
                      </div>
                    </div>
                  ))}
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-end py-4 ">
      <button
        // onClick={() => handleButtonClick(null, "add")}
        onClick={handleButtonClick}
        className="linear rounded-full border-2 bg-brand-700 px-4 py-3 text-center text-base font-medium text-white transition duration-200 hover:border-2 hover:border-brand-500 hover:!bg-white hover:text-brand-500  active:!bg-white/70"
      >
        Update
      </button>
      </div>
      
    </div>
  );
};

export default FeaturesSettings;
