import React from "react";

// Admin Imports
import MainDashboard from "views/admin/default";
import NFTMarketplace from "views/admin/marketplace";
import Permission from 'views/admin/permission'
import Profile from "views/admin/profile";
import Module from "views/admin/modules";
import Plan from "views/admin/plan";
import DataTables from "views/admin/tables";
import RTLDefault from "views/rtl/default";
import User from "views/admin/user";
import Role from "views/admin/roles";
import State from "views/admin/state";
import Zone from "views/admin/zone";
import District from "views/admin/district";
import Center from "views/admin/center";
import SignIn from "views/auth/signin/index";
import Business from "views/admin/business";
import Subscription from "views/admin/subscription";

// import User from "views/admin/user/index";

// Auth Imports
// import SignIn from "views/auth/SignIn";
import SignUp from "./views/auth/SignUp";

// Icon Imports
import {
  MdHome,
  MdOutlineShoppingCart,
  MdBarChart,
  MdPerson,
  MdLock,
  MdVerifiedUser,
  MdAccountTree,
  MdDesignServices,
  MdApps,
  MdAppRegistration,
  MdOutlineSubscriptions,
  MdAddBusiness
  
} from "react-icons/md";

import {
  FiUsers,
  FiSettings
} from "react-icons/fi"



const routes = [
  {
    name: "Dashboard",
    layout: "/admin",
    path: "default",
    icon: <MdHome className="h-6 w-6" />,
    component: <MainDashboard />,
  },
  {
    name: "Structure",
    layout: "/admin",
    icon: <MdAccountTree className="h-6 w-6" />,
    path: "structure",
    isDropdown: true,
    children: [
      {
        name: "State",
        layout: "/admin",
        path: "state",
        icon: <MdPerson className="h-6 w-6" />,
        component: <State />,
      }, 
      {
        name: "Zone",
        layout: "/admin",
        path: "zone",
        icon: <MdPerson className="h-6 w-6" />,
        component: <Zone />,
      }, 
      {
        name: "District",
        layout: "/admin",
        path: "district",
        icon: <MdPerson className="h-6 w-6" />,
        component: <District />,
      },
      {
        name: "Center",
        layout: "/admin",
        path: "center",
        icon: <MdPerson className="h-6 w-6" />,
        component: <Center />,
      },
      // {
      //   name: "Markaz",
      //   layout: "/admin",
      //   path: "profile-4",
      //   icon: <MdPerson className="h-6 w-6" />,
      //   component: <Profile />,
      // },
    ],
    component: <User />,
  },
  {
    name: "Modules",
    layout: "/admin",
    path: "modules",
    icon: <MdApps className="h-6 w-6" />,
    component: <Module />,
    secondary: true,
  },
  {
    name: "Plans",
    layout: "/admin",
    path: "plans",
    icon: <MdAppRegistration className="h-6 w-6" />,
    component: <Plan />,
    secondary: true,
  },
  {
    name: "Subscriptions",
    layout: "/admin",
    path: "subscriptions",
    icon: <MdOutlineSubscriptions className="h-6 w-6" />,
    component: <Subscription />,
    secondary: true,
  },
  {
    name: "Users & Roles",
    layout: "/admin",
    path: "people",
    icon: <FiUsers className="h-6 w-6" />,
    isDropdown: true,
    children: [
      {
        name: "Users",
        layout: "/admin",
        path: "users",
        icon: <MdPerson className="h-6 w-6" />,
        component: <User />,
      }, 
      {
        name: "Roles",
        layout: "/admin",
        path: "roles",
        icon: <MdPerson className="h-6 w-6" />,
        component: <Role />,
      },
      // {
      //   name: "Organizations",
      //   layout: "/admin",
      //   path: "profile-4",
      //   icon: <MdPerson className="h-6 w-6" />,
      //   component: <Permission />,
      // },
    ],
    secondary: true,
  },
  {
    name: "Business",
    layout: "/admin",
    path: "business",
    icon: <MdAddBusiness className="h-6 w-6" />,
    component: <Business />,
    secondary: true,
  },
  // {
  //   name: "Settings",
  //   layout: "/admin",
  //   path: "nft-marketplace",
  //   icon: <FiSettings className="h-6 w-6" />,
  //   component: <NFTMarketplace />,
  //   secondary: true,
  // },
  // {
  //   name: "NFT Marketplace",
  //   layout: "/admin",
  //   path: "nft-marketplace",
  //   icon: <MdOutlineShoppingCart className="h-6 w-6" />,
  //   component: <NFTMarketplace />,
  //   secondary: true,
  // },
  // {
  //   name: "Data Tables",
  //   layout: "/admin",
  //   icon: <MdBarChart className="h-6 w-6" />,
  //   path: "data-tables",
  //   component: <DataTables />,
  // },
  // {
  //   name: "Profile",
  //   layout: "/admin",
  //   path: "profile",
  //   icon: <MdPerson className="h-6 w-6" />,
  //   component: <Profile />,
  // },
  // {
  //   name: "Sign In",
  //   layout: "/auth",
  //   path: "sign-in",
  //   icon: <MdLock className="h-6 w-6" />,
  //   component: <SignIn />,
  // },
  // {
  //   name: "Sign Up",
  //   layout: "/auth",
  //   path: "sign-up",
  //   icon: <MdLock className="h-6 w-6" />,
  //   component: <SignUp/>,
  // },
  // {
  //   name: "Reset Password",
  //   layout: "/auth",
  //   path: "reset-password",
  //   icon: <MdLock className="h-6 w-6" />,
  //   component: <ResetPassword/>,
  // },
  // {
  //   name: "Reset Password",
  //   layout: "/auth",
  //   path: "reset-password-request",
  //   icon: <MdLock className="h-6 w-6" />,
  //   component: <ResetPasswordRequest/>,
  // },
  // {
  //   name: "CHECK TOKEN",
  //   layout: "/auth",
  //   path: "CHECKTOKEN",
  //   icon: <MdLock className="h-6 w-6" />,
  //   component: <CheckToken/>,
  // },
  // {
  //   name: "RTL Admin",
  //   layout: "/rtl",
  //   path: "rtl",
  //   icon: <MdHome className="h-6 w-6" />,
  //   component: <RTLDefault />,
  // },
];
export default routes;
