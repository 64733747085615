import React from 'react'
import { CaretRightIcon, CaretLeftIcon, DoubleArrowLeftIcon, DoubleArrowRightIcon } from '@radix-ui/react-icons'

function Paginate(props) {
    const { page, limit, totalCount, changePage } = props;
    const totalPages = Math.ceil(totalCount / limit);

    return (
        <div className='w-full flex flex-col justify-center   pt-3 mt-3 '>
            <div className='flex justify-center'>
                <div
                    className={`p-3 m-1 cursor-pointer rounded bg-blueSecondary text-white hover:text-brand-500 hover:bg-brand-100 transition-colors ${page === 1 && 'opacity-50 cursor-not-allowed'}`}
                    onClick={() => page !== 1 && changePage(1)}
                >
                    <DoubleArrowLeftIcon />
                </div>
                <div
                    className={`p-3 m-1 cursor-pointer rounded bg-blueSecondary text-white hover:text-brand-500 hover:bg-brand-100 transition-colors ${page === 1 && 'opacity-50 cursor-not-allowed'}`}
                    onClick={() => page !== 1 && changePage(page - 1 > 1 ? page - 1 : 1)}
                >
                    <CaretLeftIcon />
                </div>
                <div className='p-2 px-5 m-1 cursor-pointer rounded bg-blueSecondary text-white hover:text-brand-500 hover:bg-brand-100 transition-colors'> {page || 1} </div>

                <div
                    className={`p-3 m-1 cursor-pointer rounded bg-blueSecondary text-white hover:text-brand-500 hover:bg-brand-100 transition-colors ${page === totalPages && 'opacity-50 cursor-not-allowed'}`}
                    onClick={() => page !== totalPages && changePage((page + 1) < totalPages ? page + 1 : totalPages)}
                >
                    <CaretRightIcon />
                </div>
                <div
                    className={`p-3 m-1 cursor-pointer rounded bg-blueSecondary text-white hover:text-brand-500 hover:bg-brand-100 transition-colors ${page === totalPages && 'opacity-50 cursor-not-allowed'}`}
                    onClick={() => page !== totalPages && changePage(totalPages)}
                >
                    <DoubleArrowRightIcon />
                </div>

            </div>
                <p className='text-center text-gray-400 text-sm'> {`showing page ${page} of ${totalPages}`} </p>
        </div>
    )
}

export default Paginate


